/* eslint-disable max-len */
import React from 'react'
import { Svg, G, Rect, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const XIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg width="48" height="48" viewBox="0 0 48 48">
      <G id={'Group_8447' + id} data-name={'Group 8447' + id} transform="translate(-16 -208)">
        <Rect id={'Rectangle_3572' + id} data-name={'Rectangle 3572' + id} width="48" height="48" rx="24" transform="translate(16 208)" fill="#fbd5cd" />
      </G>
      <G>
        <Path id={'close_FILL0_wght400_GRAD0_opsz48' + id} d="M12.084,32.891,10.35,31.157l9.537-9.537L10.35,12.084l1.734-1.734,9.537,9.537,9.537-9.537,1.734,1.734-9.537,9.537,9.537,9.537-1.734,1.734-9.537-9.537Z" transform="translate(2 2)" fill="#f05231" />
      </G>
    </Svg>

  )
}

export default XIcon
