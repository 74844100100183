import React, { useCallback } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import {
  TouchableOpacity,
  FlatList,
  ListRenderItem,
  ListRenderItemInfo,
  View
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { getTranslation, TextTranslationProp } from '@hedgit/lib/translations/getTranslation'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { Tutorial } from '@hedgit/lib/interfaces/tutorial'

import H1 from '@hedgit/lib/components/typography/h1'
import Title from '@hedgit/lib/components/typography/title'
import { TutorialCard } from '@hedgit/lib/components/cards/tutorial-card'
import GoBackIcon from '@hedgit/lib/components/icons/go-back'
import { Video } from '@hedgit/lib/components/video'
import Body from '@hedgit/lib/components/typography/body'

import { useSelector } from 'store'

import { Container, SubContainer, DetailsContainer, Pressable } from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'TutorialsDetails'>

const { colors } = theme

const TutorialsDetails = ({ route, navigation }: Props) => {
  const { t } = useTranslation()

  const { id, reference } = route.params

  const list = useSelector(state => state.tutorials.list)

  const filteredListByReference = list.filter(tutorial => tutorial.reference === reference)
  const relatedVideosList = filteredListByReference.filter(tutorial => tutorial.id !== id)
  const selectedTutorial = list.find(tutorial => tutorial.id === id)

  const renderCard: ListRenderItem<Tutorial> = useCallback(
    (tutorial: ListRenderItemInfo<Tutorial>) => {
      return (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('TutorialsDetails', {
              id: tutorial.item.id,
              reference: tutorial.item.reference
            })
          }}
          style={{ marginVertical: 10 }}
        >
          <TutorialCard tutorial={tutorial.item} />
        </TouchableOpacity>
      )
    },
    [navigation]
  )

  return (
    <Container>
      <SubContainer>
        <View>
          <Video
            videoSource={selectedTutorial?.src}
            autoPlay={false}
            style={{ overflow: 'hidden' }}
          />
          <Pressable testID='nav-bar-menu' onPress={() => navigation.goBack()}>
            <GoBackIcon color={colors.white} />
          </Pressable>
        </View>
        <DetailsContainer>

          <H1>{getTranslation(selectedTutorial?.title as TextTranslationProp)}</H1>
          <Body>
            {getTranslation(selectedTutorial?.description as TextTranslationProp)}
          </Body>
          { relatedVideosList.length !== 0 ? <Title>{t('Tutorials.relatedVideos')}</Title> : null}
          <FlatList
            testID='tu-tutorials-details-flatList'
            data={relatedVideosList}
            renderItem={renderCard}
            keyExtractor={data => data.id}
          />
        </DetailsContainer>
      </SubContainer>
    </Container>
  )
}

export default TutorialsDetails
