import React from 'react'
import { Text } from 'native-base'
import type { StyleProp, TextStyle } from 'react-native'

const H2: React.FC<{
  style?: StyleProp<TextStyle>;
  testID?: string;
}> = ({ children, style, testID }) => {
  return (
    <Text
      testID={testID}
      fontSize="xl"
      fontWeight={900}
      fontFamily="Lato"
      style={style}
      color="dark"
    >
      {children}
    </Text>
  )
}

export default H2
