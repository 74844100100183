import styled from 'styled-components/native'

import { ModalProps } from '../basic-modal/modal'
import { SharedButton } from '../../shared/button'
import Body from '../../typography/body'

export const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000052;
`

interface ModalViewProps {
  variant: ModalProps['variant'];
}

export const ModalView = styled.View<ModalViewProps>`
  background-color: white;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  width: 85%;
  padding: 16px;
  padding-top: ${props => props.variant === 'confirm' ? '24px' : '34px'};
  max-width: 500px;

`

export const ButtonsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const Button = styled(SharedButton)`
  flex: 1;
`

export const IconContainer = styled.View`
  position: absolute;
  top: -24px;
`

export const InputBox = styled.View`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const InputLabel = styled(Body)`
  text-align: center;
  margin: 15px 0 10px 0;
`

export const InputLabelBold = styled(Body)`
  font-weight: bold;
`

export const ErrorBox = styled.View`
  height: 30px;
  margin-bottom: 5px;
`
