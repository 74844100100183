/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Defs, G, Rect } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
}

const AlgorithmIcon = ({ color }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'Group_8106' + id} data-name={'Group_8106' + id} width="19.712" height="19.717" viewBox="0 0 19.712 19.717">
      <Defs>
        <clipPath id={'clip-path' + id}>
          <Rect id={'Rectangle_3573' + id} data-name={'Rectangle 3573' + id} width="19.712" height="19.717" fill={color} />
        </clipPath>
      </Defs>
      <G id={'Group_8106-2' + id} data-name={'Group 8106' + id} clipPath="url(#clip-path)">
        <Path id={'Path_34641' + id} data-name={'Path 34641' + id} d="M7.158,19.717h-.77a.727.727,0,0,0-.106-.035,3.567,3.567,0,0,1-3.011-2.61,8.9,8.9,0,0,1-.205-1.282c-.008,0-.056-.016-.106-.027A3.615,3.615,0,0,1,.136,13.155c-.06-.193-.091-.4-.135-.594v-.769a.6.6,0,0,0,.034-.106,3.509,3.509,0,0,1,.436-1.3c.1-.181.225-.352.342-.532A3.665,3.665,0,0,1,.136,8.536c-.053-.2-.09-.394-.135-.592V7.175a.723.723,0,0,0,.034-.106A3.592,3.592,0,0,1,2.234,4.2a8.4,8.4,0,0,1,.878-.286.06.06,0,0,0,.007-.024c0-.051,0-.1,0-.154A3.523,3.523,0,0,1,5.2.389,3.521,3.521,0,0,1,9.11.864a.4.4,0,0,1,.167.355q-.006,7.412,0,14.824c0,.84,0,1.679,0,2.519a.322.322,0,0,1-.128.277,3.729,3.729,0,0,1-1.564.786c-.142.033-.285.061-.427.091" transform="translate(-0.001 0)" fill={color} />
        <Path id={'Path_34642' + id} data-name={'Path_34642' + id} d="M204.964,19.821c-.259-.064-.523-.115-.777-.194a3.329,3.329,0,0,1-1.239-.714.293.293,0,0,1-.1-.19c-.005-1.417,0-2.834,0-4.251a.49.49,0,0,1,.015-.075c.388,0,.777-.005,1.166.007.047,0,.1.092.135.152a1.685,1.685,0,0,0,1.533.989,1.648,1.648,0,0,0,1.568-.927,1.726,1.726,0,1,0-3.079-1.557.273.273,0,0,1-.3.186c-.338-.012-.677,0-1.027,0V10.549h.175c.808,0,1.616,0,2.424,0a.236.236,0,0,1,.253.164,1.723,1.723,0,0,0,1.949.949,1.731,1.731,0,0,0,.019-3.37,1.708,1.708,0,0,0-1.948.911.3.3,0,0,1-.331.2c-.776-.008-1.552,0-2.328,0h-.216V6.7c.393,0,.788,0,1.182.007.042,0,.094.087.121.143A1.731,1.731,0,1,0,205.89,4.4a1.712,1.712,0,0,0-1.7.958.283.283,0,0,1-.313.195c-.333-.015-.666,0-1.029,0V5.339c0-1.347,0-2.693,0-4.04a.351.351,0,0,1,.142-.31,3.529,3.529,0,0,1,3.68-.61,3.52,3.52,0,0,1,2.306,2.951,3.38,3.38,0,0,1,.03.344c0,.115,0,.23,0,.36a3.685,3.685,0,0,1,2.309,5.937A3.68,3.68,0,0,1,209.03,15.9c-.032.32-.04.641-.1.953a3.635,3.635,0,0,1-3.092,2.933.6.6,0,0,0-.106.035Z" transform="translate(-192.42 -0.104)" fill={color} />
        <Path id={'Path_34644' + id} data-name={'Path_34644' + id} d="M248.414,105.934a.576.576,0,1,1-.574.574.583.583,0,0,1,.574-.574" transform="translate(-235.103 -100.49)" fill={color} />
        <Path id={'Path_34645' + id} data-name={'Path_34645' + id} d="M278.94,181.37a.577.577,0,1,1-.569-.579.583.583,0,0,1,.569.579" transform="translate(-263.51 -171.5)" fill={color} />
        <Path id={'Path_34646' + id} data-name={'Path_34646' + id} d="M248.4,256.8a.577.577,0,1,1,.592-.556.584.584,0,0,1-.592.556" transform="translate(-235.101 -242.509)" fill={color} />
      </G>
    </Svg>
  )
}

export default AlgorithmIcon
