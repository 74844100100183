import { ThunkAction } from '@hedgit/lib/types/redux-thunk'
import { APIResponse } from '@hedgit/lib/types/api'

import { get } from '@hedgit/lib/utils/axios'

import { Algorithm } from '@hedgit/lib/interfaces/algorithm'

import { getAlgorithmsPending, getAlgorithmsFulfilled, getAlgorithmsFailed } from './actions'
import { AlgorithmsActionTypes } from './types'

interface Response {
  algorithms: Algorithm[];
}

export const getAlgorithms = (): ThunkAction<void, unknown, unknown, AlgorithmsActionTypes> => {
  return async (dispatch) => {
    dispatch(getAlgorithmsPending())
    try {
      const response = await get<APIResponse<Response>>('/algorithms')
      dispatch(getAlgorithmsFulfilled(response.data.algorithms))
    } catch (error) {
      dispatch(getAlgorithmsFailed(error))
    }
  }
}
