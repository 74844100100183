/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const EmailIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg width="27.79" height="19.857" viewBox="0 0 27.79 19.857">
      <Path id={'Path_34797' + id} data-name={'Path_34797' + id} d="M25.323,0H1.767A1.769,1.769,0,0,0,0,1.768V17.389a1.769,1.769,0,0,0,1.767,1.768H25.323a1.77,1.77,0,0,0,1.767-1.768V1.768A1.77,1.77,0,0,0,25.323,0m-.628,1.368L13.841,12.123a.421.421,0,0,1-.592,0L2.4,1.368ZM1.368,16.68V2.277L8.242,9.087Zm.842,1.109,7-7.737L12.286,13.1a1.794,1.794,0,0,0,2.518,0l3.024-3,7.04,7.689Zm23.512-1.094L18.8,9.135l6.921-6.858Z" transform="translate(0.35 0.35)" fill="#33a3d9" stroke="#33a3d9" strokeWidth="0.7" />
    </Svg>

  )
}

export default EmailIcon
