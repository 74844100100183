/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, G } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const Logout = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'logout_black_24dp' + id} width={width} height={height} viewBox="0 0 24 24">
      <G id={'Group_321' + id} data-name={'Group 321' + id}>
        <Path id={'Path_1684' + id} data-name={'Path 1684' + id} d="M0,0H24V24H0Z" fill="none" />
      </G>
      <G id={'Group_322' + id} data-name={'Group 322' + id}>
        <Path id={'Path_1685' + id} data-name={'Path 1685' + id} d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z" fill={color} />
      </G>
    </Svg>
  )
}

export default Logout
