import { ActionType } from 'typesafe-actions'

import { PricingSignal, BrokerPricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import * as actions from './actions'

export enum PricingSignalsActionConsts {
  GET_PRICING_SIGNALS_PENDING='GET_PRICING_SIGNALS_PENDING',
  GET_PRICING_SIGNALS_FULFILLED='GET_PRICING_SIGNALS_FULFILLED',
  GET_BROKER_PRICING_SIGNALS_FULFILLED='GET_BROKER_PRICING_SIGNALS_FULFILLED',
  GET_BROKER_FARMER_PRICING_SIGNALS_FULFILLED='GET_BROKER_FARMER_PRICING_SIGNALS_FULFILLED',
  GET_PRICING_SIGNALS_FAILED='GET_PRICING_SIGNALS_FAILED',
  EXECUTE_PRICING_SIGNALS_PENDING='EXECUTE_PRICING_SIGNALS_PENDING',
  EXECUTE_PRICING_SIGNALS_FULFILLED='EXECUTE_PRICING_SIGNALS_FULFILLED',
  EXECUTE_PRICING_SIGNALS_FAILED='EXECUTE_PRICING_SIGNALS_FAILED',
  SET_SELECTED_PRICING_PROGRAM='SET_SELECTED_PRICING_PROGRAM',
  CLEAR_SELECTED_PRICING_PROGRAM='CLEAR_SELECTED_PRICING_PROGRAM',
  GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_PENDING='GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_PENDING',
  GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FULFILLED='GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FULFILLED',
  GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FAILED='GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FAILED',
  GET_PRICING_SIGNALS_BY_SUBSCRIPTION_PENDING='GET_PRICING_SIGNALS_BY_SUBSCRIPTION_PENDING',
  GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FULFILLED='GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FULFILLED',
  GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FAILED='GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FAILED',
}

export type PricingSignalsActionTypes = ActionType<typeof actions>

export interface SelectedPricingProgram {
  id: string;
  date: Date;
}

export interface PricingSignalsState {
  readonly list: PricingSignal[];
  readonly farmerList: PricingSignal[];
  readonly brokerList: BrokerPricingSignal[];
  readonly selectedPricingProgram: SelectedPricingProgram;
  readonly isFetching: boolean;
  readonly error: string;
  readonly pricingSignals: {
    [pricingProgramId: string]: PricingSignal[];
  };
  readonly pricingSignalsBySubscription: {
    [subscriptionId: string]: PricingSignal[];
  };
}

export interface ExecutePricingSignalsInfo {
  pricingProgramId: string;
  signalDate?: string;
  executionPrice: number;
}
