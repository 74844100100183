import styled from 'styled-components/native'

type ButtonProps = {
  isSelected: boolean;
  disabled?: boolean;
}

export const ButtonContainer = styled.TouchableOpacity<ButtonProps>`
  background-color: ${props => props.isSelected ? '#DAECF6' : '#FFFFFF'};
  border: 1px solid ${props => props.isSelected ? '#33A3D9' : '#D6D8DB'};
  opacity: ${props => props.disabled ? '0.4' : '1'};
  border-radius: 10px;
  height: 52px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonText = styled.Text<ButtonProps>`
  color: ${props => props.isSelected ? '#33A3D9' : '#838A94'};
  font-family: 'lato';
  padding: 30px;
  text-align: center
`
