import { ActionType } from 'typesafe-actions'

import { MarketData } from '@hedgit/lib/interfaces/market-data'

import * as actions from './actions'

export enum MarketDataActionConsts {
  GET_MARKET_DATA_PENDING='GET_MARKET_DATA_PENDING',
  GET_MARKET_DATA_FULFILLED='GET_MARKET_DATA_FULFILLED',
  GET_MARKET_DATA_FAILED='GET_MARKET_DATA_FAILED',
  GET_MARKET_DATA_BY_PRODUCT_PENDING='GET_MARKET_DATA_BY_PRODUCT_PENDING',
  GET_MARKET_DATA_BY_PRODUCT_FULFILLED='GET_MARKET_DATA_BY_PRODUCT_FULFILLED',
  GET_MARKET_DATA_BY_PRODUCT_FAILED='GET_MARKET_DATA_BY_PRODUCT_FAILED',
  CREATE_MARKET_DATA_PENDING='CREATE_MARKET_DATA_PENDING',
  CREATE_MARKET_DATA_FULFILLED='CREATE_MARKET_DATA_FULFILLED',
  CREATE_MARKET_DATA_FAILED='CREATE_MARKET_DATA_FAILED',
  SET_EDITING_MARKET_DATA='SET_EDITING_MARKET_DATA',
  EDIT_MARKET_DATA_PENDING='EDIT_MARKET_DATA_PENDING',
  EDIT_MARKET_DATA_FULFILLED='EDIT_MARKET_DATA_FULFILLED',
  EDIT_MARKET_DATA_FAILED='EDIT_MARKET_DATA_FAILED',
  GET_FULL_MARKET_DATA_BY_PRODUCT_FULFILLED='GET_FULL_MARKET_DATA_BY_PRODUCT_FULFILLED',
  GET_MARKET_DATA_BY_PRICING_PROGRAM_PENDING = 'GET_MARKET_DATA_BY_PRICING_PROGRAM_PENDING',
  GET_MARKET_DATA_BY_PRICING_PROGRAM_FULFILLED = 'GET_MARKET_DATA_BY_PRICING_PROGRAM_FULFILLED',
  GET_MARKET_DATA_BY_PRICING_PROGRAM_FAILED = 'GET_MARKET_DATA_BY_PRICING_PROGRAM_FAILED'
}

export type MarketDataActionTypes = ActionType<typeof actions>

export type MarketDataSummary = Pick<MarketData, 'id'| 'date' | 'value'>

export interface MarketDataState {
  readonly list: MarketData[];
  readonly listByProduct: {
    [productId: string]: MarketDataSummary[];
  };
  readonly fullListByProduct: {
    [productId: string]: MarketData[];
  };
  readonly isFetching: boolean;
  readonly error: string;
  readonly isCreatingMarketData: boolean;
  readonly listByPricingProgram: {
    [pricingProgramId: string]: MarketData[];
  };
  readonly editingMarketData: {
    date: string;
    product: string;
    value: number;
    _id: string;
  } | undefined;
}
