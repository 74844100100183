import { ActionType } from 'typesafe-actions'

import { PricingProgram } from '@hedgit/lib/interfaces/pricing-program'

import * as actions from './actions'

import { Subscription } from '../../../interfaces/subscription'
import { Product } from '../../../interfaces/product'

export enum PricingProgramsActionConsts {
  GET_PRICING_PROGRAMS_PENDING='GET_PRICING_PROGRAMS_PENDING',
  GET_PRICING_PROGRAMS_FULFILLED='GET_PRICING_PROGRAMS_FULFILLED',
  GET_PRICING_PROGRAMS_FAILED='GET_PRICING_PROGRAMS_FAILED',
  GET_PRICING_PROGRAM_BY_ID_PENDING='GET_PRICING_PROGRAM_BY_ID_PENDING',
  GET_PRICING_PROGRAM_BY_ID_FULFILLED='GET_PRICING_PROGRAM_BY_ID_FULFILLED',
  GET_PRICING_PROGRAM_BY_ID_FAILED='GET_PRICING_PROGRAM_BY_ID_FAILED'
}

export type PricingProgramsActionTypes = ActionType<typeof actions>

export interface PricingProgramDetails {
  id: string;
  subscription: Subscription;
  product: Product;
  algorithm: {
    name: {
      es: string;
      en: string;
    };
    steps: number;
  };
  executionPrice: number;
  referencePrice: number;
  minPrice: number;
  maxPrice: number;
  subscriptionTime: Date;
  startDate: Date;
  endDate: Date;
}

export interface PricingProgramsState {
  readonly list: PricingProgram[];
  readonly isFetching: boolean;
  readonly error: string;
  readonly signalList: [];
  readonly pricingProgramDetails: {
    [id: string]: PricingProgramDetails;
  };
}
