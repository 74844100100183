import { Reducer } from 'redux'

import { SubscriptionsActionTypes, SubscriptionsState, SubscriptionsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: SubscriptionsState = {
  list: [],
  brokerList: [],
  farmerList: [],
  isFetching: false,
  error: ''
}

export const subscriptionReducer: Reducer<SubscriptionsState, SubscriptionsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): SubscriptionsState => {
  switch (action.type) {
    case SubscriptionsActionConsts.GET_SUBSCRIPTIONS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionsActionConsts.GET_BROKER_SUBSCRIPTIONS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        brokerList: action.payload
      }
    case SubscriptionsActionConsts.GET_BROKER_FARMER_SUBSCRIPTIONS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        brokerList: action.payload.subscriptions || [],
        farmerList: action.payload.subscribed || []
      }
    case SubscriptionsActionConsts.GET_SUBSCRIPTIONS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        farmerList: action.payload
      }
    case SubscriptionsActionConsts.GET_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case SubscriptionsActionConsts.CREATE_SUBSCRIPTION_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionsActionConsts.CREATE_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: [...state.list, action.payload]
      }
    case SubscriptionsActionConsts.ACCEPTED_SUBSCRIPTION_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionsActionConsts.ACCEPTED_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: [...state.list, action.payload]
      }
    case SubscriptionsActionConsts.ACCEPTED_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    }
    case SubscriptionsActionConsts.REJECT_SUBSCRIPTION_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionsActionConsts.REJECT_SUBSCRIPTION_FULFILLED: {
      const index = state.farmerList.findIndex((item) => item.id === action.payload.id)

      return {
        ...state,
        isFetching: false,
        farmerList: [
          ...state.farmerList.slice(0, index),
          {
            ...state.farmerList[index],
            status: action.payload.status
          },
          ...state.farmerList.slice(index + 1)
        ]
      }
    }
    case SubscriptionsActionConsts.REJECT_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    }
    case SubscriptionsActionConsts.REJECT_CANCELLATION_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionsActionConsts.REJECT_CANCELLATION_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: [...state.list, action.payload]
      }
    case SubscriptionsActionConsts.REJECT_CANCELLATION_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    }
    case SubscriptionsActionConsts.CREATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_PENDING: {
      return {
        ...state,
        isFetching: true
      }
    }
    case SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_FULFILLED: {
      const index = state.farmerList.findIndex((item) => item.id === action.payload.id)

      return {
        ...state,
        isFetching: false,
        farmerList: [
          ...state.farmerList.slice(0, index),
          {
            ...state.farmerList[index],
            status: action.payload.status
          },
          ...state.farmerList.slice(index + 1)
        ]
      }
    }
    case SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_PENDING_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: [...state.list, action.payload]
      }
    case SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
