import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import H2 from '@hedgit/lib/components/typography/h2'

import { PricingProgramStatus } from '../../../../enums/pricing-program-status'

const addOpacity = (color: string, per: string) => color + per

export const LabelContainer = styled.View`
  display: flex;
  flex-direction: row;
  height: 12;
  justify-content: space-between;
  padding-top: 190;
  position: absolute;
  width: 100%;
  z-index: 10;
`

export const H2Title = styled(H2)`
  align-items: flex-end;
`

export const DataContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  left: 0;
`

export const IconWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const ButtonWrapper = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

interface currentDayBoxProps {
  color: string;
}

export const CurrentDayBox = styled.Text<currentDayBoxProps>`
  color: ${props => props.color};
`

export const getGradientColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return addOpacity(theme.colors.blueGray, '80')

  if (status === PricingProgramStatus.executed) return addOpacity(theme.colors.greenLight, '80')

  if (status === PricingProgramStatus.cancelled) return addOpacity(theme.colors.blueGrayLight, '80')

  return theme.colors.blueGray
}

export const getLineColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return addOpacity(theme.colors.blueGray, '50')

  if (status === PricingProgramStatus.executed) return theme.colors.green

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getDotsColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.blueGray

  if (status === PricingProgramStatus.executed) return theme.colors.green

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getSignalsPriceColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getMonthLabelColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const NoDataText = styled.View`
  width: 100%;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 10px;
  height: 257px;
`

export const XAxisContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px;
`

export const XAxisLabelContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1px;
`

export const XAxisLabel = styled.Text`
  font-family: 'lato';
  font-size: 10;
  color: ${(props) => props.theme.colors.gray};
`
