import React from 'react'
import { differenceInBusinessDays, subMonths } from 'date-fns'

import { FilterButton, FilterButtonProps } from './filter-button'

import { HistoricalDataFilter } from '../../../../enums/filters'

export type FilterButtonItem = Omit<FilterButtonProps, 'isSelected'>

interface FilterButtonsProps {
  onChange: (filter: HistoricalDataFilter) => void;
  selected: HistoricalDataFilter;
  dataLength: number;
}

const buttons: HistoricalDataFilter[] = Object.values(HistoricalDataFilter)

export const FilterButtons = ({ onChange, selected, dataLength }: FilterButtonsProps) => {
  const isDisabled = (filter: HistoricalDataFilter) => {
    if ([HistoricalDataFilter['1M'], HistoricalDataFilter.TOTAL].includes(filter)) return false

    const businessDays = differenceInBusinessDays(new Date(), subMonths(new Date(), parseInt(filter)))

    return dataLength < businessDays
  }

  return (
    <>
      {
        buttons.map((filter) => {
          return (
            <FilterButton
              label={filter}
              key={filter}
              onPress={() => onChange(filter)}
              isSelected={selected === filter}
              disabled={isDisabled(filter)}
            />
          )
        })
      }
    </>
  )
}

export default FilterButtons
