/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
}

const PricingProgramIcon = ({ color }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'local_offer_black_24dp' + id} width="20.479" height="20.479" viewBox="0 0 20.479 20.479">
      <Path id={'Path_878' + id} data-name={'Path 878' + id} d="M0,0H20.479V20.479H0Z" fill="none" />
      <Path id={'Path_879' + id} data-name={'Path 879' + id} d="M18.563,10.175l-7.68-7.68A1.7,1.7,0,0,0,9.68,2H3.707A1.712,1.712,0,0,0,2,3.707V9.68a1.7,1.7,0,0,0,.5,1.212l7.68,7.68a1.7,1.7,0,0,0,1.2.495,1.668,1.668,0,0,0,1.2-.5l5.973-5.973a1.668,1.668,0,0,0,.5-1.2A1.724,1.724,0,0,0,18.563,10.175ZM4.987,6.267a1.28,1.28,0,1,1,1.28-1.28A1.278,1.278,0,0,1,4.987,6.267Z" transform="translate(-0.293 -0.293)" fill={color} />
    </Svg>
  )
}

export default PricingProgramIcon
