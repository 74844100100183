import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

import '@hedgit/lib/translations/i18n'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

if (!['qa', 'local'].includes(process.env.ENV_NAME as string)) {
  Sentry.init({
    dsn: process.env.SENTRY_DNS_REACT,
    environment: process.env.ENV_NAME,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    tracesSampleRate: process.env.ENV_NAME === 'prod' ? 1.0 : 0
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
