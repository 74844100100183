import { extendTheme } from 'native-base'

export const newTheme = {
  colors: {
    // Primary
    primary: '#33A3D9',
    primaryDark: '#107DB2',
    // Base
    dark: '#313C4D',
    gray: '#838A94',
    lightGray: '#AEB1B7',
    disabledGray: '#CCCCCC',
    inactiveGray: '#fcfcfc80',
    // Blue
    blue: '#5A6371',
    blueGray: '#333C4C',
    blueGrayLight: '#848A93',
    // Yellow
    yellow: '#FDBC31',
    yellowFaded: '#FDBC3133',
    yellowDark: '#F4A600',
    yellowDarker: '#D99504',
    // Light Blue
    lightBlue: '#33A3D9',
    lightBlueDarker: '#107DB2',
    // Sky Blue
    skyBlue: '#85C8E8',
    // Green
    green: '#21BE75',
    greenFaded: '#21BE7533',
    greenLight: '#5BBA7B',
    greenDarker: '#1A985E',
    // Red
    red: '#F05231',
    redFaded: '#F0523133',
    // White
    white: '#FFFFFF',
    whiteMiddle: '#F3F3F3',
    whiteDark: '#FCFCFC',
    whiteLight: '#D6D8DB'
  },
  fontConfig: {
    Lato: {
      400: {
        normal: 'Lato-Regular'
      },
      700: {
        normal: 'Lato-Bold'
      },
      800: {
        normal: 'Lato-Heavy'
      },
      900: {
        normal: 'Lato-Black'
      }
    }
  },
  fonts: {
    heading: 'Lato',
    body: 'Lato',
    mono: 'Lato'
  }
}

export const theme = extendTheme(newTheme)
