/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Defs, Rect, ClipPath, G } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
}

const NotificationPricingSignalIcon = ({ color }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg width="29.545" height="29.525" viewBox="0 0 29.545 29.525">
      <Defs>
        <ClipPath id={'clip-path' + id}>
          <Rect id={'Rectangle_3574' + id} data-name={'Rectangle 3574' + id} width="29.545" height="29.525" fill={color} />
        </ClipPath>
      </Defs>
      <G id={'Group_8107' + id} data-name="Group 8107" clipPath="url(#clip-path)">
        <Path id={'Path_34647' + id} data-name={'Path 34647' + id} d="M0,12.1c.074-.52.128-1.045.227-1.56A12.709,12.709,0,0,1,5.31,2.475,12.2,12.2,0,0,1,11.551.066a12.661,12.661,0,0,1,11.06,4.3,12.477,12.477,0,0,1,3.1,6.641,12.877,12.877,0,0,1-.047,4.105,1.11,1.11,0,0,1-.051.151,8.539,8.539,0,0,0-3.321-.36A9.473,9.473,0,0,0,19.6,6.093a9.146,9.146,0,0,0-6.766-2.733,9.563,9.563,0,1,0,2.082,18.917,8.569,8.569,0,0,0,.372,3.34c-.346.05-.673.115-1,.143a12.745,12.745,0,0,1-11.037-4.3A12.5,12.5,0,0,1,.137,14.8C.082,14.438.045,14.077,0,13.717V12.1" transform="translate(0 0)" fill={color} />
        <Path id={'Path_34648' + id} data-name={'Path 34648' + id} d="M221.487,228.393c-.421-.091-.849-.156-1.261-.277a6.45,6.45,0,1,1,2.638.224,1.887,1.887,0,0,0-.222.053Zm1.425-10.428h-1.7v5.112h1.7Zm-.007,6.241h-1.688v1.7H222.9Z" transform="translate(-198.982 -198.867)" fill={color} />
        <Path id={'Path_34649' + id} data-name={'Path 34649' + id} d="M66.129,73.926A7.826,7.826,0,0,1,81.7,72.668a7.809,7.809,0,0,1-.227,3.253.452.452,0,0,1-.3.338,8.171,8.171,0,0,0-4.711,4.707.434.434,0,0,1-.309.294,7.838,7.838,0,0,1-9.939-6.4c-.05-.371-.071-.745-.089-.937M77,72a2.439,2.439,0,0,0,0-.269,2.59,2.59,0,0,0-1.936-2.23c-.21-.053-.257-.135-.247-.328.014-.293,0-.587,0-.883H73.1v1.136l-.242.061A2.6,2.6,0,0,0,70.887,72.1a2.546,2.546,0,0,0,2.151,2.457,12.619,12.619,0,0,0,1.409.056.868.868,0,0,1,0,1.736c-.25.009-.5,0-.75,0-.686,0-.908-.164-1.1-.811H70.879a2.652,2.652,0,0,0,2.232,2.545v1.118h1.712c0-.305.012-.593,0-.879-.011-.2.044-.274.251-.325a2.574,2.574,0,0,0,1.959-2.619,2.545,2.545,0,0,0-2.16-2.449,12.845,12.845,0,0,0-1.409-.054A.865.865,0,0,1,72.615,72a.852.852,0,0,1,.839-.853c.336-.012.673-.011,1.01,0a.83.83,0,0,1,.773.59c.03.087.053.177.077.259Z" transform="translate(-61.033 -60.84)" fill={color} />
      </G>
    </Svg>
  )
}

export default NotificationPricingSignalIcon
