/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, G, Rect } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const StatusActive = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (

    <Svg id={'play_circle_black_24dp' + id} width={width} height={height} viewBox="0 0 22 22">
      <G id={'Group_8381' + id} data-name={'Group 8381' + id}>
        <Rect id={'Rectangle_3643' + id} data-name={'Rectangle 3643' + id} width="22" height="22" fill="none" />
      </G>
      <G id={'Group_8382' + id} data-name={'Group 8382' + id} transform="translate(2 2)">
        <Path id={'Path_34756' + id} data-name={'Path 34756' + id} d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,16.2A7.2,7.2,0,1,1,18.2,11,7.21,7.21,0,0,1,11,18.2ZM8.75,15.05,15.05,11,8.75,6.95Z" transform="translate(-2 -2)" fill={color} />
      </G>
    </Svg>

  )
}

export default StatusActive
