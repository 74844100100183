/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
  width?: number;
  height?: number;
  testid?: string;
}

const Question = ({ color, width, height, testid }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'help_outline_black_24dp' + id} data-testid={testid} viewBox="0 0 24 24" width={width} height={height}>
      <Path id={'Path_34645' + id} data-name={'Path 34645' + id} d="M0,0H24V24H0Z" fill="none" />
      <Path id={'Path_34646' + id} data-name={'Path 34646' + id} d="M11,18h2V16H11ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM12,6a4,4,0,0,0-4,4h2a2,2,0,0,1,4,0c0,2-3,1.75-3,5h2c0-2.25,3-2.5,3-5A4,4,0,0,0,12,6Z" fill={color} />
    </Svg>
  )
}

export default Question
