import React from 'react'
// import { View } from 'react-native'

import { BrokerSignUp } from '@hedgit/lib/components/auth/sign-up/broker'

import { Container } from './styled'

const SignUpBroker = () => {
  return (
    <Container>
      <BrokerSignUp />
    </Container>
  )
}

export default SignUpBroker
