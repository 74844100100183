import styled from 'styled-components/native'

import Caption from '@hedgit/lib/components/typography/caption'

export const ChartContent = styled.View`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  height: 172px;
`

export const CircleContent = styled.View`
  margin-top: 14px;
  align-items: center;
  width: 206px;
  height: 113px;
`

export const ChartTextContent = styled.View`
  text-align: center;
  color: #313C4D;
  width: 244px;
  padding-left: 4px;
  padding-top: 12px;
`

export const LegendText = styled(Caption)`
  color: #838A94;
  line-height: 19.5px;
`
