import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import Date from '@hedgit/lib/components/typography/date-text'
import H2 from '@hedgit/lib/components/typography/h2'

type TextProps = {
  textColor: string;
}

export const SignalText = styled(Date)`
  overflow-wrap: break-word;
  inline-size: 56px;
  text-align: center;
  line-height: 12px;
  margin-top: -2px;
  font-size: 9px;
`
export const TotalSignalText = styled(H2)<TextProps>`
  color: ${props => props.textColor} ;
  font-size: 18px;
`
export const SignalReceivedText = styled(H2)<TextProps>`
  color: ${props => props.textColor} ;
  font-size: 24px;
`
export const NumbersContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
  margin-right: -3px;
`

export const SignalsContainer = styled.View`
  align-items: center;
  width: 56px;
`

export const getPPDarkTextColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.dark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getPPTextColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.gray

  if (status === PricingProgramStatus.executed) return theme.colors.gray

  if (status === PricingProgramStatus.cancelled) return theme.colors.lightGray

  return theme.colors.gray
}
