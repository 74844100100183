import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'

import {
  PricingCardHeader,
  IconBox,
  WrapBox,
  HeaderWrapBox,
  WrapContainer,
  CardTitle,
  CardCaption,
  SmallIconBox,
  BoxRN,
  TonsContainer
} from './styled'

import Subtitle from '../../../typography/subtitle'
import DateText from '../../../typography/date-text'
import PricingSignalIcon from '../../../icons/pricing-signal'
import PricingProgramIcon from '../../../icons/pricing-program'
import TonsIcon from '../../../icons/tons'
import UserIcon from '../../../icons/user'
import { UserRole } from '../../../../enums/user-role'

interface Props {
  product: string;
  subtitle: string;
  cardType: 'pricing-signal' | 'pricing-program';
  iconColor: string;
  tons: string | number;
  broker?: string;
  role: UserRole;
}

const CardHeader = (props: Props) => {
  const { product, cardType, iconColor, subtitle, tons, broker, role } = props

  const { t } = useTranslation()

  const isFarmer = role === UserRole.farmer
  const pricingSignalCardType = cardType === 'pricing-signal'

  const shouldDisplaySubtitle = useMemo(() => (cardType === 'pricing-signal' || isFarmer), [cardType, isFarmer])

  return (
    <PricingCardHeader>
      <HeaderWrapBox>
        <IconBox color={iconColor}>
          {
            pricingSignalCardType
              ? <PricingSignalIcon color={iconColor} />
              : <PricingProgramIcon color={iconColor} />
          }
        </IconBox>
        <BoxRN>
          <CardTitle>
            {product}
          </CardTitle>
          {
            shouldDisplaySubtitle
              ? (
                <CardCaption>
                  {subtitle}
                </CardCaption>
                )
              : null
          }
        </BoxRN>
      </HeaderWrapBox>
      { isFarmer
        ? (
          <WrapContainer>
            <WrapBox>
              <SmallIconBox>
                <TonsIcon
                  color={theme.colors.lightGray}
                  width={11}
                  height={11}
                />
              </SmallIconBox>
              <Subtitle>
                {tons}
                {' '}
                {t('Components.card.tonsAbbr').toUpperCase()}
              </Subtitle>
            </WrapBox>
            <WrapBox>
              <SmallIconBox>
                <UserIcon
                  color={theme.colors.lightGray}
                  width={11}
                  height={11}
                />
              </SmallIconBox>
              <DateText>
                {(broker).toUpperCase()}
              </DateText>
            </WrapBox>
          </WrapContainer>
          )
        : (
          <TonsContainer>
            <SmallIconBox>
              <TonsIcon
                color={theme.colors.lightGray}
                width={11}
                height={11}
              />
            </SmallIconBox>
            <Subtitle>
              {tons}
              {' '}
              {t('Components.card.tonsAbbr').toUpperCase()}
            </Subtitle>
          </TonsContainer>
          )}
    </PricingCardHeader>
  )
}

export default CardHeader
