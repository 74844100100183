import { Reducer } from 'redux'

import { AlgorithmsActionTypes, AlgorithmsState, AlgorithmsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: AlgorithmsState = {
  list: [],
  isFetching: false,
  error: ''
}

export const algorithmsReducer: Reducer<AlgorithmsState, AlgorithmsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): AlgorithmsState => {
  switch (action.type) {
    case AlgorithmsActionConsts.GET_ALGORITHMS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case AlgorithmsActionConsts.GET_ALGORITHMS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case AlgorithmsActionConsts.GET_ALGORITHMS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
