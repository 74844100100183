import { useEffect } from 'react'
import { TabActions, useNavigation, useIsFocused } from '@react-navigation/native'

const useResetTab = (tabName: string) => {
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const jumpToAction = TabActions.jumpTo(tabName)

  useEffect(() => {
    if (!isFocused) {
      navigation.dispatch(jumpToAction)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused])
}

export default useResetTab
