import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'

export const formatDate = (value: Date) => format(value, 'yyyy/MM/dd')

export const GetMonthName = (mon: string | number) => {
  const { t } = useTranslation()
  // eslint-disable-next-line max-len
  return [
    t(`Components.card.month.${mon}`)
  ]
}

export const formatNumber = (value: string) => {
  const formattedNumber = value.replace(/[^\d.]/g, '')
  return formattedNumber
}
