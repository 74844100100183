import { ActionType } from 'typesafe-actions'

import { Crop } from '@hedgit/lib/interfaces/crop'

import * as actions from './actions'

export enum CropsActionConsts {
  GET_CROPS_PENDING='GET_CROPS_PENDING',
  GET_CROPS_FULFILLED='GET_CROPS_FULFILLED',
  GET_CROPS_FAILED='GET_CROPS_FAILED',
}

export type CropsActionTypes = ActionType<typeof actions>

export interface CropsState {
  readonly list: Crop[];
  readonly isFetching: boolean;
  readonly error: string;
}
