import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import Date from '@hedgit/lib/components/typography/date-text'

export const CoverageText = styled(Date)`
  margin-top: 5px;
  overflow-wrap: break-word;
  inline-size: 56px;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
  margin-right: -2px;
  font-size: 9px;
`

export const CoverageContent = styled.View`
  width: 56;
  align-items: center;
`

export const getPPCircleColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.yellowDark

  if (status === PricingProgramStatus.executed) return theme.colors.yellowDark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.yellowDark
}

export const getPPTextColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.dark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}
