import React, { useState, useEffect, useMemo } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { format, isSameDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { getTranslation, TextTranslationProp } from '@hedgit/lib/translations/getTranslation'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { getPricingProgramsById } from '@hedgit/lib/store/modules/pricing-programs/thunks'
import { getMarketDataByPricingProgram } from '@hedgit/lib/store/modules/market-data'
import { getPricingSignalsByPricingProgram } from '@hedgit/lib/store/modules/pricing-signals/thunks'

import { GetMonthName } from '@hedgit/lib/utils/format'
import { getMarkToMarket } from '@hedgit/lib/utils/formulas/mark-to-market'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'
import { HistoricalDataFilter } from '@hedgit/lib/enums/filters'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { HelpModal } from '@hedgit/lib/components/modals/help-modal'
import PerformanceBrokerCard from '@hedgit/lib/components/charts/pricing-programs/performance/performance-card-broker'
import { HistoricalPricesPricingProgramChart } from '@hedgit/lib/components/charts/pricing-programs/historical-prices'
import { PricingSignalPricingProgramChart } from '@hedgit/lib/components/charts/pricing-programs/pricing-signal'
import QuestionIcon from '@hedgit/lib/components/icons/questions'

import { useFilteredMarketData } from './useFilteredMarketData'
import { ChartTitle, CardText, Content, SubContent } from './styled'

import { useSelector, useDispatch } from '../../../store'

interface Props {
  id: string;
  state: string;
}

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'PricingProgramDetails'>

const PricingProgramBrokerDetails = ({ id, state }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()

  const [modalPerformanceChartVisible, setModalPerformanceChartVisible] = useState(false)

  const userFirstName = useSelector(
    state => state.auth.currentUser?.firstName || ' '
  )
  const userLastName = useSelector(
    state => state.auth.currentUser?.lastName || ' '
  )
  const subscriptionsList = useSelector(state => state.subscriptions.brokerList)

  const selectedSubscription = subscriptionsList.find(
    pricingProgram => pricingProgram.pricingProgramId === id
  )

  const cropName = getTranslation(
    selectedSubscription?.pricingProgram.product.crop.name as TextTranslationProp
  ) || ' '
  const month = GetMonthName(selectedSubscription?.pricingProgram.product.month || ' ')
  const year = selectedSubscription?.pricingProgram.product.year.toString() || ' '

  const programName = cropName + ' ' + month + ' ' + year

  const marketData = useSelector(
    state =>
      state.marketData.listByPricingProgram[
        selectedSubscription?.pricingProgram.id || ''
      ] || []
  )

  const {
    filteredMarketData,
    setFilter,
    filter,
    startDate,
    endDate,
    ppStartMarketData
  } = useFilteredMarketData(id)

  const marketDataValues = useMemo(() => filteredMarketData.map((mD) => mD.value), [filteredMarketData])

  const updateHistoricalPricesPPChartData = (filter: HistoricalDataFilter) => {
    setFilter(filter)
  }

  const pricingSignalsById = useSelector(
    state => state.pricingSignals.pricingSignals[selectedSubscription?.pricingProgram.id || ''] || []
  )
  const pricingProgramSignalsExecutionPrice = pricingSignalsById.map(
    data => data.executionPrice
  )
  const pricingProgramSignalsReferencePrice = pricingSignalsById.map(
    data => data.referencePrice
  )
  const pricingProgramSignalsExecutionDate = pricingSignalsById.map(
    data => data.date
  )
  const pricingProgramSteps = selectedSubscription?.pricingProgram.algorithm.steps

  useEffect(() => {
    if (selectedSubscription?.pricingProgram) {
      dispatch(
        getMarketDataByPricingProgram(selectedSubscription?.pricingProgram.id)
      )
      dispatch(
        getPricingSignalsByPricingProgram(selectedSubscription?.pricingProgram.id)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedSubscription) {
      dispatch(getPricingProgramsById(selectedSubscription.pricingProgram.id))
    }
  }, [dispatch, selectedSubscription])

  const pricingSignalsTotalSteps = pricingProgramSteps || 1

  if (!selectedSubscription?.pricingProgram) return null

  const signals = filteredMarketData.map(mD => {
    const signal = pricingSignalsById.find(s =>
      isSameDay(new Date(s.date), new Date(mD.date))
    )

    if (signal) {
      return parseFloat(signal.referencePrice.toFixed(2))
    }
    return null
  })

  if (!selectedSubscription) return null

  const benchmarkPrice = parseFloat(getMarkToMarket({
    marketPrice: marketData[marketData.length - 1]?.value || 0,
    signals: pricingSignalsById.map(s => s.referencePrice),
    steps: selectedSubscription?.pricingProgram.algorithm.steps
  }).toFixed(2))

  const executionPrice = parseFloat(getMarkToMarket({
    marketPrice: marketData[marketData.length - 1]?.value || 0,
    signals: pricingSignalsById.map(s => s.executionPrice),
    steps: selectedSubscription?.pricingProgram.algorithm.steps
  }).toFixed(2))

  const marketDataLastValue = marketData[marketData.length - 1]
  const marketDataPreviousValue = marketData[marketData.length - 2]

  const handleSubscribersPress = (pricingProgramId: string) => {
    navigation.navigate('PricingProgramSubscribers', {
      pricingProgramId, pricingProgramStatus: state as PricingProgramStatus
    })
  }

  return (
    <Content>
      <SubContent>
        <View>
          <CardText>
            <ChartTitle>
              {t('Components.charts.titles.performance')}
              {' '}
              {format(new Date(selectedSubscription.pricingProgram.startDate), 'dd/MM/yy')}
              {' - '}
              {format(new Date(selectedSubscription.pricingProgram.endDate), 'dd/MM/yy')}
            </ChartTitle>
            <TouchableOpacity
              onPress={() => setModalPerformanceChartVisible(true)}
            >
              <QuestionIcon
                testID='pp-farmer-question-icon'
                color={theme.colors.dark}
              />
            </TouchableOpacity>
          </CardText>
          <View style={{ padding: 16 }}>
            <PerformanceBrokerCard
              destination={selectedSubscription.pricingProgram.product.destination}
              broker={userFirstName + ' ' + userLastName}
              tons={selectedSubscription.tons}
              executionPrice={executionPrice}
              lastMarketData={marketDataLastValue?.value || 0}
              currency="USD"
              minPrice={parseInt(Math.min(...pricingProgramSignalsReferencePrice).toFixed(1)) || 0}
              maxPrice={parseInt(Math.max(...pricingProgramSignalsReferencePrice).toFixed(1)) || 0}
              benchmarkPrice={benchmarkPrice}
              algorithmTypeText={getTranslation(selectedSubscription?.pricingProgram.algorithm.name) || ''}
              coveragePercent={(pricingProgramSignalsExecutionPrice.length / pricingSignalsTotalSteps) * 100}
              received={pricingProgramSignalsExecutionPrice.length}
              total={pricingProgramSteps || 0}
              state={state as PricingProgramStatus}
              subscribers={selectedSubscription.subscribers.length}
              onSubscribersPress={() =>
                handleSubscribersPress(selectedSubscription.pricingProgramId)}
            />
          </View>
        </View>
        <View>
          <PricingSignalPricingProgramChart
            state={state as PricingProgramStatus}
            steps={pricingProgramSteps || 0}
            data={pricingProgramSignalsReferencePrice || []}
            dates={pricingProgramSignalsExecutionDate || []}
            startDate={selectedSubscription.pricingProgram.startDate}
            endDate={selectedSubscription.pricingProgram.endDate}
          />
        </View>
        <View>
          <HistoricalPricesPricingProgramChart
            mDPreviousValue={marketDataPreviousValue?.value || 0}
            mDLastValue={parseFloat(marketDataLastValue?.value.toFixed(2)) || 0}
            data={marketDataValues}
            onFilter={updateHistoricalPricesPPChartData}
            filter={filter}
            startDate={startDate()}
            endDate={endDate}
            signals={signals}
            state={state as PricingProgramStatus}
            totalDataLength={marketData.length}
            ppStartMarketData={ppStartMarketData}
          />
        </View>
        <HelpModal
          title={t('Components.modal.pricingProgramDetails.farmer.performance.title') + programName.toUpperCase()}
          body={t('Components.modal.pricingProgramDetails.farmer.performance.body')}
          visible={modalPerformanceChartVisible}
          buttons={[
            {
              label: t('Components.modal.button.ok'),
              onPress: () => setModalPerformanceChartVisible(false),
              testID: 'help-modal-button',
              variant: 'primary'
            }
          ]}
        />
        <View style={{ height: '15vh' }} />
      </SubContent>
    </Content>
  )
}

export default PricingProgramBrokerDetails
