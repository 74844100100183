import { createSelector } from 'reselect'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { RootState } from 'store'

const selectFarmerList = createSelector((state: RootState) => state.subscriptions.farmerList, a => a)

const selectCancelledPricingProgramsFarmer = () => createSelector(
  selectFarmerList,
  (farmerList) => {
    const cancelledSubscriptions = farmerList.filter(fl =>
      [
        SubscriptionStatus.cancelled,
        SubscriptionStatus.rejected
      ].includes(fl.status)
    )
    return cancelledSubscriptions
  }
)

export default selectCancelledPricingProgramsFarmer
