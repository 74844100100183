import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { get } from '@hedgit/lib/utils/axios'

import { Container } from './styled'

const TermsAndConditions = () => {
  const { i18n: { language } } = useTranslation()
  const [html, setHtml] = useState<string>('')

  const getHtml = useCallback(async () => {
    try {
      const res = await get(`/terms/${language}`)
      setHtml(res as string)
    } catch (e) {
      console.log(e)
    }
  }, [language])

  useEffect(() => {
    getHtml()
  }, [getHtml])

  return (
    <Container>
      { /* eslint-disable-next-line react/no-danger */ }
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  )
}

export default TermsAndConditions
