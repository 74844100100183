/* eslint-disable max-len */

import React from 'react'
import { Svg, Path, Rect, G } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
  style: Record<string, unknown>;
}

const ArrowIcon = ({ color, style }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" style={style}>
      <G id={'south_black_24dp' + id} transform="translate(0 -0.437)">
        <Rect id={'Rectangle_1534' + id} data-name={'Rectangle 1534' + id} width="12" height="12" transform="translate(0 0.437)" fill="none" />
        <Path id={'Path_667' + id} data-name={'Path 667' + id} d="M12.255,8.736l-.731-.731L9.146,10.379V2H8.109v8.379L5.731,8,5,8.736l3.627,3.627Z" transform="translate(-2.409 -0.964)" fill={color} />
      </G>
    </Svg>
  )
}

export default ArrowIcon
