/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, G } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const NotificationsBellIcon = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'notifications_black_24dp' + id} width={width} height={height} viewBox="0 0 24 24">
      <Path id={'Path_436' + id} data-name={'Path_436' + id} d="M0,0H24V24H0Z" fill="none" />
      <G id={'notifications_black_24dp_1_' + id} data-name={'notifications_black_24dp (1)' + id}>
        <Path id={'Path_1144' + id} data-name={'Path_1144' + id} d="M0,0H24V24H0Z" fill="none" />
        <Path id={'Path_1145' + id} data-name={'Path_1145' + id} d="M12,22a2.006,2.006,0,0,0,2-2H10A2.006,2.006,0,0,0,12,22Zm6-6V11c0-3.07-1.63-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.64,5.36,6,7.92,6,11v5L4,18v1H20V18Zm-2,1H8V11c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5Z" fill={color} />
      </G>
    </Svg>
  )
}

export default NotificationsBellIcon
