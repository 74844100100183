import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get } from '@hedgit/lib/utils/axios'

import { User } from '@hedgit/lib/interfaces/user'

import { getBrokersPending, getBrokersFulfilled, getBrokersFailed } from './actions'
import { BrokersActionTypes } from './types'

interface Response {
  brokers: User[];
}

export const getBrokers = (): ThunkAction<void, unknown, unknown, BrokersActionTypes> => {
  return async (dispatch) => {
    dispatch(getBrokersPending())
    try {
      const response = await get<APIResponse<Response>>('/brokers')
      dispatch(getBrokersFulfilled(response.data.brokers))
    } catch (error) {
      dispatch(getBrokersFailed(error))
    }
  }
}
