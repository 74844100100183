import { APIResponse } from '@hedgit/lib/types/api'

import { post } from '@hedgit/lib/utils/axios'

interface APIVerifyResponse {
  userExists: false;
}

export const verifyPhoneNumber = async (phoneNumber: string) => {
  try {
    const response = await post<APIResponse<APIVerifyResponse>>('/auth/verify', {
      phone: phoneNumber
    })
    return response.data.userExists
  } catch (e) {
    return Promise.reject(e)
  }
}
