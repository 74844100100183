import { action } from 'typesafe-actions'

import { User } from '@hedgit/lib/interfaces/user'

import { BrokersActionConsts } from './types'

export const getBrokersPending = () =>
  action(BrokersActionConsts.GET_BROKERS_PENDING)

export const getBrokersFulfilled = (Brokers: User[]) =>
  action(BrokersActionConsts.GET_BROKERS_FULFILLED, Brokers)

export const getBrokersFailed = (error: string) =>
  action(BrokersActionConsts.GET_BROKERS_FAILED, error)
