/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Defs, G, Rect } from 'react-native-svg'
import type { StyleProp, ViewStyle } from 'react-native'

import useId from '../../hooks/useId'

interface Props {
  style?: StyleProp<ViewStyle> | {transform: string};
}

const Background = ({ style }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg viewBox="0 0 550.332 750.353" style={style as StyleProp<ViewStyle>}>
      <Defs>
        <clipPath id="clip-path">
          <Path id="Path_34666" data-name="Path 34666" d="M0-444.173H550.332v-750.353H0Z" transform="translate(0 1194.526)" fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <Rect id="Rectangle_3583" data-name="Rectangle 3583" width="461.415" height="755.38" fill="none" />
        </clipPath>
      </Defs>
      <G id={'Group_8162' + id} data-name={'Group_8162' + id} transform="translate(-3 1194.526)">
        <G id={'Group_8161' + id} data-name={'Group_8161' + id} transform="translate(3 -1194.526)" clipPath="url(#clip-path)">
          <G id={'Group_8156' + id} data-name={'Group_8156' + id} transform="translate(-5.653 -0.631)" opacity="0.4">
            <G id={'Group_8155' + id} data-name={'Group_8155' + id}>
              <G id={'Group_8154' + id} data-name={'Group_8154' + id} clipPath="url(#clip-path-2)">
                <G id={'Group_8153' + id} data-name={'Group_8153' + id} transform="translate(5.653 0.63)">
                  <Path id={'Path_34664' + id} data-name={'Path_34664' + id} d="M-259.738-199.823l-223.59-337.566H-698.52l223.59,337.566a68.11,68.11,0,0,1,0,75.221L-698.52,212.964h215.192L-259.738-124.6a68.11,68.11,0,0,0,0-75.221" transform="translate(698.52 537.389)" fill="#efefef" />
                </G>
              </G>
            </G>
          </G>
          <G id={'Group_8160' + id} data-name={'Group_8160' + id} transform="translate(94.57 -0.631)" opacity="0.4">
            <G id={'Group_8159' + id} data-name={'Group_8159' + id}>
              <G id={'Group_8158' + id} data-name={'Group_8158' + id} clipPath="url(#clip-path-2)">
                <G id={'Group_8157' + id} data-name={'Group_8157' + id} transform="translate(5.653 0.63)">
                  <Path id={'Path_34665' + id} data-name={'Path_34665' + id} d="M-259.738-199.823l-223.59-337.566H-698.52l223.59,337.566a68.11,68.11,0,0,1,0,75.221L-698.52,212.964h215.192L-259.738-124.6a68.11,68.11,0,0,0,0-75.221" transform="translate(698.52 537.389)" fill="#efefef" />
                </G>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>

  )
}

export default Background
