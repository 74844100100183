/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { AreaChart, XAxis } from 'react-native-svg-charts'
import { Defs, LinearGradient, Stop } from 'react-native-svg'
import { theme } from '@hedgit/lib/theme'
import { useTranslation } from 'react-i18next'
import { Text as TextRN, View, TouchableOpacity } from 'react-native'
import { format } from 'date-fns'

import { HelpModal } from '@hedgit/lib/components/modals/help-modal'

import { Dots } from './dots'
import {
  LabelContainer,
  getGradientColor,
  getDotsAndLineColor,
  getSignalsPriceColor,
  getMonthLabelColor
} from './styled'

import {
  ChartTitle,
  ChartHeader,
  ChartContainer
} from '../styled'
import { Line } from '../../components/line'
import Question from '../../../icons/question'
import { PricingProgramStatus } from '../../../../enums/pricing-program-status'
import { setLanguageLocale } from '../../../../translations/getTranslation'

interface PricingSignalPricingProgramChartProps {
  data: number [];
  dates: Date[];
  steps: number;
  startDate: Date;
  endDate: Date;
  state: PricingProgramStatus;
}

interface GradientProps {
  state: PricingProgramStatus;
}

const Gradient = ({
  state
}: GradientProps) => {
  const gradientColor = getGradientColor(state as PricingProgramStatus)

  return (
    <Defs key="gradients">
      <LinearGradient id="gradients" x1="0%" y1="0%" x2="0%" y2="100%">
        <Stop offset="0%" stopColor={gradientColor} stopOpacity={0.8} />
        <Stop offset="40%" stopColor={gradientColor} stopOpacity={0.0} />
      </LinearGradient>
    </Defs>
  )
}

const PricingSignalPricingProgramChart = ({
  data = [],
  dates = [],
  steps = 0,
  startDate,
  endDate,
  state
}: PricingSignalPricingProgramChartProps) => {
  const { t } = useTranslation()

  const [modalPricingSignalVisible, setModalPricingSignalVisible] = useState(false)
  const dotsAndLineColor = getDotsAndLineColor(state as PricingProgramStatus)

  const signalsPriceColor = getSignalsPriceColor(state as PricingProgramStatus)
  const monthLabelColor = getMonthLabelColor(state as PricingProgramStatus)

  if (!data.length || !steps) return null

  const dataX = []
  const cleanData = []

  for (let i = 0; i < steps; i++) {
    dataX.push(i + 1)
  }

  const formatDays = dates.map((day) => {
    return format(new Date(day), 'd')
  })

  for (let i = data.length - 1; i < steps - 1; i++) {
    formatDays.push('')
  }

  const formatMonths = dates.map((month) => {
    const formattedMonth = format(new Date(month), 'MMM', { locale: setLanguageLocale() })
    return formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)
  })

  for (let i = data.length - 1; i < steps - 1; i++) {
    formatMonths.push('')
  }

  data.map((dat) => {
    if (dat !== 0) {
      return cleanData.push(dat)
    } else {
      return cleanData
    }
  })

  const maxValue = Math.max(...cleanData)
  const minValue = Math.min(...cleanData)

  const startMonth = format(new Date(startDate), 'M')
  const endMonth = format(new Date(endDate), 'M')

  const getMonthNameAbbr = (mon: string) => {
    return t(`Components.card.monthAbbr.${mon}`)
  }

  const startMonthLabel = getMonthNameAbbr(startMonth).toString().slice(0, 3)
  const endMonthLabel = getMonthNameAbbr(endMonth).toString().slice(0, 3)

  const startYearLabel = format(new Date(startDate), 'yy')
  const endYearLabel = format(new Date(endDate), 'yy')

  return (
    <ChartContainer>
      <ChartHeader>
        <ChartTitle>
          {t('Components.charts.titles.pricingSignals')}
        </ChartTitle>
        <TouchableOpacity
          onPress={() => setModalPricingSignalVisible(true)}
        >
          <Question
            testid='pp-ps-chart-question-icon'
            color={theme.colors.lightGray}
            width={24}
            height={24}
          />
        </TouchableOpacity>
      </ChartHeader>
      <View
        style={{
          width: '100%',
          paddingBottom: 5
        }}
      >
        <AreaChart
          style={{
            height: 210,
            backgroundColor: theme.colors.white,
            overflow: 'visible',
            zIndex: 2,
            borderBottomColor: theme.colors.lightGray,
            borderBottomWidth: 1

          }}
          gridMin={minValue - 20}
          gridMax={maxValue + 30}
          data={cleanData}
          xMax={steps - 1}
          svg={{
            fill: 'url(#gradients)'
          }}
          contentInset={{ top: 20, bottom: 20, left: 15, right: 15 }}
        >
          <Line color={dotsAndLineColor} />
          <Dots colorDots={dotsAndLineColor} colorLabel={signalsPriceColor} />
          <Gradient state={state} />
        </AreaChart>
        <LabelContainer>
          <TextRN
            style={{
              color: monthLabelColor,
              fontSize: 10,
              fontFamily: 'lato'
            }}
          >
            {startMonthLabel.toUpperCase() + ' ' + startYearLabel}
          </TextRN>
          <TextRN
            style={{
              color: monthLabelColor,
              fontSize: 10,
              fontFamily: 'lato'
            }}
          >
            {endMonthLabel.toUpperCase() + ' ' + endYearLabel}
          </TextRN>
        </LabelContainer>
        <XAxis
          data={dataX || []}
          formatLabel={(index) => dataX[index]}
          contentInset={{ left: 15, right: 15 }}
          style={{ marginTop: 10 }}
          svg={{ fontFamily: 'lato', fontSize: 10, fill: theme.colors.blue }}
        />
        <XAxis
          data={formatMonths || []}
          formatLabel={(index) => formatMonths[index]}
          contentInset={{ left: 15, right: 15 }}
          style={{ marginTop: 4, marginBottom: 4 }}
          svg={{ fontFamily: 'lato', fontSize: 10, fill: theme.colors.gray }}
        />
        <XAxis
          data={formatDays || []}
          formatLabel={(index) => formatDays[index]}
          contentInset={{ left: 15, right: 15 }}
          svg={{ fontFamily: 'lato', fontSize: 10, fill: theme.colors.gray }}
        />
      </View>
      <HelpModal
        title={t('Components.modal.pricingProgramChart.pricingSignal.title')}
        body={t('Components.modal.pricingProgramChart.pricingSignal.body')}
        visible={modalPricingSignalVisible}
        buttons={[
          {
            label: t('Components.modal.button.ok'),
            onPress: () => setModalPricingSignalVisible(false),
            testID: 'help-modal-button',
            variant: 'primary'
          }
        ]}
      />
    </ChartContainer>
  )
}

export default PricingSignalPricingProgramChart
