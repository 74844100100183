import { Reducer } from 'redux'

import { PricingProgramsActionTypes, PricingProgramsState, PricingProgramsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: PricingProgramsState = {
  list: [],
  signalList: [],
  isFetching: false,
  error: '',
  pricingProgramDetails: {}
}

export const pricingProgramsReducer: Reducer<PricingProgramsState, PricingProgramsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): PricingProgramsState => {
  switch (action.type) {
    case PricingProgramsActionConsts.GET_PRICING_PROGRAMS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case PricingProgramsActionConsts.GET_PRICING_PROGRAMS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case PricingProgramsActionConsts.GET_PRICING_PROGRAMS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case PricingProgramsActionConsts.GET_PRICING_PROGRAM_BY_ID_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case PricingProgramsActionConsts.GET_PRICING_PROGRAM_BY_ID_FULFILLED:
      return {
        ...state,
        isFetching: false,
        pricingProgramDetails: {
          ...state.pricingProgramDetails,
          [action.payload.id]: action.payload
        }
      }
    case PricingProgramsActionConsts.GET_PRICING_PROGRAM_BY_ID_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
