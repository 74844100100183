import styled from 'styled-components/native'

import Caption from '../../../typography/caption'
import H2 from '../../../typography/h2'

export const Container = styled.View`
  align-items: center;
  padding-bottom: 20px;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  width: 100%;
  max-width: 600px;
  align-self: center;
`

export const Title = styled(H2)`
  padding: 0 16px;
  margin: 44px 0;
  text-align: center;
`

export const TermsAndConditionsContainer = styled.View`
  margin-top: 20px;
`

export const TermsAndConditionsText = styled(Caption)`
  text-align: center;
  color: ${props => props.theme.colors.gray};
`

export const TermsAndConditionsLink = styled(Caption)`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

export const ButtonContainer = styled(Caption)`
  margin-top: 28px;
  padding: 0 16px;
  width: 100%;
`
