import styled from 'styled-components/native'

import Body from '@hedgit/lib/components/typography/body'

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  margin-top: 6px;
  border-radius: 10px;
  border: 1px dashed #979c9f;
  width: 90%;
  background-color: ${props => props.theme.colors.whiteDark};
  max-width: 400px;
`

export const Icon = styled.View`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: #e6e8ea;
`

export const CardText = styled(Body)`
  margin-top: 2.5px;
`
