import React, { useCallback, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  ListRenderItem,
  ListRenderItemInfo,
  SectionList
} from 'react-native'
import { formatDistanceToNow, isToday, isYesterday } from 'date-fns'
import { orderBy } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { setLanguageLocale } from '@hedgit/lib/translations/getTranslation'

import { getNotifications, markNotificationAsSeen } from '@hedgit/lib/store/modules/notifications/thunks'

import { NotificationType } from '@hedgit/lib/enums/notification-type'
import { Entity } from '@hedgit/lib/enums/entity'

import { Notification } from '@hedgit/lib/interfaces/notifications'
import { RootStackNavigationType } from '@hedgit/lib/interfaces/root-stack-params-list'

import { NotificationCard } from '@hedgit/lib/components/cards/notification-card'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'

import { Content, SubContent, SectionTitle } from './styled'

import { useDispatch, useSelector } from '../../store'

type ListItem = {
  title: string;
  data: Notification[];
}

const Notifications = () => {
  const { t } = useTranslation()
  const navigation = useNavigation<RootStackNavigationType>()

  const list = useSelector((state) => state.notifications.list)
  const orderList = orderBy(list, 'time', 'desc')

  const dispatch = useDispatch()

  const today: ListItem = {
    title: t('Notifications.today'),
    data: []
  }

  const yesterday: ListItem = {
    title: t('Notifications.yesterday'),
    data: []
  }

  const before: ListItem = {
    title: t('Notifications.before'),
    data: []
  }

  orderList.forEach((notification) => {
    if (isToday(new Date(notification.time))) {
      today.data.push(
        { ...notification, time: formatDistanceToNow(new Date(notification.time), { locale: setLanguageLocale() }) })
    }
    if (isYesterday(new Date(notification.time))) {
      yesterday.data.push(
        { ...notification, time: formatDistanceToNow(new Date(notification.time), { locale: setLanguageLocale() }) })
    }
    if ((!isToday(new Date(notification.time))) && !isYesterday(new Date(notification.time))) {
      before.data.push(
        { ...notification, time: formatDistanceToNow(new Date(notification.time), { locale: setLanguageLocale() }) })
    }
  })

  const notificationsList: ListItem[] = []

  if (today.data.length) {
    notificationsList.push(today)
  }
  if (yesterday.data.length) {
    notificationsList.push(yesterday)
  }
  if (before.data.length) {
    notificationsList.push(before)
  }

  useEffect(() => {
    dispatch(getNotifications())
  }, [dispatch])

  const onPressNotification = useCallback((notification: Notification) => {
    if (!notification.isRead) {
      dispatch(markNotificationAsSeen(notification.id))
      if (notification.notificationType === NotificationType.subscriptionAccepted ||
        notification.notificationType === NotificationType.subscriptionPending ||
        notification.notificationType === NotificationType.cancelSubscriptionPending ||
        notification.notificationType === NotificationType.cancelSubscriptionRejected) {
        navigation.navigate('NavigationTabs', {
          screen: 'PricingPrograms',
          params: {
            screen: 'PricingProgramsActiveTab'
          }
        })
      }
      if (notification.notificationType === NotificationType.cancelSubscriptionAccepted ||
        notification.notificationType === NotificationType.subscriptionRejected) {
        navigation.navigate('NavigationTabs', {
          screen: 'PricingPrograms',
          params: {
            screen: 'PricingProgramsCancelledTab'
          }
        })
      }
      if (notification.notificationType === NotificationType.signalCreated) {
        navigation.navigate('NavigationTabs', {
          screen: 'PricingSignals',
          params: {
            screen: 'PricingSignalsPendingTab'
          }
        })
      }
      if (notification.notificationType === NotificationType.signalExecuted) {
        navigation.navigate('NavigationTabs', {
          screen: 'PricingSignals',
          params: {
            screen: 'PricingSignalsExecutedTab'
          }
        })
      }
    }
  }, [dispatch, navigation])

  const renderCard: ListRenderItem<Notification> = useCallback(
    (notification: ListRenderItemInfo<Notification>) => {
      return (
        <TouchableOpacity
          testID={`notification-card${notification.item.id}`}
          onPress={() => onPressNotification(notification.item)}
        >
          <NotificationCard notification={notification.item} />
        </TouchableOpacity>
      )
    },
    [onPressNotification]
  )

  return (
    <Content>
      <SubContent>
        <SectionList
          testID='notification-sectionList'
          sections={notificationsList}
          keyExtractor={(item, index) => item.id + index}
          renderItem={renderCard}
          renderSectionHeader={({ section: { title } }) => (
            <SectionTitle>{title}</SectionTitle>
          )}
          contentContainerStyle={notificationsList.length === 0 ? { alignItems: 'center' } : { paddingHorizontal: 16 }}
          ListEmptyComponent={<EmptyCard text={t('Empty.notifications')} entity={Entity.notifications} />}
        />
        <View style={{ height: '5vh' }} />
      </SubContent>
    </Content>
  )
}

export default Notifications
