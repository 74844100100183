import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import { View } from 'react-native'
import type { HeaderTitleProps } from '@react-navigation/elements'
import { useTranslation } from 'react-i18next'

import H2 from '@hedgit/lib/components/typography/h2'

import { SignIn } from './sign-in'
import { SignInVerification } from './verification/sign-in'
import { SignUpFarmer } from './sign-up/farmer'
import { SignUpBroker } from './sign-up/broker'
import { SignUpVerification } from './verification/sign-up'
import { TermsAndConditions } from './terms-and-conditions'

import { HeaderLeftButton } from '../../components/header-left-button'

const Stack = createStackNavigator()

const SetHeaderTitleByProps = (props: HeaderTitleProps) => (
  <H2>{props.children}</H2>
)

const SetHeaderTitleByString = (title: string) => (
  <H2>{title}</H2>
)

const AuthNavigator = () => {
  const { t } = useTranslation()
  return (
    <View style={{ height: '100%' }}>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerTitleAlign: 'center',
            headerTitle: SetHeaderTitleByProps,
            headerShadowVisible: false,
            headerLeft: HeaderLeftButton,
            headerLeftContainerStyle: {
              paddingLeft: 16
            }
          }}
        >
          <Stack.Screen
            name="SignIn"
            component={SignIn}
            options={{
              headerShown: false
            }}
          />
          <Stack.Screen
            name="SignInVerification"
            component={SignInVerification}
            options={{
              headerTitle: () => SetHeaderTitleByString(t('AuthStack.verificationCode'))
            }}
          />
          <Stack.Screen
            name="SignUpFarmer"
            component={SignUpFarmer}
            options={{
              headerTitle: () => SetHeaderTitleByString(t('AuthStack.signUpFarmer'))
            }}
          />
          <Stack.Screen
            name="SignUpBroker"
            component={SignUpBroker}
            options={{
              headerTitle: () => SetHeaderTitleByString(t('AuthStack.signUpBroker'))
            }}
          />
          <Stack.Screen
            name="SignUpVerification"
            component={SignUpVerification}
            options={{
              headerTitle: () => SetHeaderTitleByString(t('AuthStack.verificationCode'))
            }}
          />
          <Stack.Screen
            name="TermsAndConditions"
            component={TermsAndConditions}
            options={{
              headerTitle: () => SetHeaderTitleByString(t('AuthStack.termsAndConditions'))
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </View>

  )
}

export default AuthNavigator
