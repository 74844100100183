import styled, { DefaultTheme } from 'styled-components/native'

import { theme } from '../../../theme'
import ButtonText from '../../typography/button-text'

import type { ButtonProps } from '.'

interface PropsWithTheme extends Partial<ButtonProps> {
  theme: DefaultTheme;
}

const { colors } = theme

const getThemeColor = ({ theme, variant }: PropsWithTheme) => theme.colors[variant]

const getBackground = (props: PropsWithTheme) => {
  const { variant, disabled } = props

  if (variant === 'primary') {
    if (disabled) {
      return colors.disabledGray
    }
    return getThemeColor(props)
  }
  if (variant === 'secondary' || variant === 'secondary-dark') {
    return colors.white
  }
}

const getColor = (props: PropsWithTheme) => {
  const { variant } = props
  if (variant === 'primary') {
    return colors.white
  }
  if (variant === 'secondary') {
    return colors.primary
  }
  if (variant === 'secondary-dark') {
    return colors.dark
  }
}

const getBorderColor = (props: PropsWithTheme) => {
  const { variant, disabled } = props
  if (variant === 'primary') {
    if (disabled) {
      return colors.disabledGray
    }
    return getThemeColor(props)
  }
  if (variant === 'secondary') {
    return colors.primary
  }
  if (variant === 'secondary-dark') {
    return colors.dark
  }
}

const getOpacity = (props: PropsWithTheme) => {
  const { disabled, variant } = props
  if (variant === 'secondary' || variant === 'secondary-dark') {
    return disabled ? 0.4 : 1
  }
}

type ContainerProps = Pick<ButtonProps, 'variant' | 'disabled'>

export const Container = styled.View<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid;
  font-size: 16px;
  background-color: ${props => getBackground(props as PropsWithTheme)};
  border-color: ${props => getBorderColor(props as PropsWithTheme)};
  opacity: ${props => getOpacity(props as PropsWithTheme)};
`

type TextProps = Pick<ButtonProps, 'variant' | 'disabled'>

export const StyledButtonText = styled(ButtonText)<TextProps>`
  color: ${props => getColor(props as PropsWithTheme)};
`
