import { createSelector } from 'reselect'
import { isAfter } from 'date-fns'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { BrokerSubscription } from '@hedgit/lib/interfaces/subscription'

import { RootState } from 'store'

const selectBrokerList = createSelector((state: RootState) => state.subscriptions.brokerList, a => a)

const selectExecutedPricingProgramsBroker = () => createSelector(
  selectBrokerList,
  (brokerList) => {
    return brokerList.reduce<BrokerSubscription[]>((accum, pp) => {
      const executedSubscriptions = pp.subscribers?.filter(subcriber =>
        [SubscriptionStatus.active, SubscriptionStatus.cancelled].includes(subcriber.status)
      )
      const isFinished = isAfter(new Date(), new Date(pp.pricingProgram.endDate))
      if (executedSubscriptions?.length && isFinished) {
        accum.push({
          ...pp,
          subscribers: executedSubscriptions
        })
      }

      return accum
    }, [])
  }
)

export default selectExecutedPricingProgramsBroker
