import { action } from 'typesafe-actions'

import { PricingProgram } from '@hedgit/lib/interfaces/pricing-program'

import { PricingProgramsActionConsts, PricingProgramDetails } from './types'

export const getPricingProgramPending = () =>
  action(PricingProgramsActionConsts.GET_PRICING_PROGRAMS_PENDING)

export const getPricingProgramFulfilled = (pricingPrograms: PricingProgram[]) =>
  action(PricingProgramsActionConsts.GET_PRICING_PROGRAMS_FULFILLED, pricingPrograms)

export const getPricingProgramFailed = (error: string) =>
  action(PricingProgramsActionConsts.GET_PRICING_PROGRAMS_FAILED, error)

export const getPricingProgramByIdPending = () =>
  action(PricingProgramsActionConsts.GET_PRICING_PROGRAM_BY_ID_PENDING)

export const getPricingProgramByIdFulfilled = (pricingProgram: PricingProgramDetails) =>
  action(PricingProgramsActionConsts.GET_PRICING_PROGRAM_BY_ID_FULFILLED, pricingProgram)

export const getPricingProgramByIdFailed = (error: string) =>
  action(PricingProgramsActionConsts.GET_PRICING_PROGRAM_BY_ID_FAILED, error)
