import styled from 'styled-components/native'

import Caption from '../../../typography/caption'
import DateText from '../../../typography/date-text'

export const Container = styled.View`
  width: auto;
`

export const SubscribersContainer = styled.TouchableOpacity`
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 15px;
  margin-bottom: 15px;
  width: auto;
  background-color: white;
`

export const Initials = styled.View`
  align-items: center;
  border-radius: 50%;
  border: 2px solid white;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: -6px;
  width: 30px;
  z-index: 2;
  &:first-child {
    margin-left: 0;
  }
`

export const InitialsText = styled(Caption)`
  color: ${props => props.theme.colors.white};
`

export const NumberText = styled(Caption)`
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
`

export const Number = styled(Caption)`
  background-color: ${props => props.theme.colors.yellow};
  height: 30px;
  width: 30px;
  align-items: center;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  margin-left: -6px;
  z-index: 3;
`

export const SubscribersLabel = styled(DateText)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 95px;
`
