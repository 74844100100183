import { ActionType } from 'typesafe-actions'

import { Notification } from '@hedgit/lib/interfaces/notifications'

import * as actions from './actions'
import { getNotifications } from './thunks'

export enum NotificationsActionConsts {
  GET_NOTIFICATIONS_PENDING='GET_NOTIFICATIONS_PENDING',
  GET_NOTIFICATIONS_FULFILLED='GET_NOTIFICATIONS_FULFILLED',
  GET_NOTIFICATIONS_FAILED='GET_NOTIFICATIONS_FAILED',
  MARK_NOTIFICATION_AS_SEEN_PENDING='MARK_NOTIFICATION_AS_SEEN_PENDING',
  MARK_NOTIFICATION_AS_SEEN_FULFILLED='MARK_NOTIFICATION_AS_SEEN_FULFILLED',
  MARK_NOTIFICATION_AS_SEEN_FAILED='MARK_NOTIFICATION_AS_SEEN_FAILED'
}

export type NotificationsActionTypes = ActionType<typeof actions>
export type NotificationsThunksTypes = typeof getNotifications

export interface NotificationsState {
  readonly list: Notification[];
  readonly isFetching: boolean;
  readonly error: string;
}
