import { Reducer } from 'redux'

import { BrokersActionTypes, BrokersState, BrokersActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: BrokersState = {
  brokerList: [],
  isFetching: false,
  error: ''
}

export const brokersReducer: Reducer<BrokersState, BrokersActionTypes | SignOutActionType> = (
  state = initialState,
  action
): BrokersState => {
  switch (action.type) {
    case BrokersActionConsts.GET_BROKERS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case BrokersActionConsts.GET_BROKERS_FULFILLED:
      return {
        ...state,
        brokerList: action.payload,
        isFetching: false
      }
    case BrokersActionConsts.GET_BROKERS_FAILED:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
