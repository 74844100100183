import React, { useCallback } from 'react'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import selectExecutedPricingProgramsBroker from '@hedgit/web/src/store/selectors/selectExecutedPricingProgramsBroker'
import { getTranslation } from '@hedgit/lib/translations/getTranslation'
import { useTranslation } from 'react-i18next'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'
import { UserRole } from '@hedgit/lib/enums/user-role'
import { Entity } from '@hedgit/lib/enums/entity'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { BrokerSubscription } from '@hedgit/lib/interfaces/subscription'

import PricingProgramCard from '@hedgit/lib/components/cards/pricing-program'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'

import { useSelector } from 'store'

import useMediaQueries from 'hooks/use-media-queries'

import { CardClickArea, Content, SubContent } from '../styled'
import { RouteType } from '../tabs'

type PricingProgramsBrokerListProps = {
  route: RouteType;
}

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'NavigationTabs'>

const PricingProgramsBrokerList = ({ route }: PricingProgramsBrokerListProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()
  const { columns } = useMediaQueries()

  const list = useSelector(selectExecutedPricingProgramsBroker())
  const isFetching = useSelector(state => state.subscriptions.isFetching)

  const handleSubscribersPress = (pricingProgramId: string) => {
    navigation.navigate('PricingProgramSubscribers', {
      pricingProgramId, pricingProgramStatus: PricingProgramStatus.executed
    })
  }

  const renderCard: ListRenderItem<BrokerSubscription> = useCallback(
    (subscription: ListRenderItemInfo<BrokerSubscription>) => {
      return (
        <CardClickArea onPress={() => {
          navigation.navigate('PricingProgramDetails', {
            id: subscription.item.pricingProgramId,
            crop: getTranslation(subscription.item.pricingProgram.product.crop.name) || '',
            month: subscription.item.pricingProgram.product.month,
            year: subscription.item.pricingProgram.product.year,
            state: route.name.toLowerCase() as PricingProgramStatus,
            role: UserRole.broker
          })
        }}
        >
          <PricingProgramCard
            role={UserRole.broker}
            state={route.name.toLowerCase() as PricingProgramStatus}
            subscription={subscription.item}
            subscribers={subscription.item.subscribers}
            handleSubscribersOnPress={() => handleSubscribersPress(subscription.item.pricingProgramId)}
          />
        </CardClickArea>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (isFetching && !list.length) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Content>
      <SubContent>
        <FlatList
          key={`flatListWith-${columns}-Columns`}
          numColumns={columns}
          testID='pp-broker-executed-flatList'
          data={list}
          renderItem={renderCard}
          keyExtractor={data => data.pricingProgramId}
          style={{ width: '100%' }}
          contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
          ListEmptyComponent={<EmptyCard text={t('Empty.pricingPrograms.executed')} entity={Entity.pricingProgram} />}
        />
      </SubContent>
    </Content>
  )
}

export default PricingProgramsBrokerList
