import { useMemo } from 'react'
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult
} from 'firebase/auth'

import { SignUpOptions } from './types'

import { AuthUser } from '../../interfaces/auth-user'
import i18n from '../../translations/i18n'

let confirmationResult: ConfirmationResult
let recaptchaVerifier: RecaptchaVerifier

const useSignUp = (options: SignUpOptions) => {
  const { auth, onCodeVerified, onVerificationCodeSent, onCodeInvalid } = options

  const signUp = async (data: AuthUser) => {
    if (recaptchaVerifier) {
      recaptchaVerifier.render()
    } else {
      recaptchaVerifier = new RecaptchaVerifier('recaptcha-verifier', {
        size: 'invisible'
      }, auth)
    }

    const phone = data.phone

    try {
      const result = await signInWithPhoneNumber(auth, phone, recaptchaVerifier)
      if (!result) throw new Error()
      confirmationResult = result
      onVerificationCodeSent(data)
    } catch (error) {
      return error
    }
  }

  const clearRecaptcha = () => {
    if (recaptchaVerifier) {
      recaptchaVerifier.clear()
      recaptchaVerifier = null
    }
  }

  const signUpVerification = async (code: string) => {
    try {
      if (confirmationResult) {
        const result = await confirmationResult.confirm(code)
        if (!result) throw new Error()
        onCodeVerified(result)
      }
    } catch (error) {
      return onCodeInvalid(i18n.t('ErrorMessages.verificationCode.invalid'))
    }
  }

  const res = {
    signUp,
    signUpVerification,
    clearRecaptcha
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => res, [])
}

export default useSignUp
