import { action } from 'typesafe-actions'

import { MarketData } from '@hedgit/lib/interfaces/market-data'

import { MarketDataActionConsts, MarketDataSummary } from './types'

interface MarketDataFulFilledPayload {
  productId: string;
  data: MarketDataSummary[];
}

interface FullMarketDataFulFilledPayload {
  productId: string;
  data: MarketData[];
}

interface MarketDataByPricingProgramFulFilledPayload {
  pricingProgramId: string;
  data: MarketData[];
}

export const getMarketDataPending = () =>
  action(MarketDataActionConsts.GET_MARKET_DATA_PENDING)

export const getMarketDataFulfilled = (marketData: MarketData[]) =>
  action(MarketDataActionConsts.GET_MARKET_DATA_FULFILLED, marketData)

export const getMarketDataFailed = (error: string) =>
  action(MarketDataActionConsts.GET_MARKET_DATA_FAILED, error)

export const getMarketDataByProductPending = () =>
  action(MarketDataActionConsts.GET_MARKET_DATA_BY_PRODUCT_PENDING)

export const getMarketDataByProductFulfilled = (payload: MarketDataFulFilledPayload) =>
  action(MarketDataActionConsts.GET_MARKET_DATA_BY_PRODUCT_FULFILLED, payload)

export const getFullMarketDataByProductFulfilled = (payload: FullMarketDataFulFilledPayload) =>
  action(MarketDataActionConsts.GET_FULL_MARKET_DATA_BY_PRODUCT_FULFILLED, payload)

export const getMarketDataByProductFailed = (error: string) =>
  action(MarketDataActionConsts.GET_MARKET_DATA_BY_PRODUCT_FAILED, error)

export const getMarketDataByPricingProgramPending = () =>
  action(MarketDataActionConsts.GET_MARKET_DATA_BY_PRICING_PROGRAM_PENDING)

export const getMarketDataByPricingProgramFulfilled = (payload: MarketDataByPricingProgramFulFilledPayload) =>
  action(MarketDataActionConsts.GET_MARKET_DATA_BY_PRICING_PROGRAM_FULFILLED, payload)

export const getMarketDataByPricingProgramFailed = (error: string) =>
  action(MarketDataActionConsts.GET_MARKET_DATA_BY_PRICING_PROGRAM_FAILED, error)

export const createMarketDataPending = () =>
  action(MarketDataActionConsts.CREATE_MARKET_DATA_PENDING)

export const createMarketDataFulfilled = (marketData: MarketData) =>
  action(MarketDataActionConsts.CREATE_MARKET_DATA_FULFILLED, marketData)

export const createMarketDataFailed = (error: string) =>
  action(MarketDataActionConsts.CREATE_MARKET_DATA_FAILED, error)

export const setEditingMarketData = (marketData: MarketData) =>
  action(MarketDataActionConsts.SET_EDITING_MARKET_DATA, marketData)

export const editMarketDataPending = () =>
  action(MarketDataActionConsts.EDIT_MARKET_DATA_PENDING)

export const editMarketDataFulfilled = (marketData: MarketData) =>
  action(MarketDataActionConsts.EDIT_MARKET_DATA_FULFILLED, marketData)

export const editMarketDataFailed = (error: string) =>
  action(MarketDataActionConsts.EDIT_MARKET_DATA_FAILED, error)
