import React, { useEffect, useState, useCallback } from 'react'
import { FormControl, Select, WarningOutlineIcon, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import { APIResponse } from '@hedgit/lib/types/api'
import { theme } from '@hedgit/lib/theme'
import * as yup from 'yup'

import { getBrokers } from '@hedgit/lib/store/modules/brokers/thunks'
import {
  createSubscriptionPending,
  createSubscriptionFulfilled,
  createSubscriptionFailed
} from '@hedgit/lib/store/modules/subscriptions'
import { getSubscriptions } from '@hedgit/lib/store/modules/subscriptions/thunks'

import { post } from '@hedgit/lib/utils/axios'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { Subscription } from '@hedgit/lib/interfaces/subscription'
import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import Title from '@hedgit/lib/components/typography/title'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'
import { InputModal } from '@hedgit/lib/components/modals/input-modal'

import { useSelector, useDispatch } from 'store'

import { Container, InputContainer, RequirementText, Button } from './styled'

const { colors } = theme

type Props = NativeStackScreenProps<RootStackParamList, 'AlgorithmSubscription'>
interface FormData {
  broker: string;
  tons: number;
}

const AlgorithmSubscription = ({ route, navigation }: Props) => {
  const { ppId, program } = route.params
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [error, setError] = useState<string>()

  const currentUser = useSelector(state => state.auth.currentUser)
  const brokers = useSelector(state => state.brokers.brokerList.filter(b => b.id !== currentUser?.id))
  const pricingProgram = useSelector(state => state.pricingPrograms.list)
  const selectedPricingProgram = pricingProgram.find(pp => pp.id === ppId)
  const steps = selectedPricingProgram?.algorithm.steps || 0
  const multiple = steps * 100
  const [subscriptionData, setSubscriptionData] = useState<FormData>({ broker: '', tons: 0 })
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)

  const formatPhoneNumber = (number: string | undefined) => {
    const formattedNumber = number?.replace(/[^\d]/g, '')
    return formattedNumber
  }

  const brokerValidation = yup
    .string()
    .required(t('ErrorMessages.broker.required'))

  const farmerBrokerValidation = yup
    .string()

  const validationSchema = yup.object({
    broker: currentUser?.role === UserRole.broker_farmer ? farmerBrokerValidation : brokerValidation,
    tons: yup
      .number()
      .required(t('ErrorMessages.tons.required'))
      .positive(t('ErrorMessages.tons.positive'))
      .integer(t('ErrorMessages.tons.integer'))
      .typeError(t('ErrorMessages.tons.typeError'))
      .test({
        name: 'tons-multiple-validation',
        test: async (value, testContext) => {
          if (value && value % multiple === 0) return true
          return testContext.createError({ message: t('ErrorMessages.tons.test') + `${multiple}` })
        }
      })
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    reset,
    clearErrors,
    watch
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      broker: ''
    },
    resolver: yupResolver(validationSchema)
  })

  const formReset = useCallback(() => {
    reset({
      broker: '',
      tons: undefined
    })
  }, [reset])

  useEffect(() => {
    dispatch(getBrokers())
    formReset()
  }, [dispatch, formReset])

  const handleSubmit = async (data: FormData) => {
    setConfirmModalVisible(true)
    setSubscriptionData(data)
  }

  const handleCancelPress = () => {
    setConfirmModalVisible(false)
  }

  const handleConfirmPress = async (data: FormData) => {
    let { broker, tons } = data
    let url = '/subscriptions'
    if (currentUser?.role === UserRole.broker_farmer) {
      broker = currentUser.id
      url = '/subscriptions/auto-subscription'
    }
    try {
      dispatch(createSubscriptionPending())
      const response = await post<APIResponse<{subscription: Subscription}>>(
        url,
        {
          pricingProgram: ppId,
          broker: broker,
          tons: tons
        }
      )
      dispatch(createSubscriptionFulfilled(response.data.subscription))
      dispatch(getSubscriptions(UserRole.farmer))
      setConfirmModalVisible(false)
      setModalVisible(true)
      formReset()
    } catch (e) {
      dispatch(createSubscriptionFailed(e as string))
      setConfirmModalVisible(false)
      setError(t('ErrorMessages.subscription'))
    }
  }

  const handleModalClose = () => {
    navigation.navigate('NavigationTabs', { screen: 'PricingPrograms' })
  }

  const getConfirmSubscriptionText = () => {
    if (currentUser?.role === UserRole.broker_farmer) {
      return currentUser?.lastName || currentUser?.firstName
    }

    const selectedBroker = brokers.find((b) => b.id === watch('broker'))

    return selectedBroker?.lastName + (selectedBroker?.firstName ? ' ' + selectedBroker?.firstName : '')
  }

  const confirmSubscriptionText = getConfirmSubscriptionText()

  return (
    <Container>
      <InputContainer>
        <FormControl isInvalid={'tons' in errors}>
          <Title
            testID='als-volume-label'
          >
            {t('AlgorithmSubscription.form.volume.label')}
          </Title>
          <RequirementText>
            {t('AlgorithmSubscription.form.volume.requirement')}
            {multiple}
          </RequirementText>
          <Controller
            name="tons"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <SharedInput
                testID='als-volume-input'
                placeholder={t('AlgorithmSubscription.form.volume.placeholder')}
                value={formatPhoneNumber(value?.toString())}
                onChange={onChange}
                onFocus={() => clearErrors('tons')}
                onBlur={onBlur}
                withLabel={false}
                keyboardType='numeric'
              />
            )}
          />
          <FormControl.ErrorMessage
            testID='als-volumeError-message'
            leftIcon={<WarningOutlineIcon size="xs" />}
          >
            {errors.tons?.message}
          </FormControl.ErrorMessage>
        </FormControl>
      </InputContainer>
      {
        currentUser?.role === UserRole.broker_farmer
          ? null
          : (
            <InputContainer>
              <FormControl isInvalid={'broker' in errors}>
                <Title
                  testID='als-selectBroker-label'
                  style={{
                    marginBottom: 14
                  }}
                >
                  {t('AlgorithmSubscription.form.selectBroker.label')}
                </Title>
                <Controller
                  name="broker"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      testID='als-selectBroker-select'
                      placeholder={t('AlgorithmSubscription.form.selectBroker.placeholder')}
                      selectedValue={value}
                      defaultValue={undefined}
                      onValueChange={onChange}
                      onClose={() => {
                        clearErrors('broker')
                        setError('')
                      }}
                      style={{ backgroundColor: 'red' }}
                      width='100%'
                      h={52}
                      paddingRight={18}
                      paddingLeft={18}
                      borderColor={colors.gray}
                      borderRadius={10}
                      fontSize={14}
                    >
                      {brokers.map(item => (
                        <Select.Item
                          label={item.lastName + ' ' + item.firstName}
                          value={item.id}
                          key={item.id}
                        />
                      ))}
                    </Select>
                  )}
                />
                <FormControl.ErrorMessage
                  testID='als-selectBrokerError-message'
                  leftIcon={<WarningOutlineIcon size="xs" />}
                >
                  {errors.broker?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            </InputContainer>
            )
      }
      <Button
        testID="als-subscribe-button"
        onPress={handleSubmitForm(handleSubmit)}
      >
        {t('Components.button.subscribe')}
      </Button>
      <InputModal
        title={t('Components.modal.algorithmDetails.inputModalConfirm.title')}
        inputText={confirmSubscriptionText}
        variant="confirm"
        requirementText={t('Components.modal.algorithmDetails.inputModalConfirm.requirementText')}
        visible={confirmModalVisible}
        onCancelPress={handleCancelPress}
        onConfirmPress={() => handleConfirmPress(subscriptionData)}
      />
      <Modal
        title={currentUser?.role === UserRole.broker_farmer
          ? t('Components.modal.algorithmAutoSubscription.title') + program.toUpperCase()
          : t('Components.modal.algorithmSubscription.title') + program.toUpperCase()}
        variant='success'
        visible={modalVisible}
        buttons={[
          {
            label: t('Components.modal.button.ok'),
            onPress: handleModalClose,
            testID: 'ok-modal-button',
            variant: 'primary'
          }
        ]}
      />
      {
          error
            ? (
              <Text testID="als-subscribeError-message">
                {error}
              </Text>
              )
            : null
        }
    </Container>
  )
}
export default AlgorithmSubscription
