/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const PhoneIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg width="28.351" height="28.746" viewBox="0 0 28.351 28.746">
      <Path id={'Path_34796' + id} data-name={'Path_34796' + id} d="M27.52,22.468A4.186,4.186,0,0,0,25.705,19.6c-.546-.364-1.094-.771-1.624-1.166-2.126-1.581-4.536-3.372-6.455-1.453a2.284,2.284,0,0,1-2.429.8c-.346-.29-1.775-1.5-3.252-2.964-1.878-1.861-2.063-2.693-.988-4.451,1.74-2.846,1-3.9-1.446-7.4L9.4,2.813C8.13.993,7.1.158,5.954.019,4.606-.144,3.381.746,2.36,1.64-.15,3.836-.7,7.415.918,10.981A33.066,33.066,0,0,0,12.162,24.335c3.383,2.272,6.191,3.713,8.758,3.713A7.067,7.067,0,0,0,25.8,25.979a4.318,4.318,0,0,0,1.724-3.511m-2.737,2.395c-2.9,2.634-5.876,2.185-11.78-1.78A31.511,31.511,0,0,1,2.291,10.357C.936,7.376,1.333,4.542,3.353,2.775A3.984,3.984,0,0,1,5.63,1.508a1.146,1.146,0,0,1,.142.009c.658.08,1.419.767,2.4,2.162l.108.154C10.7,7.291,10.9,7.573,9.671,9.577c-1.635,2.674-.834,4.281,1.214,6.309,1.741,1.725,3.365,3.067,3.433,3.123a.759.759,0,0,0,.137.09,3.677,3.677,0,0,0,4.238-1.056c.9-.9,2.116-.167,4.489,1.6.546.406,1.11.826,1.689,1.212l.009.006a2.692,2.692,0,0,1,1.137,1.733,2.938,2.938,0,0,1-1.234,2.271" transform="translate(0.469 0.348)" fill="#33a3d9" stroke="#33a3d9" strokeWidth="0.7" />
    </Svg>

  )
}

export default PhoneIcon
