import styled from 'styled-components/native'

import Caption from '../../typography/caption'

export const Container = styled.View`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
`

export const InputContainer = styled.View`
  width: 100%;
  gap: 4px;
`

export const ErrorContainer = styled.View`
  height: 30px;
`

export const ButtonsContainer = styled.View`
  width: 100%;
  gap: 10px;
`

export const Error = styled.Text`
  color: ${props => props.theme.colors.red};
  font-size: 12px;
`

export const SignInButtonContainer = styled.View`
  margin: 20px 0px;
  width: 100%;
`

export const Requirements = styled(Caption)`
  color: ${props => props.theme.colors.gray}
`
