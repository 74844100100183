import React, { useCallback } from 'react'
import { Modal as RNModal } from 'react-native'
import { useTranslation } from 'react-i18next'
import { FormControl, Input } from 'native-base'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BrokerPricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import {
  CircleWrapper,
  CenteredView,
  ModalView,
  ButtonContainer,
  ButtonsContainer,
  Button,
  CircleBox,
  Line,
  ExecuteBox,
  ReferenceText
} from './styled'

import { theme } from '../../../theme'
import Body from '../../typography/body'
import H3 from '../../typography/h3'
import H2 from '../../typography/h2'
import yup from '../../../utils/yup'
import ErrorIcon from '../../icons/error'

export interface ModalProps {
  testId: string;
  modalData?: BrokerPricingSignal;
  visible?: boolean;
  onCloseModal?: () => void;
  onPressConfirm: (executionPrice: number) => void;
  onPressCancel: () => void;
}

const { colors } = theme

const ExecuteSignalModal = ({
  testId,
  modalData,
  visible,
  onPressConfirm,
  onPressCancel,
  onCloseModal
}: ModalProps) => {
  const { t } = useTranslation()

  const validationExecutionPrice = yup.object({
    executionPrice: yup
      .number()
      .required(t('ErrorMessages.executionPrice.required'))
      .positive(t('ErrorMessages.executionPrice.positive'))
      .typeError(t('ErrorMessages.executionPrice.typeError'))
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    reset,
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      executionPrice: ''
    },
    resolver: yupResolver(validationExecutionPrice)
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const formReset = useCallback(() => {
    reset({
      executionPrice: ''
    })
  }, [reset])

  const handleInputFocus = () => {
    clearErrors()
  }

  const handleSubmit = data => {
    onPressConfirm(data.executionPrice)
  }

  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView>
        <ModalView testID={testId}>
          <H2>{t('Components.modal.executePricingSignal.title')}</H2>
          <CircleWrapper>
            <CircleBox />
            <ExecuteBox>
              <Body>{t('Components.modal.executePricingSignal.currency').toUpperCase()}</Body>
              <FormControl
                isInvalid={'executionPrice' in errors}
                style={{
                  height: 118
                }}
              >
                <Controller
                  name="executionPrice"
                  control={control}
                  defaultValue=''
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      variant="unstyled"
                      type="numeric"
                      value={value}
                      keyboardType="decimal-pad"
                      maxLength={5}
                      defaultValue=''
                      onChange={onChange}
                      onFocus={handleInputFocus}
                      onBlur={onBlur}
                      color={colors.dark}
                      _invalid={{
                        color: colors.red
                      }}
                      h={85}
                      w="100%"
                      fontSize={57}
                      fontWeight="bold"
                      fontFamily="lato"
                      textAlign="center"
                    />
                  )}
                />
                <Line />
                <FormControl.ErrorMessage
                  testID="si-phone-error-message"
                  style={{ alignItems: 'center' }}
                  leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                  _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
                >
                  {errors.executionPrice?.message}
                </FormControl.ErrorMessage>
              </FormControl>
              <ReferenceText>{t('Components.modal.executePricingSignal.referencePrice').toUpperCase()}</ReferenceText>
              <H3>
                {t('Components.modal.executePricingSignal.currency').toUpperCase()}
                {' '}
                { modalData && modalData.referencePrice }
              </H3>
            </ExecuteBox>
          </CircleWrapper>
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                testID='es-cancel-button'
                variant='secondary'
                onPress={() => {
                  onPressCancel()
                  formReset()
                }}
              >
                {t('Components.button.cancel')}
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                disabled={isDisabled}
                testID='es-confirm-button'
                variant='primary'
                onPress={handleSubmitForm(handleSubmit)}
              >
                {t('Components.button.confirm')}
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default ExecuteSignalModal
