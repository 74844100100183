import styled from 'styled-components/native'

export const BadgeContainer = styled.View`
  position: absolute;
  margin-top: -5px;
  margin-left: 12px;
  width: 15px;
  height: 15px;
  z-index: 1;
`
