/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'

import { ExecutePricingSignalsInfo, setSelectedPricingProgram } from '@hedgit/lib/store/modules/pricing-signals'
import { executePricingSignals } from '@hedgit/lib/store/modules/pricing-signals/thunks'

import { formatDate } from '@hedgit/lib/utils/format'

import { UserRole } from '@hedgit/lib/enums/user-role'
import { PricingSignalStatus } from '@hedgit/lib/enums/pricing-signal-status'
import { Entity } from '@hedgit/lib/enums/entity'

import { BrokerPricingSignal, PricingSignal } from '@hedgit/lib/interfaces/pricing-signal'
import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import PricingSignalCard from '@hedgit/lib/components/cards/pricing-signal'
import { ExecuteSignalModal } from '@hedgit/lib/components/modals/execute-pricing-signal-modal'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'

import useMediaQueries from 'hooks/use-media-queries'

import { CardClickArea, ContainerFlatList, Content, SectionTitle, SubContent } from './styled'

import { RootState, useDispatch, useSelector } from '../../../store'

type Props = NativeStackScreenProps<RootStackParamList, 'NavigationTabs'>

const PricingSignalsPendingTab = ({ navigation }: Props) => {
  const dispatch = useDispatch()
  const userRole = useSelector((state) => state.auth.currentUser?.role)
  const list = useSelector((state) => state.pricingSignals.list || [])
  const brokerList = useSelector((state) => state.pricingSignals.brokerList || [])
  const filteredBrokerList = brokerList.filter((signal) => !signal.executionPrice)
  const filteredFarmerList = list.filter((signal) => !signal.executionPrice)
  const [executionPriceModalVisible, setExecutionPriceModalVisible] = useState<boolean>(false)
  const [modalData, setModalData] = useState<BrokerPricingSignal>()
  const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)
  const [priceConfirmationModalVisible, setPriceConfirmationModalVisible] = useState<boolean>(false)
  const [selectedPricingProgramInfo, setSelectedPricingProgramInfo] = useState<ExecutePricingSignalsInfo>()

  const selectedPricingProgramId = useSelector((state: RootState) => state.pricingSignals.selectedPricingProgram.id)
  const selectedPricingProgramDate = useSelector((state: RootState) => state.pricingSignals.selectedPricingProgram.date)
  const isFetching = useSelector(state => state.pricingSignals.isFetching)

  const { t } = useTranslation()
  const { columns } = useMediaQueries()

  const renderCard: ListRenderItem<PricingSignal> = useCallback(
    (pricingSignal: ListRenderItemInfo<PricingSignal>) => {
      return (
        <CardClickArea>
          <PricingSignalCard
            role={UserRole.farmer}
            state={PricingSignalStatus.pending}
            pricingSignal={pricingSignal.item}
          />
        </CardClickArea>
      )
    },
    [navigation]
  )

  const renderCardByPricingProgram: ListRenderItem<BrokerPricingSignal> = useCallback(
    (pricingSignal: ListRenderItemInfo<BrokerPricingSignal>) => {
      return (
        <CardClickArea
          onPress={() => {
            const selectedPricingProgram = {
              id: pricingSignal.item.id,
              date: pricingSignal.item.date
            }
            dispatch(setSelectedPricingProgram(selectedPricingProgram))
            setModalData(pricingSignal.item)
            setExecutionPriceModalVisible(true)
          }}
        >
          <PricingSignalCard
            role={UserRole.broker}
            state={PricingSignalStatus.pending}
            pricingSignal={pricingSignal.item}
          />
        </CardClickArea>
      )
    },
    [navigation]
  )

  const isExecutionPriceOutOfRange = (price: number, referenceValue: number) => {
    const isOver = referenceValue + (referenceValue * 5 / 100)

    const isBelow = referenceValue - (referenceValue * 5 / 100)

    return price <= isBelow || price >= isOver
  }

  const handleConfirmExecutedPrice = (executionPrice: number) => {
    const ppInfo = {
      pricingProgramId: selectedPricingProgramId,
      signalDate: formatDate(new Date(selectedPricingProgramDate)),
      executionPrice: executionPrice
    }
    setSelectedPricingProgramInfo(ppInfo)

    const executionPriceOutOfRange = isExecutionPriceOutOfRange(executionPrice, modalData?.referencePrice || 0)
    if (executionPriceOutOfRange) {
      setPriceConfirmationModalVisible(true)
    } else {
      setPriceConfirmationModalVisible(false)
      dispatch(executePricingSignals(ppInfo))
      handleExecuteSignalModalClose()
      setSuccessModalVisible(true)
    }
  }

  const handleExecuteSignalModalClose = () => {
    setModalData(undefined)
    setExecutionPriceModalVisible(false)
  }

  const handleSuccessExecutedPrice = () => {
    setSuccessModalVisible(false)
    navigation.goBack()
  }

  const handlePriceCancelation = () => {
    setPriceConfirmationModalVisible(false)
    setExecutionPriceModalVisible(true)
  }

  const handlePriceConfirmation = () => {
    if (selectedPricingProgramInfo) {
      setPriceConfirmationModalVisible(false)
      dispatch(executePricingSignals(selectedPricingProgramInfo))
      handleExecuteSignalModalClose()
      setSuccessModalVisible(true)
    }
  }

  if (isFetching && !filteredBrokerList.length && !filteredFarmerList.length) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Content>
      <SubContent>
        {[UserRole.farmer, UserRole.broker_farmer].includes(
          userRole as UserRole
        ) && filteredFarmerList.length
          ? (
            <ContainerFlatList>
              {userRole === UserRole.broker_farmer
                ? (
                  <SectionTitle>
                    {t('PricingPrograms.broker_farmer.farmerSignals')}
                  </SectionTitle>
                  )
                : null}
              <FlatList
                key={`flatListWith-${columns}-Columns`}
                numColumns={columns}
                testID='ps-farmer-pending-flatList'
                data={filteredFarmerList}
                renderItem={renderCard}
                keyExtractor={(data, index) => data.id + index}
                style={{ width: '100%' }}
                contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                ListEmptyComponent={(
                  <EmptyCard
                    text={t('Empty.pricingSignals.pending')}
                    entity={Entity.pricingSignal}
                  />
                )}
              />
            </ContainerFlatList>
            )
          : null}
        {[UserRole.broker, UserRole.broker_farmer].includes(
          userRole as UserRole
        ) && filteredBrokerList.length
          ? (
            <ContainerFlatList>
              {userRole === UserRole.broker_farmer
                ? (
                  <SectionTitle>
                    {t('PricingPrograms.broker_farmer.brokerSignals')}
                  </SectionTitle>
                  )
                : null}
              <FlatList
                key={`flatListWith-${columns}-Columns`}
                numColumns={columns}
                testID='ps-broker-pending-flatList'
                data={filteredBrokerList}
                renderItem={renderCardByPricingProgram}
                keyExtractor={(data, index) => data.id + index}
                style={{ width: '100%' }}
                contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                ListEmptyComponent={(
                  <EmptyCard
                    text={t('Empty.pricingSignals.pending')}
                    entity={Entity.pricingSignal}
                  />
              )}
              />
            </ContainerFlatList>
            )
          : null}

        {
          (executionPriceModalVisible && modalData) && (
            <ExecuteSignalModal
              testId='ps-executePrice-modal'
              modalData={modalData}
              visible={executionPriceModalVisible}
              onPressConfirm={handleConfirmExecutedPrice}
              onPressCancel={handleExecuteSignalModalClose}
            />
          )
        }
        {
          priceConfirmationModalVisible && (
            <Modal
              title={t('Components.modal.priceConfirmation.title')}
              variant='error'
              visible={priceConfirmationModalVisible}
              buttons={[
                {
                  label: t('Components.button.cancel').toString(),
                  onPress: handlePriceCancelation,
                  testID: 'ps-priceCancel-button',
                  variant: 'secondary'
                },
                {
                  label: t('Components.button.confirm').toString(),
                  onPress: handlePriceConfirmation,
                  testID: 'ps-priceConfirm-button',
                  variant: 'primary'
                }
              ]}
            />
          )
        }
        {
          successModalVisible && (
            <Modal
              title={t('Components.modal.successExPrice.title')}
              variant='success'
              visible={successModalVisible}
              buttons={[
                {
                  label: t('Components.button.ok').toString(),
                  onPress: handleSuccessExecutedPrice,
                  testID: 'ps-success-button',
                  variant: 'primary'
                }
              ]}
            />
          )
        }
      </SubContent>
    </Content>
  )
}

export default PricingSignalsPendingTab
