/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Svg, Circle, Text as SVGText, Path, Marker, Defs } from 'react-native-svg'
import { useTranslation } from 'react-i18next'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import {
  ChartTextContent,
  AlgorithmTypeContent,
  ChartFooterText,
  ChartContent,
  CircleContent,
  BodyBlack,
  getPPBPMarkerColor,
  getPPEPMarkerColor,
  AlgoText
} from './styled'

import ChartCircleIcon from '../../../../icons/chart-circle-icon'

interface CircleProps {
  currency: string;
  minPrice: number;
  maxPrice: number;
  executionPrice: number;
  benchmarkPrice: number;
  algorithmTypeText: string;
  state: PricingProgramStatus;
  lastMarketData: number;
}

const PerformanceChart = ({
  currency,
  minPrice,
  maxPrice,
  executionPrice,
  benchmarkPrice,
  algorithmTypeText,
  state,
  lastMarketData
} :CircleProps) => {
  let maxValue = maxPrice
  let minValue = minPrice

  if (executionPrice < minPrice && executionPrice < benchmarkPrice) {
    minValue = executionPrice
  }

  if (benchmarkPrice < minPrice && benchmarkPrice < executionPrice) {
    minValue = benchmarkPrice
  }

  if (executionPrice > maxPrice && executionPrice > benchmarkPrice) {
    maxValue = executionPrice
  }

  if (benchmarkPrice > maxPrice && benchmarkPrice > executionPrice) {
    maxValue = benchmarkPrice
  }

  const { t } = useTranslation()
  const strokeWidth = 8
  const algoType = algorithmTypeText.toUpperCase()
  const progress = ((executionPrice - minValue) / (maxValue - minValue)) * 50 | 0
  const benchmarkValue = ((benchmarkPrice - minValue) / (maxValue - minValue)) * 50 | 0
  const size = 202
  const algorithmText = t('Components.card.algorithmText')
  const min = 'Mín'
  const max = 'Máx'
  const radius = (size - strokeWidth) / 2
  const circum = radius * 2 * Math.PI
  const svgProgress = 100 - progress
  const xDimension = (size / 1.99)
  const yDimension = (size / 1.6)

  const BPColor = getPPBPMarkerColor(state as PricingProgramStatus)

  const EPColor = getPPEPMarkerColor(state as PricingProgramStatus)

  const angle = () => {
    if (progress !== 0) {
      return (90 / 25) * (progress - 25)
    } else {
      return -90
    }
  }

  const benchmarkAngle = () => {
    if (benchmarkValue !== 0) {
      return (90 / 25) * (benchmarkValue - 25)
    } else {
      return -90
    }
  }

  return (
    <ChartContent>
      <CircleContent>
        <Svg
          style={{
            height: 125,
            width: 206,
            overflow: 'visible',
            marginTop: -5
          }}
        >
          {/* Background Circle */}
          <Circle
            stroke='#ededed'
            fill="none"
            cx={size / 2}
            cy={yDimension}
            r={radius}
            strokeDasharray={`${circum} ${circum}`}
            strokeDashoffset={radius * Math.PI * 2 * (0.5)}
            transform={`rotate(-180, ${(size / 2) || 0}, ${yDimension || 0})`}
            strokeLinecap="round"
            {...{ strokeWidth }}
          />
          {/* Progress Circle */}
          {
            executionPrice === 0 || executionPrice === null
              ? null
              : (
                <Circle
                  stroke={EPColor}
                  fill="none"
                  cx={size / 2}
                  cy={yDimension}
                  r={radius}
                  strokeDasharray={`${circum} ${circum}`}
                  strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
                  strokeLinecap="round"
                  transform={`rotate(-180, ${(size / 2) || 0}, ${yDimension || 0})`}
                  {...{ strokeWidth }}
                />
                )
}
          {/* Circle Marker for Executed Price */}
          {
            executionPrice
              ? (
                <Defs>
                  <Marker
                    id="EP"
                    viewBox="-120 -120 220 220"
                    refY={11}
                    refX={-35}
                    markerWidth="100"
                    markerHeight="100"
                    orient='auto'
                  >
                    <ChartCircleIcon
                      color={EPColor}
                      text='EP'
                      shadowed
                      height={20}
                      width={20}
                      cx={15}
                      cy={15}
                      r={23}
                      r2={30}
                      fontS={23}
                      tx={1}
                      ty={24}
                      angle={90 - angle()}
                      data={executionPrice}
                      dy={-20}
                      dx={-8}
                    />
                  </Marker>
                </Defs>
                )
              : null
          }
          {/* Circle Marker for Benchmark Price */}
          {
            benchmarkPrice
              ? (
                <Defs>
                  <Marker
                    id="BP"
                    viewBox="-120 -120 220 220"
                    refY={11}
                    refX={-35}
                    markerWidth="100"
                    markerHeight="100"
                    orient="auto"
                  >
                    <ChartCircleIcon
                      color={BPColor}
                      text='BP'
                      shadowed
                      height={20}
                      width={20}
                      cx={15}
                      cy={15}
                      r={23}
                      r2={30}
                      fontS={23}
                      tx={1}
                      ty={24}
                      angle={90 - benchmarkAngle()}
                      data={benchmarkPrice}
                      dy={-20}
                      dx={-8}
                    />
                  </Marker>
                </Defs>
                )
              : null
          }
          {/* Reference Price Line */}
          <Path
            markerEnd='url(#EP)'
            transform-origin={`${xDimension} ${yDimension}`}
            transform={`rotate(${angle() || 0}, 0, 0)`}
            fill='none'
            d={`M ${xDimension} ${yDimension} V ${(radius / 1.95)}`}
          />
          {/* Benchmark Line */}
          <Path
            markerEnd='url(#BP)'
            transform-origin={`${xDimension} ${yDimension}`}
            transform={`rotate(${benchmarkAngle() || 0}, 0, 0)`}
            fill='none'
            d={`M ${xDimension} ${yDimension} V ${(radius / 1.95)}`}
          />
          {/* Text */}
          <SVGText
            fontFamily='Lato'
            fontSize={10}
            x={xDimension}
            y={size / 2.8}
            textAnchor="middle"
            fill={EPColor}
          >
            {currency}
          </SVGText>
          <SVGText
            fontFamily='Lato'
            fontWeight={900}
            fontSize={36}
            x={xDimension}
            y={size / 1.8}
            textAnchor="middle"
            fill={EPColor}
          >
            {lastMarketData}
          </SVGText>
        </Svg>
      </CircleContent>
      <ChartTextContent>
        <ChartFooterText style={{
          borderRightWidth: 1,
          borderColor: '#D6D8DB',
          paddingRight: '21px'
        }}
        >
          <BodyBlack
            textColor={EPColor}
          >
            {min}
          </BodyBlack>
        </ChartFooterText>
        <AlgorithmTypeContent>
          <ChartFooterText>
            <BodyBlack
              textColor={EPColor}
            >
              {algoType}
            </BodyBlack>
          </ChartFooterText>
          <AlgoText
            textColor={EPColor}
          >
            {algorithmText}
          </AlgoText>
        </AlgorithmTypeContent>
        <ChartFooterText style={{
          borderLeftWidth: 1,
          borderColor: '#D6D8DB',
          paddingLeft: '21px'
        }}
        >
          <BodyBlack
            textColor={EPColor}
          >
            {max}
          </BodyBlack>
        </ChartFooterText>
      </ChartTextContent>
    </ChartContent>
  )
}

export default PerformanceChart
