import React from 'react'

import useUserRole from 'hooks/use-user-role'

import PricingProgramsFarmerList from './farmer'
import PricingProgramsBrokerList from './broker'
import PricingProgramsBrokerFarmerList from './broker-farmer'

import { RouteType } from '../tabs'

type PricingProgramsActiveTabProps = {
  route: RouteType;
}

const PricingProgramsExecutedTab = ({ route }: PricingProgramsActiveTabProps) => {
  const { isBroker, isFarmer, isBrokerFarmer } = useUserRole()

  if (isBroker) {
    return <PricingProgramsBrokerList route={route} />
  } else if (isFarmer) {
    return <PricingProgramsFarmerList route={route} />
  } else if (isBrokerFarmer) {
    return <PricingProgramsBrokerFarmerList route={route} />
  }
  return null
}

export default PricingProgramsExecutedTab
