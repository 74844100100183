import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { saveUserInformation, savePhone } from '@hedgit/lib/store/modules/auth'

import useSignUp from '@hedgit/lib/hooks/firebase/useSignUp'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { AuthUser } from '@hedgit/lib/interfaces/auth-user'

import { FarmerSignUp } from '@hedgit/lib/components/auth/sign-up/farmer'

import auth from 'utils/firebase'
import { verifyPhoneNumber } from 'utils/api'

type Props = NativeStackScreenProps<RootStackParamList, 'SignUpFarmer'>;

const SignUpFarmer = ({ navigation }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [error, setError] = useState('')
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const onVerificationCodeSent = (data: AuthUser) => {
    setIsFetching(false)
    dispatch(savePhone(data.phone))
    dispatch(saveUserInformation(data))
    navigation.navigate('SignUpVerification')
  }

  const {
    signUp,
    clearRecaptcha
  } = useSignUp({ auth, onVerificationCodeSent })

  useEffect(() => {
    return () => {
      clearRecaptcha()
    }
  }, [clearRecaptcha])

  const handleSubmit = async (data: AuthUser) => {
    try {
      setIsFetching(true)
      const userExists = await verifyPhoneNumber(data.phone)

      if (!userExists) {
        signUp(data)
      } else {
        setError(t('SignUpFarmer.handleSubmitError'))
        setIsFetching(false)
      }
    } catch (e) {
      setIsFetching(false)
      setError(t('SignUpFarmer.handleSubmitError'))
    }
  }

  return (
    <View
      style={{
        height: '100%',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: 16
      }}
    >
      <View nativeID="recaptcha-verifier" />
      <FarmerSignUp
        isFetching={isFetching}
        onSubmit={handleSubmit}
        error={error}
        clearError={() =>
          setError('')}
      />
    </View>
  )
}

export default SignUpFarmer
