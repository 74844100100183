import { ActionType } from 'typesafe-actions'

import { Destination } from '@hedgit/lib/interfaces/destination'

import * as actions from './actions'

export enum DestinationsActionConsts {
  GET_DESTINATIONS_PENDING='GET_DESTINATIONS_PENDING',
  GET_DESTINATIONS_FULFILLED='GET_DESTINATIONS_FULFILLED',
  GET_DESTINATIONS_FAILED='GET_DESTINATIONS_FAILED',
}

export type DestinationsActionTypes = ActionType<typeof actions>

export interface DestinationsState {
  readonly list: Destination[];
  readonly isFetching: boolean;
  readonly error: string;
}
