import React from 'react'
import { t } from 'i18next'

import {
  Card,
  CardContent,
  AlgorithmName,
  AlgorithmDetailsText,
  SeeBox,
  SeeText,
  DetailsContent,
  DetailsText
} from './styled'

import { GetMonthName } from '../../../utils/format'
import { getTranslation } from '../../../translations/getTranslation'
import { PricingProgram } from '../../../interfaces/pricing-program'
import AlgorithmPerformanceIcon from '../../icons/algorithm-performance'

interface AlgorithmCardProps {
  pricingProgram: PricingProgram;
}

export const AlgorithmCard = ({
  pricingProgram
}: AlgorithmCardProps) => {
  const month = GetMonthName(pricingProgram.product.month)
  return (
    <Card testID={`al-${pricingProgram.algorithm.id}-card`}>
      <CardContent testID={`al-${pricingProgram.algorithm.id}-cardContent`}>
        <DetailsContent>
          <AlgorithmPerformanceIcon color='#adb1b8' />
          <DetailsText>
            <AlgorithmName>
              {getTranslation(pricingProgram.algorithm.name)}
            </AlgorithmName>
            <AlgorithmDetailsText>
              {month}
              {' '}
              {pricingProgram.product.year}
              {' - '}
              {getTranslation(pricingProgram.product.crop.name)}
              {', '}
              {pricingProgram.product.destination.name}
            </AlgorithmDetailsText>
          </DetailsText>
        </DetailsContent>
        <SeeBox>
          <SeeText>
            {t('Components.button.see')}
          </SeeText>
        </SeeBox>
      </CardContent>
    </Card>
  )
}
