/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Svg, Circle, Text as SVGText } from 'react-native-svg'

import {
  ChartTextContent,
  ChartContent,
  CircleContent,
  LegendText
} from './styled'

interface CircleProps {
  averagePercentile: number;
  legend: string;
}

const AveragePerformanceAlgorithmChart = ({
  averagePercentile,
  legend
} :CircleProps) => {
  const strokeWidth = 8
  const progress = (averagePercentile / 100) * 50 | 0
  const size = 202
  const subTitle = 'CHANCES'
  const radius = (size - strokeWidth) / 2
  const circum = radius * 2 * Math.PI
  const svgProgress = 100 - progress

  return (
    <ChartContent>
      <CircleContent>
        <Svg
          style={{
            height: 125,
            width: 206,
            marginTop: -10
          }}
        >
          {/* Background Circle */}
          <Circle
            stroke='#ededed'
            fill="none"
            strokeLinecap='round'
            cx={size / 2}
            cy={size / 1.8}
            r={radius}
            strokeDasharray={`${circum} ${circum}`}
            strokeDashoffset={radius * Math.PI}
            transform={`rotate(-180, ${(size / 2) || 0}, ${(size / 1.8) || 0})`}
            {...{ strokeWidth }}
          />
          {/* Progress Circle */}
          <Circle
            stroke='#313C4D'
            fill="none"
            strokeLinecap='round'
            cx={size / 2}
            cy={size / 1.8}
            r={radius}
            strokeDasharray={`${circum} ${circum}`}
            strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
            transform={`rotate(-180, ${(size / 2) || 0}, ${(size / 1.8) || 0})`}
            {...{ strokeWidth }}
          />
          {/* Text */}
          <SVGText
            fontFamily='Lato'
            fontSize={14}
            x={size / 1.95}
            y={size / 1.88}
            textAnchor="middle"
            fill='#313C4D'
          >
            {subTitle}
          </SVGText>
          <SVGText
            fontFamily='Lato'
            fontSize={36}
            fontWeight='bold'
            x={size / 1.89}
            y={size / 2.38}
            textAnchor='middle'
            fill='#313C4D'
          >
            {averagePercentile}
            %
          </SVGText>
        </Svg>
      </CircleContent>
      <ChartTextContent>
        <LegendText>
          {legend}
        </LegendText>
      </ChartTextContent>
    </ChartContent>
  )
}

export default AveragePerformanceAlgorithmChart
