import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import Body from '@hedgit/lib/components/typography/body'
import Date from '@hedgit/lib/components/typography/date-text'

type TextProps = {
  textColor: string;
}

export const ChartContent = styled.View`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  height: 172px;
`

export const CircleContent = styled.View`
  align-items: center;
  width: 200px;
  height: 125px;
`

export const ChartTextContent = styled.View`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 220px;
`

export const ChartFooterText = styled.Text`
`

export const AlgorithmTypeContent = styled.View`
  align-items: center;
`

export const BodyBlack = styled(Body)<TextProps>`
  color: ${props => props.textColor} ;
  font-weight: bold;
`

export const AlgoText = styled(Date)<TextProps>`
  color: ${props => props.textColor} ;
`

export const getPPEPMarkerColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.dark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getPPBPMarkerColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.green

  if (status === PricingProgramStatus.executed) return theme.colors.green

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.green
}
