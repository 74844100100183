import React from 'react'
import { Line as LineSvg } from 'react-native-svg'

const OFFSET_Y = 10

interface Props {
  pct: number;
  color: string;
}

const Line = ({ pct, color }: Props) => {
  return (
    <LineSvg
      x1={0}
      y1={OFFSET_Y}
      x2={`${pct}%`}
      y2={OFFSET_Y}
      strokeLinecap="round"
      stroke={color}
      strokeWidth="3"
    />
  )
}

export default Line
