import styled from 'styled-components/native'

export const Container = styled.View`
  position: relative;
  z-index: 1;
`

export const EndAdornmentContainer = styled.View`
  z-index: -1;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  height: 30px;
  width: 30px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
