import React from 'react'
import { ActivityIndicator } from 'react-native'
import { theme } from '@hedgit/lib/theme'

import { Container } from './styled'

const { colors } = theme

const LoadingScreen = () => (
  <Container>
    <ActivityIndicator color={colors.primary} style={{ flex: 1 }} />
  </Container>
)

export default LoadingScreen
