import { ActionType } from 'typesafe-actions'

import { Tutorial } from '@hedgit/lib/interfaces/tutorial'

import * as actions from './actions'
import { getTutorials } from './thunks'

export enum TutorialsActionConsts {
  GET_TUTORIALS_PENDING='GET_TUTORIALS_PENDING',
  GET_TUTORIALS_FULFILLED='GET_TUTORIALS_FULFILLED',
  GET_TUTORIALS_FAILED='GET_TUTORIALS_FAILED',
}

export type TutorialsActionTypes = ActionType<typeof actions>
export type TutorialsThunksTypes = typeof getTutorials

export interface TutorialsState {
  readonly list: Tutorial[];
  readonly isFetching: boolean;
  readonly error: string;
}
