import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { View } from 'react-native'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from 'native-base'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { HeaderLeftButton } from '@hedgit/web/src/components/header-left-button'

import { UpdateUserSchema } from '@hedgit/lib/utils/validations/user'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { User } from '@hedgit/lib/interfaces/user'

import Title from '@hedgit/lib/components/typography/title'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import ErrorIcon from '@hedgit/lib/components/icons/error'
import H2 from '@hedgit/lib/components/typography/h2'

import { useFormFields } from './hooks'
import {
  Container,
  InputsContainer,
  Requirements,
  ButtonContainer,
  Error,
  FieldContainer,
  UserIcon,
  StyledBackground,
  Header,
  BackButtonContainer,
  UserText
} from './styled'

import PencilIcon from '../icons/pencil'

export interface SettingsProps {
  error?: string;
  isFetching?: boolean;
  onSubmit: (data: User) => void;
  currentUser: User;
  role: UserRole;
}

const { colors } = theme

const Settings = ({
  onSubmit,
  error,
  isFetching,
  currentUser,
  role
}: SettingsProps) => {
  const { t } = useTranslation()

  const isBroker = role === UserRole.broker
  const isBrokerFarmer = role === UserRole.broker_farmer

  const { userFields, billingInformationFields } = useFormFields()

  const {
    control,
    reset,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(UpdateUserSchema)
  })

  const handleSubmit = (data: User) => {
    onSubmit(data)
  }

  useEffect(() => {
    if (currentUser) {
      const user = userFields.reduce((accum, field) => {
        accum[field.name] = currentUser[field.name]
        return accum
      }, {})

      const billingInformation = billingInformationFields.reduce((accum, field) => {
        const fieldName = field.name.split('.')[1]
        accum[fieldName] = currentUser.billingInformation?.[fieldName]
        return accum
      }, {})

      reset({ ...user, billingInformation })
    }
  }, [currentUser, reset, role, billingInformationFields, userFields])

  const initials = currentUser.firstName[0] + (currentUser.lastName[0] || currentUser.firstName[1])

  return (
    <View>
      <Header>
        <BackButtonContainer>
          <HeaderLeftButton />
        </BackButtonContainer>
        <H2>{t('Settings.title')}</H2>
      </Header>
      <StyledBackground />
      <Container>
        <UserIcon><UserText>{initials}</UserText></UserIcon>
        <InputsContainer>
          {userFields.map((field, index) => (
            <FieldContainer
              key={field.name}
              style={index === userFields.length - 1 && { marginBottom: 0 }}
            >
              <FormControl isInvalid={field.name in errors}>
                <Controller
                  name={field.name}
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <SharedInput
                      testID={field.testID}
                      withLabel
                      label={field.label}
                      onChange={onChange}
                      onFocus={() => clearErrors(field.name)}
                      onBlur={onBlur}
                      value={value}
                      keyboardType={field.keyboardType || 'default'}
                      placeholder={field.placeholder}
                      endAdornment={!field.disabled && <PencilIcon width={24} height={24} color='#adb1b8' />}
                      disabled={field.disabled}
                    />
                  )}
                />
                <FormControl.ErrorMessage
                  testID="su-farmer-firstName-error-message"
                  leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                  _text={{
                    style: {
                      color: colors.red,
                      fontSize: 12,
                      fontFamily: 'Lato'
                    }
                  }}
                >
                  {errors[field.name]?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            </FieldContainer>
          ))}
          <Requirements>{t('Settings.phoneDisclaimer')}</Requirements>
          {(isBroker || isBrokerFarmer) && (
            <>
              <Title>
                {t('Settings.billingInformationLabel')}
              </Title>
              <Requirements style={{ marginBottom: '16px' }}>{t('Settings.billingInfoDisclaimer')}</Requirements>
              {billingInformationFields.map((field, index) => (
                <FieldContainer
                  key={field.name}
                  style={
                    index === billingInformationFields.length - 1 && {
                      marginBottom: 0
                    }
                  }
                >
                  <FormControl isInvalid={field.name in errors}>
                    <Controller
                      name={field.name}
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SharedInput
                          testID={field.testID}
                          withLabel
                          label={field.label}
                          value={value}
                          onChange={onChange}
                          onFocus={() => clearErrors(field.name)}
                          onBlur={onBlur}
                          keyboardType={field.keyboardType || 'default'}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                        />
                      )}
                    />
                    <FormControl.ErrorMessage
                      testID={field.testID + '-error'}
                      leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                      _text={{
                        style: {
                          color: colors.red,
                          fontSize: 12,
                          fontFamily: 'Lato'
                        }
                      }}
                    >
                      {errors[field.name]?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </FieldContainer>
              ))}
            </>
          )}
          <Error testID="set-error-messages">{error}</Error>
        </InputsContainer>
        <ButtonContainer>
          <SharedButton
            testID="set-submit-button"
            variant="primary"
            onPress={handleSubmitForm(handleSubmit)}
            loading={isFetching}
          >
            {t('Settings.submitButton')}
          </SharedButton>
        </ButtonContainer>
      </Container>
      <View style={{ height: 40 }} />
    </View>
  )
}

export default Settings
