import { action } from 'typesafe-actions'

import { BrokerSubscription, FarmerSubscription, Subscription } from '@hedgit/lib/interfaces/subscription'

import { SubscriptionsActionConsts } from './types'

export const getSubscriptionsPending = () =>
  action(SubscriptionsActionConsts.GET_SUBSCRIPTIONS_PENDING)

export const getSubscriptionsFulfilled = (subscriptions: FarmerSubscription[]) =>
  action(SubscriptionsActionConsts.GET_SUBSCRIPTIONS_FULFILLED, subscriptions)

export const getBrokerSubscriptionsFulfilled = (subscriptions: BrokerSubscription[]) =>
  action(SubscriptionsActionConsts.GET_BROKER_SUBSCRIPTIONS_FULFILLED, subscriptions)

interface BrokerFarmerSubscriptions {
  subscriptions?: BrokerSubscription[];
  subscribed?: FarmerSubscription[];
}

export const getBrokerFarmerSubscriptionsFulfilled = (data: BrokerFarmerSubscriptions) =>
  action(SubscriptionsActionConsts.GET_BROKER_FARMER_SUBSCRIPTIONS_FULFILLED, data)

export const getSubscriptionsFailed = (error: string) =>
  action(SubscriptionsActionConsts.GET_SUBSCRIPTIONS_FAILED, error)

export const createSubscriptionPending = () =>
  action(SubscriptionsActionConsts.CREATE_SUBSCRIPTION_PENDING)

export const createSubscriptionFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.CREATE_SUBSCRIPTION_FULFILLED, subscription)

export const createSubscriptionFailed = (error: string) =>
  action(SubscriptionsActionConsts.CREATE_SUBSCRIPTION_FAILED, error)

export const cancelSubscriptionPending = () =>
  action(SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_PENDING)

export const cancelSubscriptionFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_FULFILLED, subscription)

export const cancelSubscriptionFailed = (error: string) =>
  action(SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_FAILED, error)

export const cancelSubscriptionPendingFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.CANCEL_SUBSCRIPTION_PENDING_FULFILLED, subscription)

export const acceptedSubscriptionPending = () =>
  action(SubscriptionsActionConsts.ACCEPTED_SUBSCRIPTION_PENDING)

export const acceptedSubscriptionFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.ACCEPTED_SUBSCRIPTION_FULFILLED, subscription)

export const acceptedSubscriptionFailed = (error: string) =>
  action(SubscriptionsActionConsts.ACCEPTED_SUBSCRIPTION_FAILED, error)

export const rejectSubscriptionPending = () =>
  action(SubscriptionsActionConsts.REJECT_SUBSCRIPTION_PENDING)

export const rejectSubscriptionFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.REJECT_SUBSCRIPTION_FULFILLED, subscription)

export const rejectSubscriptionFailed = (error: string) =>
  action(SubscriptionsActionConsts.REJECT_SUBSCRIPTION_FAILED, error)

export const rejectCancellationPending = () =>
  action(SubscriptionsActionConsts.REJECT_CANCELLATION_PENDING)

export const rejectCancellationFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.REJECT_CANCELLATION_FULFILLED, subscription)

export const rejectCancellationFailed = (error: string) =>
  action(SubscriptionsActionConsts.REJECT_CANCELLATION_FAILED, error)

export const confirmCancelSubscriptionFulfilled = (subscription: Subscription) =>
  action(SubscriptionsActionConsts.CONFIRM_CANCEL_SUBSCRIPTION_FULFILLED, subscription)

export const confirmCancelSubscriptionFailed = (error: string) =>
  action(SubscriptionsActionConsts.CONFIRM_CANCEL_SUBSCRIPTION_FAILED, error)

export const confirmCancelSubscriptionPending = () =>
  action(SubscriptionsActionConsts.CONFIRM_CANCEL_SUBSCRIPTION_PENDING)
