import { useMemo } from 'react'

let lastId = 0

const useId = (prefix: string) => {
  const id = useMemo(() => `${prefix}-${++lastId}`, [prefix])
  return id
}

export default useId
