import React, { useEffect } from 'react'
import { View, SafeAreaView } from 'react-native'
import { useWindowSize } from 'react-use'

import { getUserData } from '@hedgit/lib/store/modules/auth/thunks'

import useSafeArea from '@hedgit/lib/hooks/use-safe-area'

import { useSelector, RootState, useDispatch } from 'store'

import useAuthToken from 'hooks/use-auth-token'

import { AppNavigator } from './app'
import { AuthNavigator } from './auth'

const Navigator = () => {
  const dispatch = useDispatch()
  const token = useSelector((store: RootState) => store.auth.token)
  const isSignedIn = token

  const { height } = useWindowSize()
  const { bottom } = useSafeArea()

  useAuthToken()

  useEffect(() => {
    if (token) {
      dispatch(getUserData())
    }
  }, [token, dispatch])

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = window.visualViewport.height + 'px'
    }
  }, [height])

  return (
    <View
      style={{ height: height + bottom, overflow: 'hidden', display: 'flex', maxHeight: '100vh' }}
    >
      <SafeAreaView style={{ flex: 1 }}>
        {isSignedIn ? <AppNavigator /> : <AuthNavigator />}
      </SafeAreaView>
    </View>
  )
}

export default Navigator
