import { ActionType } from 'typesafe-actions'

import * as actions from './actions'

export enum UIActionConsts {
  TOGGLE_SIDEBAR='TOGGLE_SIDEBAR',
}

export type UIActionTypes = ActionType<typeof actions>

export interface UIState {
  readonly sidebarOpen: boolean;
}
