import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get } from '@hedgit/lib/utils/axios'

import { PricingProgram } from '@hedgit/lib/interfaces/pricing-program'

import {
  getPricingProgramPending,
  getPricingProgramFulfilled,
  getPricingProgramFailed,
  getPricingProgramByIdFailed,
  getPricingProgramByIdFulfilled,
  getPricingProgramByIdPending
} from './actions'
import { PricingProgramsActionTypes, PricingProgramDetails } from './types'

interface Response {
  pricingPrograms: PricingProgram[];
}

interface ResponseById {
  pricingProgram: PricingProgramDetails;
}

export const getPricingPrograms = (): ThunkAction<void, unknown, unknown, PricingProgramsActionTypes> => {
  return async (dispatch) => {
    dispatch(getPricingProgramPending())
    try {
      const response = await get<APIResponse<Response>>('/pricing-programs')
      dispatch(getPricingProgramFulfilled(response.data.pricingPrograms))
    } catch (error) {
      dispatch(getPricingProgramFailed(error as string))
    }
  }
}

export const getPricingProgramsById = (id: string): ThunkAction<void, unknown, unknown, PricingProgramsActionTypes> => {
  return async (dispatch) => {
    dispatch(getPricingProgramByIdPending())
    try {
      const response = await get<APIResponse<ResponseById>>(`/pricing-programs/${id}`)
      dispatch(getPricingProgramByIdFulfilled(response.data.pricingProgram))
    } catch (error) {
      dispatch(getPricingProgramByIdFailed(error as string))
    }
  }
}
