import { action } from 'typesafe-actions'

import { Product } from '@hedgit/lib/interfaces/product'

import { ProductsActionConsts } from './types'

export const getProductsPending = () =>
  action(ProductsActionConsts.GET_PRODUCTS_PENDING)

export const getProductsFulfilled = (products: Product[]) =>
  action(ProductsActionConsts.GET_PRODUCTS_FULFILLED, products)

export const getProductsFailed = (error: string) =>
  action(ProductsActionConsts.GET_PRODUCTS_FAILED, error)
