import React from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import PricingProgramFarmerDetails from './farmer'
import PricingProgramBrokerDetails from './broker'

type Props = NativeStackScreenProps<RootStackParamList, 'PricingProgramDetails'>;

const PricingProgramDetails = ({ route }: Props) => {
  const { id, state, role } = route.params

  if (role === UserRole.broker) {
    return <PricingProgramBrokerDetails id={id} state={state || 'active'} />
  } else {
    return <PricingProgramFarmerDetails id={id} state={state || 'active'} />
  }
}

export default PricingProgramDetails
