import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get, patch, post } from '@hedgit/lib/utils/axios'

import { MarketData } from '@hedgit/lib/interfaces/market-data'

import {
  getMarketDataPending,
  getMarketDataFulfilled,
  getMarketDataFailed,
  getMarketDataByProductPending,
  getMarketDataByProductFulfilled,
  getMarketDataByProductFailed,
  createMarketDataPending,
  createMarketDataFulfilled,
  createMarketDataFailed,
  editMarketDataPending,
  editMarketDataFulfilled,
  editMarketDataFailed,
  getFullMarketDataByProductFulfilled,
  getMarketDataByPricingProgramPending,
  getMarketDataByPricingProgramFulfilled,
  getMarketDataByPricingProgramFailed
} from './actions'
import { MarketDataActionTypes, MarketDataSummary } from './types'

interface Response {
  marketData: MarketData[] | MarketDataSummary[];
}

export const getMarketData = (): ThunkAction<void, unknown, unknown, MarketDataActionTypes> => {
  return async dispatch => {
    dispatch(getMarketDataPending())
    try {
      const response = await get<APIResponse<Response>>('/market-data')
      dispatch(getMarketDataFulfilled(response.data.marketData as MarketData[]))
    } catch (error) {
      dispatch(getMarketDataFailed(error))
    }
  }
}

interface Options {
  summary: boolean;
  sort: 'asc' | 'desc';
  limit: boolean;
}

// eslint-disable-next-line max-len
export const getMarketDataByProduct = (productId: string, options: Options): ThunkAction<void, unknown, unknown, MarketDataActionTypes> => {
  return async dispatch => {
    dispatch(getMarketDataByProductPending())
    try {
      const query = new URLSearchParams({
        summary: options.summary.toString(),
        limit: options.limit.toString(),
        sort: options.sort
      })

      const response = await get<APIResponse<Response>>(
        `/market-data/by-product/${productId}?${query.toString()}`
      )

      if (options.summary) {
        dispatch(getMarketDataByProductFulfilled({
          productId,
          data: response.data.marketData as MarketDataSummary[]
        }))
      } else {
        dispatch(getFullMarketDataByProductFulfilled({
          productId,
          data: response.data.marketData as MarketData[]
        }))
      }
    } catch (error) {
      dispatch(getMarketDataByProductFailed(error))
    }
  }
}

export const getMarketDataByPricingProgram =
  (pricingProgramId: string): ThunkAction<void, unknown, unknown, MarketDataActionTypes> => {
    return async dispatch => {
      dispatch(getMarketDataByPricingProgramPending())
      try {
        const response = await get<APIResponse<Response>>(
          `/market-data/by-pricing-program/${pricingProgramId}`
        )
        dispatch(getMarketDataByPricingProgramFulfilled({
          pricingProgramId,
          data: response.data.marketData as MarketData[]
        }))
      } catch (error) {
        dispatch(getMarketDataByPricingProgramFailed(error))
      }
    }
  }

interface CreateResponse {
  marketData: MarketData;
}

interface MarketDataRequest {
  id?: string;
  product: string;
  value: number;
}

export const createMarketData = (
  marketData: MarketDataRequest
): ThunkAction<void, unknown, unknown, MarketDataActionTypes> => {
  return async dispatch => {
    dispatch(createMarketDataPending())
    try {
      const response = await post<APIResponse<CreateResponse>>(
        '/market-data',
        marketData
      )
      dispatch(createMarketDataFulfilled(response.data.marketData))
    } catch (error) {
      dispatch(createMarketDataFailed(error))
    }
  }
}

export const editMarketData = (
  marketData: MarketDataRequest
): ThunkAction<void, unknown, unknown, MarketDataActionTypes> => {
  return async dispatch => {
    dispatch(editMarketDataPending())
    try {
      const response = await patch<APIResponse<CreateResponse>>(
        `/market-data/${marketData.id}`,
        marketData
      )
      dispatch(editMarketDataFulfilled(response.data.marketData))
    } catch (error) {
      dispatch(editMarketDataFailed(error))
    }
  }
}
