/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
}

const NotificationPricingProgramIcon = ({ color }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'local_offer_black_24dp' + id} width="30.719" height="30.719" viewBox="0 0 30.719 30.719">
      <Path id={'Path_878' + id} data-name={'Path 878' + id} d="M0,0H20.479V20.479H0Z" fill="none" />
      <Path id={'Path_879' + id} data-name={'Path 879' + id} d="M26.844,14.262,15.324,2.742A2.544,2.544,0,0,0,13.52,2H4.56A2.567,2.567,0,0,0,2,4.56v8.96a2.554,2.554,0,0,0,.755,1.818l11.52,11.52a2.544,2.544,0,0,0,1.8.742,2.5,2.5,0,0,0,1.8-.755l8.96-8.96a2.5,2.5,0,0,0,.755-1.8A2.585,2.585,0,0,0,26.844,14.262ZM6.48,8.4A1.92,1.92,0,1,1,8.4,6.48,1.917,1.917,0,0,1,6.48,8.4Z" transform="translate(0.56 0.56)" fill={color} />
    </Svg>
  )
}
export default NotificationPricingProgramIcon
