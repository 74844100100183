import styled from 'styled-components/native'

import Body from '../../typography/body'

export const Container = styled.View`
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;

  max-width: 600px;
  align-self: center;
`

export const SubContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  width: 100%;
  height: auto;
  gap: 26px;
`

export const InputWrapper = styled.View`
  height: 100%;
  justify-content: flex-start;
`

export const InputContainer = styled.View`
  width: 100%;
  max-width: 328px;
  height: 72px;
  margin-top: 80px;
  border-radius: 15px;
  background-color: #f3f3f3;
  align-items: center;
`

export const StyledInput = styled.TextInput`
  width: 250px;
  margin: 0px 40px;
  padding-left: 8px;
  font-size: 40px;
  font-family: 'Lato';
  letter-spacing: 17px;
  outline-width: 0;
  caret-color: transparent;
`

export const RequirementsContainer = styled.View`
  align-items: center;
  max-width: 328px;
  width: 100%;
  margin-top: 30px;
`

export const Requirements = styled(Body)`
  color: ${props => props.theme.colors.gray};
`

export const RequirementsBold = styled(Body)`
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
`

export const TextErrorCodeInvalid = styled(Body)`
  color: ${props => props.theme.colors.red};
`

export const ButtonContainer = styled.View`
  width: 100%;
  gap: 20px;
`
