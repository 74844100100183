import styled from 'styled-components/native'

export const Button = styled.Button`
  width: 50vh;
  font-size: 56px;
  font-weight: bold;
  padding: 10px;
`

export const Content = styled.View`
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

export const SubContent = styled.View`
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

export const CardClickArea = styled.TouchableOpacity`
  margin: 0 10px;
  width: 90vw;
  min-width: 350px;
  max-width: 400px;
`
