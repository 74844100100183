import { Reducer } from 'redux'

import { TutorialsActionTypes, TutorialsState, TutorialsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: TutorialsState = {
  list: [],
  isFetching: false,
  error: ''
}

export const tutorialsReducer: Reducer<TutorialsState, TutorialsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): TutorialsState => {
  switch (action.type) {
    case TutorialsActionConsts.GET_TUTORIALS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case TutorialsActionConsts.GET_TUTORIALS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case TutorialsActionConsts.GET_TUTORIALS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
