/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const Calendar = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'event_black_24dp' + id} width={width} height={height} viewBox="0 0 21.899 21.899">
      <Path id={'Path_34660' + id} data-name={'Path 34660' + id} d="M0,0H21.9V21.9H0Z" fill="none" />
      <Path id={'Path_34661' + id} data-name={'Path 34661' + id} d="M15.774,11.037H11.212V15.6h4.562ZM14.862,1V2.825h-7.3V1H5.737V2.825H4.825A1.817,1.817,0,0,0,3.009,4.65L3,17.424a1.824,1.824,0,0,0,1.825,1.825H17.6a1.83,1.83,0,0,0,1.825-1.825V4.65A1.83,1.83,0,0,0,17.6,2.825h-.912V1ZM17.6,17.424H4.825V7.387H17.6Z" transform="translate(-0.263 -0.088)" fill={color} />
    </Svg>
  )
}

export default Calendar
