import * as yup from 'yup'

import {
  phoneNumberRegex,
  phoneNumberCountryCodeRegex,
  nameRegex,
  emailRegex,
  textRegex,
  addressRegex
} from '@hedgit/lib/utils/regex'

import i18n from '../../translations/i18n'
import { UserRole } from '../../enums/user-role'

const firstName = yup
  .string()
  .min(3, i18n.t('ErrorMessages.firstName.min'))
  .matches(nameRegex, i18n.t('ErrorMessages.firstName.matches'))

const lastName = yup
  .string()
  .max(15, i18n.t('ErrorMessages.lastName.max'))

const email = yup
  .string()
  .matches(emailRegex, i18n.t('ErrorMessages.email.matches'))

const phone = yup
  .string()
  .min(10, i18n.t('ErrorMessages.phone.min'))
  .max(10, i18n.t('ErrorMessages.phone.max'))
  .matches(phoneNumberRegex, i18n.t('ErrorMessages.phone.matches'))

const phoneCountryCode = yup
  .string()
  .matches(phoneNumberCountryCodeRegex, i18n.t('ErrorMessages.phone.matches'))

const country = yup
  .string()
  .matches(textRegex, i18n.t('ErrorMessages.country.matches'))

const state = yup
  .string()
  .matches(textRegex, i18n.t('ErrorMessages.state.matches'))

const city = yup
  .string()
  .matches(textRegex, i18n.t('ErrorMessages.city.matches'))

const postalCode = yup
  .string()
  .matches(addressRegex, i18n.t('ErrorMessages.postalCode.matches'))

const address = yup
  .string()
  .matches(addressRegex, i18n.t('ErrorMessages.address.matches'))

const role = yup.string().oneOf([UserRole.broker, UserRole.broker_farmer])

export const BillingInformationSchema = yup.object({
  country: country.required(i18n.t('ErrorMessages.country.required')),
  state,
  city,
  postalCode,
  address
})

export const CreateUserSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  phone: phone.required(i18n.t('ErrorMessages.phone.required'))
})

export const CreateBrokerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName,
  email: email.required(i18n.t('ErrorMessages.email.required')),
  phone: phoneCountryCode.required(i18n.t('ErrorMessages.phone.required')),
  role: role.required('Role is required')
})

export const CreateUserServerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName,
  email: email.required(i18n.t('ErrorMessages.email.required')),
  phone: phoneCountryCode.required(i18n.t('ErrorMessages.phone.required'))
})

export const SignUpFarmerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  phone: phone.required(i18n.t('ErrorMessages.phone.required')),
  termsAndConditions: yup.boolean().isTrue(i18n.t('ErrorMessages.termsAndConditions.isTrue'))
})

export const UpdateUserSchema = yup.object({
  firstName,
  lastName,
  email
})

export const UpdateUserServerSchema = yup.object({
  firstName,
  lastName,
  email,
  phoneCountryCode
})
