/* eslint-disable max-len */
import React from 'react'
import { Svg, G, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const MessageIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg width="27.431" height="24.958" viewBox="0 0 27.431 24.958">
      <G id={'Group_8407' + id} data-name={'Group 8407' + id} transform="translate(-175.285 -299.071)">
        <Path id={'Path_34776' + id} data-name={'Path 34776' + id} d="M22.732,0H3.99A4,4,0,0,0,0,3.991V14.705A4,4,0,0,0,3.99,18.7H6.182v4.871a.692.692,0,0,0,1.147.523l6.2-5.394h9.2a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4m2.614,14.7a2.617,2.617,0,0,1-2.614,2.614H13.273a.691.691,0,0,0-.454.17L7.567,22.047V18a.692.692,0,0,0-.692-.692H3.99a2.608,2.608,0,0,1-2.605-2.605V3.991A2.608,2.608,0,0,1,3.99,1.386H22.732A2.617,2.617,0,0,1,25.346,4Z" transform="translate(175.635 299.42)" fill="#33a3d9" stroke="#33a3d9" strokeWidth="0.7" />
        <Path id={'Path_34777' + id} data-name={'Path 34777' + id} d="M50.754,32.338A2.158,2.158,0,1,0,52.912,34.5a2.161,2.161,0,0,0-2.158-2.158m0,2.932a.773.773,0,1,1,.773-.773.774.774,0,0,1-.773.773" transform="translate(138.255 274.547)" fill="#33a3d9" stroke="#33a3d9" strokeWidth="0.7" />
        <Path id={'Path_34778' + id} data-name={'Path 34778' + id} d="M77.214,32.338A2.158,2.158,0,1,0,79.372,34.5a2.161,2.161,0,0,0-2.158-2.158m0,2.932a.773.773,0,1,1,.773-.773.774.774,0,0,1-.773.773" transform="translate(117.902 274.547)" fill="#33a3d9" stroke="#33a3d9" strokeWidth="0.7" />
        <Path id={'Path_34779' + id} data-name={'Path 34779' + id} d="M24.293,32.338A2.158,2.158,0,1,0,26.451,34.5a2.161,2.161,0,0,0-2.158-2.158m0,2.932a.773.773,0,1,1,.773-.773.774.774,0,0,1-.773.773" transform="translate(158.608 274.547)" fill="#33a3d9" stroke="#33a3d9" strokeWidth="0.7" />
      </G>
    </Svg>
  )
}

export default MessageIcon
