import styled from 'styled-components/native'

import Body from '@hedgit/lib/components/typography/body'
import H3 from '@hedgit/lib/components/typography/h3'
import Button from '@hedgit/lib/components/typography/button-text'

import { SidebarBack } from './components/sidebar-back'

export const Title = styled(H3)`
  color: #ffffff;
  font-size: 20px;
  line-break: normal;
  word-break: break-all;
`

export const SubTitle = styled(Body)`
  text-transform: capitalize;
  color: #ffffff;
`
export const Header = styled.View`
  justify-content: flex-end;
  text-align: left;
  align-items: baseline;
  padding-left: 24px;
  padding-right: 16px;
  padding-bottom: 20px;
  background-color: transparent;
  height: 183px;
  overflow: hidden;
`

export const GradientContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
`

export const HeaderBack = styled(SidebarBack)`
  height: 166%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 25%;
  z-index: -1;
`

export const SidebarMenu = styled(Button)``

export const LinksContainer = styled.View`
  padding-top: 16px;
`

export const LineContent = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 16px 0 16px 22px;
`

export const IconContainer = styled.View`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 34px;
`

export const HeaderImageContent = styled.Image`
  width: 183px;
  height: 1000px;
  margin-right: 40px;
`

export const LogOutBox = styled.View`
  position: absolute;
  bottom: 20px;
  justify-content: left;
  border-top-width: 1.5;
  border-color: #ECEEF6;
  margin-top: 120px;
  padding: 16px 0;
  margin-left: 22px;
`

export const LogoutButton = styled.TouchableOpacity`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 6px;
`

export const BadgeContainer = styled.View`
  position: absolute;
  margin-top: 3px;
  margin-left: 12px;
  width: 15px;
  height: 35px;
  z-index: 1;
`

export const HedgitLogoBox = styled.View`
  width: 50px;
  height: 50px;
  left: -7px;
`
