/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Defs, Rect, ClipPath, G } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const TonsIcon = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'Group_8089' + id} data-name={'Group 8089' + id} width={width} height={height} viewBox="0 0 11.127 12.129">
      <Defs>
        <ClipPath id={'clip-path' + id}>
          <Rect id={'Rectangle_3567' + id} data-name={'Rectangle 3567' + id} width="11.127" height="12.129" fill="#adb1b8" />
        </ClipPath>
      </Defs>
      <G id={'Group_8089-2' + id} data-name={'Group 8089' + id} clipPath="url(#clip-path)">
        <Path id={'Path_34627' + id} data-name={'Path 34627' + id} d="M10.634,12.129H.49a.142.142,0,0,0-.029-.019.6.6,0,0,1-.446-.737c.15-.867.308-1.732.463-2.6q.4-2.219.794-4.438a.636.636,0,0,1,.718-.609H4.029a2.18,2.18,0,0,1-.642-1.654A2.137,2.137,0,0,1,4.162.508a2.183,2.183,0,0,1,2.8,0,2.136,2.136,0,0,1,.749,1.32,2.183,2.183,0,0,1-.615,1.9H7.25c.636,0,1.272,0,1.908,0a.621.621,0,0,1,.688.574c.043.233.083.466.124.7q.373,2.085.747,4.17.2,1.124.4,2.248a.573.573,0,0,1-.223.577,1.454,1.454,0,0,1-.26.133M5.561,1.21a.979.979,0,0,0-.965.967.966.966,0,1,0,1.932,0,.979.979,0,0,0-.967-.966" transform="translate(-0.001 0)" fill={color} />
      </G>
    </Svg>
  )
}

export default TonsIcon
