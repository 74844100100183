import React from 'react'
import { Modal as RNModal, GestureResponderEvent } from 'react-native'

import Title from '@hedgit/lib/components/typography/title'
import Body from '@hedgit/lib/components/typography/body'

import {
  CenteredView,
  ModalView,
  ButtonContainer,
  ButtonsContainer,
  Button
} from './styled'

interface IButton {
  label: string;
  onPress: (event: GestureResponderEvent) => void;
  testID: string;
  variant: 'primary' | 'secondary';
}

export interface ModalProps {
  title?: string;
  buttons?: IButton[];
  visible?: boolean;
  onCloseModal?: () => void;
  body?: string;
}

const HelpModal = ({
  title,
  buttons,
  visible,
  body,
  onCloseModal
}: ModalProps) => {
  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView>
        <ModalView>
          <Title>{title}</Title>
          <Body>{body}</Body>
          <ButtonsContainer>
            {
                buttons.map((button) => (
                  <ButtonContainer key={button.label}>
                    <Button
                      testID={button.testID}
                      variant={button.variant}
                      onPress={button.onPress}
                    >
                      {button.label}
                    </Button>
                  </ButtonContainer>
                )
                )
              }
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default HelpModal
