import { Reducer } from 'redux'

import { DestinationsActionTypes, DestinationsState, DestinationsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: DestinationsState = {
  list: [],
  isFetching: false,
  error: ''
}

export const destinationsReducer: Reducer<DestinationsState, DestinationsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): DestinationsState => {
  switch (action.type) {
    case DestinationsActionConsts.GET_DESTINATIONS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case DestinationsActionConsts.GET_DESTINATIONS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case DestinationsActionConsts.GET_DESTINATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
