/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, G, Rect } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const SubscribersIcon = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg width={width} height={height} viewBox="2 2 19 19">
      <Rect id={'Rectangle_3672' + id} data-name={'Rectangle 3672' + id} width={36} height={24} fill="none" />
      <G id={'Group_8473' + id} data-name={'Group 8473' + id}>
        <Path id={'Path_34794' + id} data-name={'Path 34794' + id} d="M12,12.75a10.611,10.611,0,0,1,4.24.9A2.984,2.984,0,0,1,18,16.38V18H6V16.39a2.963,2.963,0,0,1,1.76-2.73A10.44,10.44,0,0,1,12,12.75ZM4,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,4,13Zm1.13,1.1A6.983,6.983,0,0,0,4,14a6.95,6.95,0,0,0-2.78.58A2.011,2.011,0,0,0,0,16.43V18H4.5V16.39A4.5,4.5,0,0,1,5.13,14.1ZM20,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,20,13Zm4,3.43a2.011,2.011,0,0,0-1.22-1.85,6.8,6.8,0,0,0-3.91-.48,4.5,4.5,0,0,1,.63,2.29V18H24ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z" fill={color} />
      </G>
    </Svg>
  )
}

export default SubscribersIcon
