import { Reducer } from 'redux'

import { UIActionTypes, UIState, UIActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: UIState = {
  sidebarOpen: false
}

export const uiReducer: Reducer<UIState, UIActionTypes | SignOutActionType> = (
  state = initialState,
  action
): UIState => {
  switch (action.type) {
    case UIActionConsts.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
