import { Reducer } from 'redux'

import { NotificationsActionTypes, NotificationsState, NotificationsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: NotificationsState = {
  list: [],
  isFetching: false,
  error: ''
}

export const notificationsReducer: Reducer<NotificationsState, NotificationsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionConsts.GET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case NotificationsActionConsts.GET_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case NotificationsActionConsts.GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case NotificationsActionConsts.MARK_NOTIFICATION_AS_SEEN_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case NotificationsActionConsts.MARK_NOTIFICATION_AS_SEEN_FULFILLED: {
      const index = state.list.findIndex((item) => item.id === action.payload.id)

      return {
        ...state,
        isFetching: false,
        list: [
          ...state.list.slice(0, index),
          {
            ...state.list[index],
            isRead: action.payload.isRead
          },
          ...state.list.slice(index + 1)
        ]
      }
    }
    case NotificationsActionConsts.MARK_NOTIFICATION_AS_SEEN_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
