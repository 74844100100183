/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const UserIcon = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg width={width} height={height} viewBox="0 0 11 11">
      <Path id={'Path_34629' + id} data-name={'Path 34629' + id} d="M9.5,9.5A2.75,2.75,0,1,0,6.75,6.75,2.749,2.749,0,0,0,9.5,9.5Zm0,1.375c-1.836,0-5.5.921-5.5,2.75V15H15V13.625C15,11.8,11.336,10.875,9.5,10.875Z" transform="translate(-4 -4)" fill={color} />
    </Svg>
  )
}

export default UserIcon
