export enum NotificationType {
  signalExecuted = 'signalExecuted',
  signalCreated = 'signalCreated',
  subscriptionPending = 'subscriptionPending',
  subscriptionAccepted = 'subscriptionAccepted',
  subscriptionRejected = 'subscriptionRejected',
  cancelSubscriptionPending = 'cancelSubscriptionPending',
  cancelSubscriptionAccepted = 'cancelSubscriptionAccepted',
  cancelSubscriptionRejected = 'cancelSubscriptionRejected',
}
