import { Reducer } from 'redux'

import { TranslationsActionTypes, TranslationsState, TranslationsActionConsts } from './types'

export const initialState: TranslationsState = {
  language: 'es',
  keys: []
}

export const translationsReducer: Reducer<TranslationsState, TranslationsActionTypes> = (
  state = initialState,
  action
): TranslationsState => {
  switch (action.type) {
    case TranslationsActionConsts.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
        keys: action.payload.keys
      }
    default:
      return state
  }
}
