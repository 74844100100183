import React from 'react'
import { CSSProperties } from 'styled-components'

export type VideoProps = {
  videoSource?: string;
  videoPoster?: string;
  width?: string;
  height?: string;
  autoPlay?: boolean;
  style?: CSSProperties;
}

const Video = ({ ...props }: VideoProps) => {
  return (
    <video
      width={props.width}
      height={props.height}
      style={props.style}
      controls
      autoPlay={props.autoPlay}
      poster={props.videoPoster}
      src={props.videoSource}
    >
      Your browser does not support the video tag
    </video>
  )
}

export default Video
