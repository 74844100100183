import styled from 'styled-components/native'

import { SharedButton } from '@hedgit/lib/components/shared/button'
import Caption from '@hedgit/lib/components/typography/caption'

export const Container = styled.View`
  align-items: center;
  height: 100%;
  padding: 16px;
  gap: 4px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

export const InputContainer = styled.View`
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 135px;
`

export const RequirementText = styled(Caption)`
  color: ${props => props.theme.colors.gray};
  padding-left: 5px;
  margin-bottom: 10px;
`

export const Button = styled(SharedButton)`
  align-items: center;
  max-width: 600px;
`
