import React from 'react'
import { Text } from 'native-base'
import type { StyleProp, TextStyle } from 'react-native'

const H1: React.FC<{
  style?: StyleProp<TextStyle>;
  testID?: string;
}> = ({ children, style, testID }) => (
  <Text
    testID={testID}
    fontSize="2xl"
    fontWeight={800}
    fontFamily="Lato"
    color="dark"
    style={style}
  >
    {children}
  </Text>
)

export default H1
