import styled from 'styled-components/native'

export const Container = styled.View`
  background-color: #FFF;
  padding: 16px;
  height: calc(100vh - 64px);
`

export const SubContainer = styled.View`
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 600px;
`

export const TitleContainer = styled.View`
  gap: 15px;
`
