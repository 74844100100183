/* eslint-disable max-len, no-useless-escape */

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const phoneNumberRegex = /^(?!(\d)\1{9})(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/

export const phoneNumberCountryCodeRegex = /^\+(?:[0-9]●?){6,14}[0-9]$/

export const numberRegex = /^([+\d].*)?\d$/

export const dateRegex =
  // eslint-disable-next-line max-len
  /^(19|20|21)\d{2}[\/]?((((0[13578])|(1[02]))[\/]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\/]?(([0-2][0-9])|(30)))|(02[\/]?[0-2][0-9]))$/

export const nameRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\-']+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]$/

export const validIdRegex = /^[0-9a-fA-F]{24}$/

export const yearRegex = /^(19|20|21)\d{2}$/

export const textRegex = /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/

export const addressRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
