import React from 'react'
import { Svg, Defs, LinearGradient, Stop, Path } from 'react-native-svg'

export const BgGradient = () => {
  return (
    <Svg viewBox="0 0 281 183.5">
      <Defs>
        <LinearGradient id="linear-gradient" x1="1" x2="0" y2="1.051" gradientUnits="objectBoundingBox">
          <Stop offset="0" stopColor="#333c4c" />
          <Stop offset="1" stopColor="#33a3d9" />
        </LinearGradient>
      </Defs>
      <Path id="Drawer_bg" data-name="Drawer bg" d="M0,0H281V183.5H0Z" fill="url(#linear-gradient)" />
    </Svg>
  )
}
