/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const StatusExecuted = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (

    <Svg id={'check_circle_black_24dp' + id} width={width} height={height} viewBox="0 0 22 22">
      <Path id={'Path_34753' + id} data-name={'Path 34753' + id} d="M0,0H22V22H0Z" fill="none" />
      <Path id={'Path_34754' + id} data-name={'Path 34754' + id} d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,16.2A7.2,7.2,0,1,1,18.2,11,7.21,7.21,0,0,1,11,18.2ZM15.131,7.022,9.2,12.953,6.869,10.631,5.6,11.9l3.6,3.6,7.2-7.2Z" fill={color} />
    </Svg>

  )
}

export default StatusExecuted
