import styled from 'styled-components/native'

import H2 from '../../typography/h2'

export const ChartContainer = styled.View`
  align-items: flex-start;
  display: flex;
  margin: 15px;
`

export const ChartHeader = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ChartTitle = styled(H2)`
  align-items: center;
  justify-content: center;
  font-size: 16px;
`

export const Box = styled.View`
  align-items: center;
`

export const PerformanceGraphContent = styled.View`
  align-items: center;
  justify-content: center;
  background-color: #fdfdfd;
  margin: 8px;
  width: 70%;
`
