import { createSelector } from 'reselect'
import { isBefore } from 'date-fns'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { BrokerSubscription } from '@hedgit/lib/interfaces/subscription'

import { RootState } from 'store'

const selectBrokerList = createSelector((state: RootState) => state.subscriptions.brokerList, a => a)

const selectActivePricingProgramsBroker = () => createSelector(
  selectBrokerList,
  (brokerList) => {
    return brokerList.reduce<BrokerSubscription[]>((accum, pp) => {
      const activeSubscriptions = pp.subscribers?.filter(subscriber =>
        [
          SubscriptionStatus.active,
          SubscriptionStatus.pending,
          SubscriptionStatus.pendingCancellation,
          SubscriptionStatus.rejectCancellation
        ].includes(subscriber.status)
      )
      const isNotFinished = isBefore(new Date(), new Date(pp.pricingProgram.endDate))
      if (activeSubscriptions?.length && isNotFinished) {
        accum.push({
          ...pp,
          subscribers: activeSubscriptions
        })
      }

      return accum
    }, [])
  }
)

// const selectActivePricingProgramsBroker = () => createSelector(
//   selectBrokerList,
//   (brokerList) => {
//     return brokerList.filter(pp => {
//       const hasActiveSubscriber = pp.subscribers?.some(subcriber =>
//         [SubscriptionStatus.active, SubscriptionStatus.pending].includes(subcriber.status)
//       )
//       const isNotFinished = isBefore(new Date(), new Date(pp.pricingProgram.product.endDate))

//       return hasActiveSubscriber && isNotFinished
//     })
//   }
// )

export default selectActivePricingProgramsBroker
