import React, { useCallback } from 'react'
import { FlatList, ListRenderItem, ListRenderItemInfo } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import selectCancelledPricingProgramsBroker from '@hedgit/web/src/store/selectors/selectCancelledPricingProgramsBroker'
import { getTranslation } from '@hedgit/lib/translations/getTranslation'
import selectCancelledPricingProgramsFarmer from '@hedgit/web/src/store/selectors/selectCancelledPricingProgramsFarmer'
import { useTranslation } from 'react-i18next'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'
import { UserRole } from '@hedgit/lib/enums/user-role'
import { Entity } from '@hedgit/lib/enums/entity'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import {
  BrokerSubscription,
  FarmerSubscription
} from '@hedgit/lib/interfaces/subscription'

import PricingProgramCard from '@hedgit/lib/components/cards/pricing-program'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'

import { useSelector } from 'store'

import useMediaQueries from 'hooks/use-media-queries'

import {
  CardClickArea,
  Content,
  SubContent,
  ContainerFlatList,
  SectionTitle
} from '../styled'
import { RouteType } from '../tabs'

type PricingProgramsBrokerListProps = {
  route: RouteType;
}

type NavigationType = NativeStackNavigationProp<
  RootStackParamList,
  'NavigationTabs'
>

const PricingProgramsBrokerList = ({
  route
}: PricingProgramsBrokerListProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()
  const { columns } = useMediaQueries()

  const farmerList = useSelector(selectCancelledPricingProgramsFarmer())
  const brokerList = useSelector(selectCancelledPricingProgramsBroker())
  const isFetching = useSelector(state => state.subscriptions.isFetching)

  const handleSubscribersPress = (pricingProgramId: string) => {
    navigation.navigate('PricingProgramSubscribers', {
      pricingProgramId,
      pricingProgramStatus: PricingProgramStatus.cancelled
    })
  }

  const renderBrokerCard: ListRenderItem<BrokerSubscription> = useCallback(
    (subscription: ListRenderItemInfo<BrokerSubscription>) => {
      return (
        <CardClickArea
          onPress={() => {
            navigation.navigate('PricingProgramDetails', {
              id: subscription.item.pricingProgramId,
              crop:
                getTranslation(
                  subscription.item.pricingProgram.product.crop.name
                ) || '',
              month: subscription.item.pricingProgram.product.month,
              year: subscription.item.pricingProgram.product.year,
              state: route.name.toLowerCase() as PricingProgramStatus,
              role: UserRole.broker
            })
          }}
        >
          <PricingProgramCard
            role={UserRole.broker}
            state={route.name.toLowerCase() as PricingProgramStatus}
            subscription={subscription.item}
            subscribers={subscription.item.subscribers}
            handleSubscribersOnPress={() =>
              handleSubscribersPress(subscription.item.pricingProgramId)}
          />
        </CardClickArea>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const renderFarmerCard: ListRenderItem<FarmerSubscription> = useCallback(
    (subscription: ListRenderItemInfo<FarmerSubscription>) => {
      return (
        <CardClickArea
          onPress={() => {
            navigation.navigate('PricingProgramDetails', {
              id: subscription.item.id,
              crop:
                getTranslation(
                  subscription.item.pricingProgram.product.crop.name
                ) || '',
              month: subscription.item.pricingProgram.product.month,
              year: subscription.item.pricingProgram.product.year,
              state: route.name.toLowerCase() as PricingProgramStatus,
              role: UserRole.farmer
            })
          }}
        >
          <PricingProgramCard
            role={UserRole.farmer}
            state={route.name.toLowerCase() as PricingProgramStatus}
            subscription={subscription.item}
            subscribers={[]}
          />
        </CardClickArea>
      )
    },
    [navigation, route]
  )

  if (isFetching && !farmerList.length && !brokerList.length) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Content>
      <SubContent>
        {
          farmerList.length === 0 && brokerList.length === 0
            ? <EmptyCard text={t('Empty.pricingPrograms.active')} entity={Entity.pricingProgram} />
            : (
              <>
                {
                  farmerList.length !== 0
                    ? (
                      <ContainerFlatList>
                        <SectionTitle>
                          {t('PricingPrograms.broker_farmer.farmerSubscriptions')}
                        </SectionTitle>
                        <FlatList
                          key={`flatListWith-${columns}-Columns-Cancelled-Farmer`}
                          numColumns={columns}
                          testID="pp-farmer-cancelled-flatList"
                          data={farmerList}
                          renderItem={renderFarmerCard}
                          keyExtractor={data => data.id}
                          style={{ width: '100%' }}
                          contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                        />
                      </ContainerFlatList>
                      )
                    : null
              }
                {
                  brokerList.length !== 0
                    ? (
                      <ContainerFlatList>
                        <SectionTitle>
                          {t('PricingPrograms.broker_farmer.brokerPrograms')}
                        </SectionTitle>
                        <FlatList
                          key={`flatListWith-${columns}-Columns-Cancelled-Broker`}
                          numColumns={columns}
                          testID="pp-broker-cancelled-flatList"
                          data={brokerList}
                          renderItem={renderBrokerCard}
                          keyExtractor={data => data.pricingProgramId}
                          style={{ width: '100%' }}
                          contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                        />
                      </ContainerFlatList>
                      )
                    : null
                }
              </>
              )
        }

      </SubContent>
    </Content>
  )
}

export default PricingProgramsBrokerList
