import React from 'react'
import { Text as TextRN, View } from 'react-native'
import { Svg, Text } from 'react-native-svg'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'

import Line from './components/line'
import Circle from './components/circle'

interface ProgressBarProps {
  color: string;
  xMax: number;
  currentDay: number;
  initLabel: string;
  endLabel: string;
}

export const ProgressBar = ({
  color,
  xMax,
  currentDay,
  initLabel,
  endLabel
}: ProgressBarProps) => {
  const { t } = useTranslation()

  const xCurrentDatePct = Math.min((currentDay / xMax) * 100, 100)

  const OFFSET_Y = 10

  return (
    <>
      <Svg style={{ height: 10, overflow: 'visible', zIndex: 2 }}>
        <Line
          pct={xCurrentDatePct}
          color={color}
        />
        <>
          { xCurrentDatePct > 15 && xCurrentDatePct < 70
            ? (
              <Text
                x={`${xCurrentDatePct}%`}
                y={OFFSET_Y + 21}
                fontFamily="lato"
                fontWeight="700"
                fill={color}
                fontSize="10"
                textAnchor="middle"
              >
                {t('Components.progressBar.currentDay').toUpperCase()}
              </Text>
              )
            : null}
          <Circle
            pct={xCurrentDatePct}
            color={color}
          />
        </>
      </Svg>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 26,
          justifyContent: 'space-between',
          borderColor: theme.colors.lightGray,
          borderStyle: 'dotted',
          borderTopWidth: 1.5,
          paddingTop: 10
        }}
      >
        <TextRN
          style={{
            color: theme.colors.dark,
            fontSize: 10,
            fontFamily: 'lato'
          }}
        >
          {initLabel}
        </TextRN>
        <TextRN
          style={{
            color: theme.colors.dark,
            fontSize: 10,
            fontFamily: 'lato'
          }}
        >
          {endLabel}
        </TextRN>
      </View>
    </>

  )
}
