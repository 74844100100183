import styled from 'styled-components/native'

export const Container = styled.View`
  width: 100%;
  height: 200px;
  justify-content: end;
  padding: 16px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  overflow: hidden;
`

export const Gradient = styled.View`
  position: absolute;
  margin-top: 100px;
  height: 100px;
  width: 100%;
  background-image: linear-gradient( rgba(255,0,0,0), rgba(0,0,0));
`

export const SubContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`

export const DescriptionContainer = styled.View`
  flex-direction: row;
  gap: 8px;
`

export const IconContainer = styled.View`
  justify-content: center;
`

export const SubIconContainer = styled.View`
  position: absolute;
  margin-left: 6px;
`

export const IconBackground = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  opacity: 0.5;
  z-index: 2;
`

export const DurationContainer = styled.View`
  justify-content: flex-end;
`
