import { Reducer } from 'redux'

import { PricingSignalsActionTypes, PricingSignalsState, PricingSignalsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'
import { formatDate } from '../../../utils/format'

export const initialState: PricingSignalsState = {
  list: [],
  farmerList: [],
  brokerList: [],
  selectedPricingProgram: {
    id: '',
    date: null
  },
  isFetching: false,
  error: '',
  pricingSignals: {},
  pricingSignalsBySubscription: {}
}

export const pricingSignalsReducer: Reducer<PricingSignalsState, PricingSignalsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): PricingSignalsState => {
  switch (action.type) {
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_PENDING:
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_SUBSCRIPTION_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case PricingSignalsActionConsts.GET_BROKER_PRICING_SIGNALS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        brokerList: action.payload
      }
    case PricingSignalsActionConsts.GET_BROKER_FARMER_PRICING_SIGNALS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        farmerList: action.payload.signalsAsFarmer || [],
        brokerList: action.payload.signalsAsBroker || []
      }
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_FAILED:
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case PricingSignalsActionConsts.EXECUTE_PRICING_SIGNALS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case PricingSignalsActionConsts.EXECUTE_PRICING_SIGNALS_FULFILLED: {
      const index = state.brokerList.findIndex((item) =>
        item.id === action.payload.pricingProgram.id &&
        formatDate(new Date(item.date)) === formatDate(new Date(action.payload.pricingProgram.signalDate))
      )
      if (index === -1) return state

      return {
        ...state,
        isFetching: false,
        brokerList: [
          ...state.brokerList.slice(0, index),
          {
            ...state.brokerList[index],
            executionPrice: action.payload.pricingProgram.executionPrice
          },
          ...state.brokerList.slice(index + 1)
        ]
      }
    }
    case PricingSignalsActionConsts.EXECUTE_PRICING_SIGNALS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case PricingSignalsActionConsts.SET_SELECTED_PRICING_PROGRAM:
      return {
        ...state,
        selectedPricingProgram: action.payload
      }
    case PricingSignalsActionConsts.CLEAR_SELECTED_PRICING_PROGRAM:
      return {
        ...state,
        selectedPricingProgram: initialState.selectedPricingProgram
      }
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FULFILLED:
      return {
        ...state,
        isFetching: false,
        pricingSignals: {
          ...state.pricingSignals,
          [action.payload.pricingProgramId]: action.payload.signals
        }
      }
    case PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        isFetching: false,
        pricingSignalsBySubscription: {
          ...state.pricingSignalsBySubscription,
          [action.payload.subscriptionId]: action.payload.signals
        }
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
