import styled from 'styled-components/native'

import Body from '@hedgit/lib/components/typography/body'
import Caption from '@hedgit/lib/components/typography/caption'
import DateText from '@hedgit/lib/components/typography/date-text'

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  padding: 12px 0px 16px;
  justify-content: space-between;
  background-color: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #e9ebed;
  border-bottom-style: solid;
`

export const SubContainer = styled.View`
  display: flex;
  justify-content: center;
`

export const Content = styled.View`
  display: flex;
  justify-content: center;
  margin-left: 16px;
  padding-top: 2px;
  flex: 1;
`

export const IconContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #e9ebed;
`

export const ImageContainer = styled.Text`
  position: absolute;
  right: 10;
  bottom: 10;
  background-color: #e9ebed;
  border-radius: 50%;
`

const Circle = styled.View`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border-color: white;
  border-width: 2px;
  z-index: 1;
  position: absolute;
  top: -1px;
  left: -1px;
`

export const GreenCircle = styled(Circle)`
  background-color: ${props => props.theme.colors.green};
`

export const RedCircle = styled(Circle)`
  background-color: ${props => props.theme.colors.red};
`

export const YellowCircle = styled(Circle)`
  background-color: ${props => props.theme.colors.yellow};
`

export const Title = styled(Body)`
  font-weight: bold;
`

export const Message = styled(Caption)`
  margin-top: 4px;
`

export const Time = styled(DateText)`
  align-self: flex-end;
  color: ${props => props.theme.colors.gray};
`
