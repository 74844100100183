/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'

import { getPricingSignals } from '@hedgit/lib/store/modules/pricing-signals/thunks'

import { PricingSignalStatus } from '@hedgit/lib/enums/pricing-signal-status'
import { UserRole } from '@hedgit/lib/enums/user-role'
import { Entity } from '@hedgit/lib/enums/entity'

import { BrokerPricingSignal, PricingSignal } from '@hedgit/lib/interfaces/pricing-signal'
import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import PricingSignalCard from '@hedgit/lib/components/cards/pricing-signal'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'

import { useDispatch, useSelector } from 'store'

import useMediaQueries from 'hooks/use-media-queries'

import { CardClickArea, ContainerFlatList, Content, SectionTitle, SubContent } from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'NavigationTabs'>

const PricingSignalsExecutedTab = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { columns } = useMediaQueries()

  const userRole = useSelector((state) => state.auth.currentUser?.role)
  const list = useSelector((state) => state.pricingSignals.list || [])
  const brokerList = useSelector((state) => state.pricingSignals.brokerList || [])
  const filteredBrokerList = brokerList.filter((signal) => signal.executionPrice)
  const filteredFarmerList = list.filter((signal) => signal.executionPrice)
  const isFetching = useSelector(state => state.pricingSignals.isFetching)

  useFocusEffect(
    useCallback(() => {
      if (userRole) {
        dispatch(getPricingSignals(userRole))
      }
    }, [dispatch, userRole])
  )

  const renderCard: ListRenderItem<PricingSignal> = useCallback(
    (pricingSignal: ListRenderItemInfo<PricingSignal>) => {
      return (
        <CardClickArea>
          <PricingSignalCard
            role={UserRole.farmer}
            state={PricingSignalStatus.executed}
            programStatus={pricingSignal.item.subscription.pricingProgram.status}
            pricingSignal={pricingSignal.item}
          />
        </CardClickArea>
      )
    },
    [navigation]
  )

  const renderCardByPricingProgram: ListRenderItem<BrokerPricingSignal> = useCallback(
    (pricingSignal: ListRenderItemInfo<BrokerPricingSignal>) => {
      return (
        <CardClickArea>
          <PricingSignalCard
            role={UserRole.broker}
            state={PricingSignalStatus.executed}
            programStatus={pricingSignal.item.subscription?.pricingProgram.status}
            pricingSignal={pricingSignal.item}
          />
        </CardClickArea>
      )
    },
    [navigation]
  )

  if (isFetching && !filteredBrokerList.length && !filteredFarmerList.length) {
    return (
      <LoadingScreen />
    )
  }

  return (

    <Content>
      <SubContent>
        {[UserRole.farmer, UserRole.broker_farmer].includes(
            userRole as UserRole
        ) && filteredFarmerList.length
          ? (
            <ContainerFlatList>
              {userRole === UserRole.broker_farmer
                ? (
                  <SectionTitle>
                    {t('PricingPrograms.broker_farmer.farmerSignals')}
                  </SectionTitle>
                  )
                : null}
              <FlatList
                key={`flatListWith-${columns}-Columns`}
                numColumns={columns}
                testID="ps-farmer-executed-flatList"
                data={filteredFarmerList}
                renderItem={renderCard}
                keyExtractor={(data, index) => data.id + index}
                style={{ width: '100%' }}
                contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                ListEmptyComponent={(
                  <EmptyCard
                    text={t('Empty.pricingSignals.executed')}
                    entity={Entity.pricingSignal}
                  />
                )}
              />
            </ContainerFlatList>
            )
          : null}
        {[UserRole.broker, UserRole.broker_farmer].includes(
            userRole as UserRole
        ) && filteredBrokerList.length
          ? (
            <ContainerFlatList>
              {userRole === UserRole.broker_farmer
                ? (
                  <SectionTitle>
                    {t('PricingPrograms.broker_farmer.brokerSignals')}
                  </SectionTitle>
                  )
                : null}
              <FlatList
                key={`flatListWith-${columns}-Columns`}
                numColumns={columns}
                testID="ps-broker-executed-flatList"
                data={filteredBrokerList}
                renderItem={renderCardByPricingProgram}
                keyExtractor={(data, index) => data.id + index}
                style={{ width: '100%' }}
                contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                ListEmptyComponent={(
                  <EmptyCard
                    text={t('Empty.pricingSignals.executed')}
                    entity={Entity.pricingSignal}
                  />
                  )}
              />
            </ContainerFlatList>
            )
          : null}
      </SubContent>
    </Content>
  )
}

export default PricingSignalsExecutedTab
