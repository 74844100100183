import React from 'react'

import { Notification } from '@hedgit/lib/interfaces/notifications'

import {
  Container,
  SubContainer,
  IconContainer,
  GreenCircle,
  RedCircle,
  YellowCircle,
  Title,
  Time,
  Message,
  Content
} from './styled'

import NotificationPricingProgramIcon from '../../icons/notification-pricing-program'
import NotificationPricingSignalIcon from '../../icons/notification-pricing-signal'
import { NotificationType } from '../../../enums/notification-type'
import { GetMonthName } from '../../../utils/format'
import { getTranslation } from '../../../translations/getTranslation'
interface NotificationCardProps {
  notification: Notification;
}

export const NotificationCard = ({
  notification
}: NotificationCardProps) => {
  return (
    <Container>
      <SubContainer>
        <IconContainer>
          {
            (notification.notificationType === NotificationType.signalCreated ||
              notification.notificationType === NotificationType.signalExecuted
              ? (
                <NotificationPricingSignalIcon color='#ADB1B8' />
                )
              : (
                <NotificationPricingProgramIcon color='#ADB1B8' />
                ))
          }
        </IconContainer>
        {!notification.isRead
          ? (notification.notificationType === NotificationType.signalExecuted ||
            notification.notificationType === NotificationType.subscriptionAccepted ||
            notification.notificationType === NotificationType.cancelSubscriptionAccepted)
              ? <GreenCircle />
              : (notification.notificationType === NotificationType.signalCreated ||
                notification.notificationType === NotificationType.subscriptionPending ||
                notification.notificationType === NotificationType.cancelSubscriptionPending)
                  ? <YellowCircle />
                  : <RedCircle />
          : <> </>}
      </SubContainer>
      <Content>
        <Title>
          {getTranslation(notification.content.product.crop)}
          {' '}
          {GetMonthName(notification.content.product.month)}
          {' '}
          {notification.content.product.year}
        </Title>
        <Message>
          {notification.message}
          {' '}
          {notification.content.brokerLastName}
        </Message>
      </Content>
      <SubContainer
        style={{
          alignSelf: 'flex-start',
          paddingTop: '8px',
          paddingRight: '10px'
        }}
      >
        <Time>{notification.time}</Time>
      </SubContainer>
    </Container>
  )
}
