import { Reducer } from 'redux'

import { ProductsActionTypes, ProductsState, ProductsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: ProductsState = {
  list: [],
  isFetching: false,
  error: ''
}

export const productsReducer: Reducer<ProductsState, ProductsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): ProductsState => {
  switch (action.type) {
    case ProductsActionConsts.GET_PRODUCTS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case ProductsActionConsts.GET_PRODUCTS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case ProductsActionConsts.GET_PRODUCTS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
