import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Initials,
  InitialsText,
  SubscribersContainer,
  NumberText,
  Number,
  SubscribersLabel,
  Container
} from './styled'

import { Subscriber } from '../../../../interfaces/subscription'

interface Props {
  onPress: () => void;
  list?: Subscriber[];
}

const Subscribers = ({ onPress, list }: Props) => {
  const { t } = useTranslation()
  return (
    <Container>
      <SubscribersContainer onPress={onPress}>
        {
          list.length > 4
            ? (
              <>
                {
                  list.slice(0, 4).map(subscriber => (
                    <Initials key={subscriber.id}>
                      <InitialsText>
                        {subscriber.firstName.charAt(0)}
                        {subscriber.lastName?.charAt(0) || subscriber.firstName.charAt(1)}
                      </InitialsText>
                    </Initials>
                  ))
                }
                <Number>
                  <NumberText>
                    {`+${list.length - 4}`}
                  </NumberText>
                </Number>
              </>
              )
            : list.map(subscriber => (
              <Initials key={subscriber.id}>
                <InitialsText>
                  {subscriber.firstName.charAt(0)}
                  {subscriber.lastName?.charAt(0) || subscriber.firstName.charAt(1)}
                </InitialsText>
              </Initials>
            ))
        }
        <SubscribersLabel>{t('Components.card.subscribers').toUpperCase()}</SubscribersLabel>
      </SubscribersContainer>
    </Container>
  )
}

export default Subscribers
