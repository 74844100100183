import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get, patch, post } from '@hedgit/lib/utils/axios'

import { BrokerSubscription, FarmerSubscription, Subscription } from '@hedgit/lib/interfaces/subscription'

import {
  getSubscriptionsPending,
  getSubscriptionsFulfilled,
  getSubscriptionsFailed,
  getBrokerSubscriptionsFulfilled,
  createSubscriptionPending,
  createSubscriptionFulfilled,
  createSubscriptionFailed,
  cancelSubscriptionPending,
  cancelSubscriptionFulfilled,
  cancelSubscriptionFailed,
  getBrokerFarmerSubscriptionsFulfilled,
  cancelSubscriptionPendingFulfilled
} from './actions'
import { SubscriptionsActionTypes } from './types'

import { UserRole } from '../../../enums/user-role'

interface Response {
  subscriptions?: BrokerSubscription[];
  subscribed?: FarmerSubscription[];
}

export const getSubscriptions =
(role: UserRole = UserRole.farmer): ThunkAction<void, unknown, unknown, SubscriptionsActionTypes> => {
  return async (dispatch) => {
    dispatch(getSubscriptionsPending())
    try {
      const response = await get<APIResponse<Response>>('/subscriptions')
      if (role === UserRole.farmer) {
        dispatch(getSubscriptionsFulfilled(response.data.subscribed))
      } else if (role === UserRole.broker) {
        dispatch(getBrokerSubscriptionsFulfilled(response.data.subscriptions))
      } else if (role === UserRole.broker_farmer) {
        dispatch(getBrokerFarmerSubscriptionsFulfilled(response.data))
      }
    } catch (error) {
      dispatch(getSubscriptionsFailed(error as string))
    }
  }
}

interface CreateResponse {
  subscription: Subscription;
}

interface SubscriptionRequest {
  pricingProgram: string;
  broker: string;
  tons: number;
}

export interface CancelSubscriptionRequest {
  cancelledBy: string;
  cancelledReason: string;
}

export const createSubscription = (
  subscription: SubscriptionRequest
): ThunkAction<void, unknown, unknown, SubscriptionsActionTypes> => {
  return async dispatch => {
    dispatch(createSubscriptionPending())
    try {
      const response = await post<APIResponse<CreateResponse>>(
        '/subscriptions',
        subscription
      )
      dispatch(createSubscriptionFulfilled(response.data.subscription))
    } catch (error) {
      dispatch(createSubscriptionFailed(error))
    }
  }
}

export const cancelSubscription = (
  id: string
): ThunkAction<void, unknown, unknown, SubscriptionsActionTypes> => {
  return async dispatch => {
    dispatch(cancelSubscriptionPending())
    try {
      const response = await patch<APIResponse<CreateResponse>>(
        `/subscriptions/${id}/actions/cancel`
      )
      dispatch(cancelSubscriptionFulfilled(response.data.subscription))
    } catch (error) {
      dispatch(cancelSubscriptionFailed(error))
    }
  }
}

export const pendingCancelSubscription = (
  id: string,
  cancelSubscription: CancelSubscriptionRequest
): ThunkAction<void, unknown, unknown, SubscriptionsActionTypes> => {
  return async dispatch => {
    dispatch(cancelSubscriptionPending())
    try {
      const response = await patch<APIResponse<CreateResponse>>(
        `/subscriptions/${id}/actions/pending-cancellation`,
        cancelSubscription
      )
      dispatch(cancelSubscriptionPendingFulfilled(response.data.subscription))
    } catch (error) {
      dispatch(cancelSubscriptionFailed(error))
    }
  }
}
