/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const Camera = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg viewBox="0 0 21.85 14.406" width={width} height={height}>
      <Path id={'Path_34740' + id} data-name={'Path 34740' + id} d="M19.85.5V12.075a.356.356,0,0,1-.331.331h-.165c-.165,0-.165,0-.331-.165L14.887,8.105v3.143c0,.331-.165.5-.331.827a1.773,1.773,0,0,1-.992.331H1.323A1.264,1.264,0,0,1,.5,12.075c-.331-.331-.5-.5-.5-.827V1.323A1.264,1.264,0,0,1,.331.5,1.179,1.179,0,0,1,1.323,0H13.73a1.264,1.264,0,0,1,.827.331,2.351,2.351,0,0,1,.331.993V4.466l4.3-4.3c0-.165.165-.165.331-.165C19.685.165,19.85.165,19.85.5Z" transform="translate(1 1)" fill="none" stroke={color} strokeWidth="2" />
    </Svg>
  )
}

export default Camera
