import styled from 'styled-components/native'

export const Title = styled.Text`
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
`
export const Button = styled.Button`
weidth: 50vh;
font-size: 56px;
font-weight: bold;
padding: 10px;
backgroundColor: red;
`
