import styled from 'styled-components/native'

import H2 from '@hedgit/lib/components/typography/h2'

export const Content = styled.View`
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

export const SubContent = styled.View`
  align-items: center;
  height: 100%;
  max-height: fit-content;
  overflow: auto;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

export const CardClickArea = styled.TouchableOpacity`
  margin: 0 10px;
  width: 90vw;
  min-width: 350px;
  max-width: 400px;
`

export const ContainerFlatList = styled.View`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`

export const SectionTitle = styled(H2)`
  text-align: left;
  width: 100%;
  padding: 0 16px;
`
