/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
}

const MenuIcon = ({ color }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'Menu_Icon' + id} width="16" height="14" viewBox="0 0 16 14">
      <Path id={'Menu_Icon' + id} data-name={'Menu_Icon' + id} d="M0,14V12H8v2ZM0,8V6H16V8ZM0,2V0H16V2Z" fill={color} />
    </Svg>
  )
}

export default MenuIcon
