import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { getDate } from '@hedgit/lib/translations/getTranslation'

import { BrokerSubscription, FarmerSubscription, Subscriber } from '@hedgit/lib/interfaces/subscription'

import {
  PricingCard,
  PricingCardContent,
  PPContentContainer,
  PricingCardContentBox,
  PPContentDoubles,
  getPPStatusColor,
  CardBodyTitle,
  CardBodyText,
  CardBodyTitleSmall
} from './styled'
import CardHeader from './components/card-header'
import CardFooter from './components/card-footer'
import Subscribers from './components/subscribers/subscribers'

import { GetMonthName } from '../../utils/format'
import { PricingProgramStatus } from '../../enums/pricing-program-status'
import { SubscriptionStatus } from '../../enums/subscription-status'
import { UserRole } from '../../enums/user-role'
import { getTranslation } from '../../translations/getTranslation'
interface Props {
  role: UserRole;
  state: PricingProgramStatus;
  subscription?: FarmerSubscription | BrokerSubscription;
  handleSubscribersOnPress?: () => void;
  subscribers?: Subscriber[];
  subscriptionStatus?: SubscriptionStatus;
}

const PricingProgramCard = ({
  state,
  subscription,
  role,
  handleSubscribersOnPress,
  subscribers,
  subscriptionStatus
}: Props) => {
  const { t } = useTranslation()

  const productLabel = [
    getTranslation(subscription.pricingProgram.product.crop.name),
    GetMonthName(subscription.pricingProgram.product.month),
    subscription.pricingProgram.product.year
  ].join(' ')

  const color = getPPStatusColor(state as PricingProgramStatus)

  const subscribedOnLabel = role === UserRole.farmer
    ? getDate(subscription.subscriptionTime)
    : null

  const subtitle = `${t('Components.card.subscribedOn').toLowerCase()} ${subscribedOnLabel}`

  const broker = role === UserRole.farmer
    ? ((subscription as FarmerSubscription).broker.lastName || (subscription as FarmerSubscription).broker.firstName)
    : null

  let tons = 0

  if (role === UserRole.farmer) {
    tons = subscription.tons
  } else if (role === UserRole.broker) {
    for (let i = 0; i < subscribers.length; i++) {
      tons += subscribers[i].tons
    }
  }

  let brokerSubscribers = SubscriptionStatus.active
  const brokerSubscriberPending =
    subscribers?.find(item => item.status === SubscriptionStatus.pending)
  const brokerSubscriberPendingCancellation =
    subscribers?.find(item => item.status === SubscriptionStatus.pendingCancellation)

  if (brokerSubscriberPending && !brokerSubscriberPendingCancellation) {
    brokerSubscribers = SubscriptionStatus.pending
  } else if (brokerSubscriberPendingCancellation && !brokerSubscriberPending) {
    brokerSubscribers = SubscriptionStatus.pendingCancellation
  } else if (brokerSubscriberPendingCancellation && brokerSubscriberPending) {
    brokerSubscribers = SubscriptionStatus.pending
  }

  const finalStatus = role === UserRole.farmer ? subscriptionStatus : brokerSubscribers

  const algorithmName = getTranslation(subscription.pricingProgram.algorithm.name).toUpperCase()

  return (
    <PricingCard
      testID={`pp-${subscription.id}-card`}
      state={finalStatus}
    >
      <CardHeader
        role={role}
        product={productLabel}
        subtitle={subtitle}
        cardType="pricing-program"
        iconColor={color}
        broker={broker}
        tons={tons}
      />
      {
        role === 'broker' && (
          <Subscribers onPress={handleSubscribersOnPress} list={subscribers} />
        )
      }
      <PricingCardContent>
        <PPContentContainer state={state}>
          <PPContentDoubles style={{ borderRight: '1px solid white' } as StyleProp<ViewStyle>}>
            <PricingCardContentBox>
              {
                algorithmName.length < 13
                  ? (
                    <CardBodyTitle>
                      {algorithmName}
                    </CardBodyTitle>
                    )
                  : (
                    <CardBodyTitleSmall>
                      {algorithmName}
                    </CardBodyTitleSmall>
                    )
              }
              <CardBodyText>
                {t('Components.card.algorithm').toUpperCase()}
              </CardBodyText>
            </PricingCardContentBox>
          </PPContentDoubles>
          <PPContentDoubles>
            <PricingCardContentBox>
              <CardBodyTitle>
                {t('Components.card.currency').toUpperCase()}
                {' '}
                {parseFloat(subscription.referencePrice?.toFixed(2)) || '-'}
              </CardBodyTitle>
              <CardBodyText>
                {t('Components.card.referencePrice').toUpperCase()}
              </CardBodyText>
            </PricingCardContentBox>
          </PPContentDoubles>
        </PPContentContainer>
      </PricingCardContent>
      <CardFooter
        entityType="pricing-program"
        status={state}
        color={color}
        startDate={subscription.pricingProgram.startDate}
        endDate={subscription.pricingProgram.endDate}
      />
    </PricingCard>
  )
}

export default PricingProgramCard
