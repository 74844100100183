import React from 'react'
import { Image } from 'react-native'

import { Container, Icon, Content, CardText, CardText2 } from './styled'

interface UnavailableProductCardProps {
  text: string;
  generalText: string;
}

export const UnavailableProductCard = ({
  text,
  generalText
}: UnavailableProductCardProps) => {
  return (
    <Container>
      <Icon>
        <Image
          source={require('../../../utils/assets/brain.png')}
          style={{ width: 20, height: 20 }}
        />
      </Icon>
      <Content>
        <CardText>
          {`${text}`}
        </CardText>
        <CardText2>
          {`${generalText}`}
        </CardText2>
      </Content>
    </Container>
  )
}
