import { ActionType } from 'typesafe-actions'

import { User } from '@hedgit/lib/interfaces/user'

import * as actions from './actions'
import { createFarmer } from './thunks'

export enum AuthActionConsts {
  SET_TOKEN='SET_TOKEN',
  CLEAR_TOKEN='CLEAR_TOKEN',
  SIGN_IN='SIGN_IN',
  SIGN_OUT='SIGN_OUT',
  SAVE_DATA='SAVE_DATA',
  SAVE_PHONE='SAVE_PHONE',
  SAVE_USER_INFORMATION='SAVE_USER_INFORMATION',
  SAVE_FIREBASE_UID='SAVE_FIREBASE_UID',
  VERIFY_USER_PENDING='VERIFY_USER_PENDING',
  VERIFY_USER_FULFILLED='VERIFY_USER_FULFILLED',
  VERIFY_USER_REJECTED='VERIFY_USER_REJECTED',
  CREATE_FARMER_PENDING='CREATE_FARMER_PENDING',
  CREATE_FARMER_FULFILLED='CREATE_FARMER_FULFILLED',
  CREATE_FARMER_REJECTED='CREATE_FARMER_REJECTED',
  CREATE_BROKER_PENDING='CREATE_BROKER_PENDING',
  CREATE_BROKER_FULFILLED='CREATE_BROKER_FULFILLED',
  CREATE_BROKER_REJECTED='CREATE_BROKER_REJECTED',
  GET_USER_DATA_PENDING='GET_USER_DATA_PENDING',
  GET_USER_DATA_FULFILLED='GET_USER_DATA_FULFILLED',
  GET_USER_DATA_REJECTED='GET_USER_DATA_REJECTED',
  UPDATE_FARMER_PENDING='UPDATE_FARMER_PENDING',
  UPDATE_FARMER_FULFILLED='UPDATE_FARMER_FULFILLED',
  UPDATE_FARMER_REJECTED='UPDATE_FARMER_REJECTED',
  UPDATE_BROKER_PENDING='UPDATE_BROKER_PENDING',
  UPDATE_BROKER_FULFILLED='UPDATE_BROKER_FULFILLED',
  UPDATE_BROKER_REJECTED='UPDATE_BROKER_REJECTED',
  DELETE_BROKER_PENDING='DELETE_BROKER_PENDING',
  DELETE_BROKER_FULFILLED='DELETE_BROKER_FULFILLED',
  DELETE_BROKER_REJECTED='DELETE_BROKER_REJECTED',
}

export type AuthActionTypes = ActionType<typeof actions>
export type SignOutActionType = ActionType<typeof actions.signOut>
export type AuthThunksTypes = typeof createFarmer

export interface AuthState {
  readonly token: string;
  readonly isFetching: boolean;
  readonly error: string;
  readonly user?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    firebaseUID: string;
  };
  readonly phone?: string;
  readonly currentUser?: User;
  readonly isUpdatingUser: boolean;
  readonly isDeletingUser: boolean;
  readonly isVerified: boolean;
}
