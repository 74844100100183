import { Reducer } from 'redux'

import { MarketDataActionTypes, MarketDataState, MarketDataActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: MarketDataState = {
  list: [],
  listByProduct: {},
  fullListByProduct: {},
  listByPricingProgram: {},
  isFetching: false,
  error: '',
  isCreatingMarketData: false,
  editingMarketData: undefined
}

export const marketDataReducer: Reducer<MarketDataState, MarketDataActionTypes | SignOutActionType> = (
  state = initialState,
  action
): MarketDataState => {
  switch (action.type) {
    case MarketDataActionConsts.GET_MARKET_DATA_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case MarketDataActionConsts.GET_MARKET_DATA_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case MarketDataActionConsts.GET_MARKET_DATA_BY_PRODUCT_FAILED:
    case MarketDataActionConsts.GET_MARKET_DATA_FAILED:
    case MarketDataActionConsts.GET_MARKET_DATA_BY_PRICING_PROGRAM_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case MarketDataActionConsts.GET_MARKET_DATA_BY_PRODUCT_PENDING:
    case MarketDataActionConsts.GET_MARKET_DATA_BY_PRICING_PROGRAM_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case MarketDataActionConsts.GET_MARKET_DATA_BY_PRODUCT_FULFILLED:
      return {
        ...state,
        isFetching: false,
        listByProduct: {
          ...state.listByProduct,
          [action.payload.productId]: action.payload.data
        }
      }
    case MarketDataActionConsts.GET_FULL_MARKET_DATA_BY_PRODUCT_FULFILLED:
      return {
        ...state,
        isFetching: false,
        fullListByProduct: {
          ...state.fullListByProduct,
          [action.payload.productId]: action.payload.data
        }
      }
    case MarketDataActionConsts.GET_MARKET_DATA_BY_PRICING_PROGRAM_FULFILLED:
      return {
        ...state,
        isFetching: false,
        listByPricingProgram: {
          ...state.listByPricingProgram,
          [action.payload.pricingProgramId]: action.payload.data
        }
      }
    case MarketDataActionConsts.EDIT_MARKET_DATA_PENDING:
    case MarketDataActionConsts.CREATE_MARKET_DATA_PENDING:
      return {
        ...state,
        isCreatingMarketData: true
      }
    case MarketDataActionConsts.CREATE_MARKET_DATA_FULFILLED:
      return {
        ...state,
        isCreatingMarketData: false,
        list: [...state.list, action.payload]
      }
    case MarketDataActionConsts.CREATE_MARKET_DATA_FAILED:
      return {
        ...state,
        isCreatingMarketData: false,
        error: action.payload
      }
    case MarketDataActionConsts.SET_EDITING_MARKET_DATA:
      return {
        ...state,
        editingMarketData: action.payload
      }
    case MarketDataActionConsts.EDIT_MARKET_DATA_FULFILLED: {
      return {
        ...state,
        isCreatingMarketData: false,
        editingMarketData: undefined
      }
    }
    case MarketDataActionConsts.EDIT_MARKET_DATA_FAILED:
      return {
        ...state,
        isCreatingMarketData: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
