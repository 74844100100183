import styled from 'styled-components/native'

export const Container = styled.View`
  background-color: #FFF;
  justify-content: center;
  height: 100vh;
  justify-content: flex-start;
  overflow-y: scroll;
`

export const SubContainer = styled.View`
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  max-width: 600px;
`

export const DetailsContainer = styled.View`
  padding: 16px;
`

export const Pressable = styled.Pressable`
  position: absolute;
  top: 24px;
  padding: 16px;
`
