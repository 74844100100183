import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { theme } from '@hedgit/lib/theme'

import { getPricingSignals } from '@hedgit/lib/store/modules/pricing-signals/thunks'

import useResetTab from '@hedgit/lib/hooks/use-reset-tab'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootState } from 'store'

import PricingSignalsPendingTab from 'routes/pricing-signals/tabs/pending'
import PricingSignalsExecutedTab from 'routes/pricing-signals/tabs/executed'

import selectPendingPricingSignalsFarmer from '../../../store/selectors/selectPendingPricingSignalsFarmer'
import selectExecutedPricingSignalsFarmer from '../../../store/selectors/selectExecutedPricingSignalsFarmer'
import selectExecutedPricingSignalsBroker from '../../../store/selectors/selectExecutedPricingSignalsBroker'
import selectPendingPricingSignalsBroker from '../../../store/selectors/selectPendingPricingSignalsBroker'

const Tab = createMaterialTopTabNavigator()

const { colors } = theme

const PricingSignalsTabs = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userRole = useSelector((state: RootState) => state.auth.currentUser?.role)

  useResetTab('PricingSignalsExecutedTab')

  useEffect(() => {
    if (userRole) {
      dispatch(getPricingSignals(userRole))
    }
  }, [dispatch, userRole])

  const brokerPendingBadge = useSelector(selectPendingPricingSignalsBroker()).length
  const brokerExecutedBadge = useSelector(selectExecutedPricingSignalsBroker()).length

  const farmerPendingBadge = useSelector(selectPendingPricingSignalsFarmer()).length
  const farmerExecutedBadge = useSelector(selectExecutedPricingSignalsFarmer()).length

  const pendingBadge = () => {
    if ([UserRole.broker].includes(userRole as UserRole)) {
      return brokerPendingBadge
    } else if ([UserRole.farmer].includes(userRole as UserRole)) {
      return farmerPendingBadge
    } else if ([UserRole.broker_farmer].includes(userRole as UserRole)) {
      return brokerPendingBadge + farmerPendingBadge
    }
    return 0
  }

  const executedBadge = () => {
    if ([UserRole.broker].includes(userRole as UserRole)) {
      return brokerExecutedBadge
    } else if ([UserRole.farmer].includes(userRole as UserRole)) {
      return farmerExecutedBadge
    } else if ([UserRole.broker_farmer].includes(userRole as UserRole)) {
      return brokerExecutedBadge + farmerExecutedBadge
    }
    return 0
  }

  return (
    <Tab.Navigator screenOptions={{
      tabBarActiveTintColor: colors.primary,
      tabBarInactiveTintColor: colors.dark,
      tabBarIndicatorStyle: {
        backgroundColor: colors.primary
      }
    }}
    >
      <Tab.Screen
        name="PricingSignalsExecutedTab"
        component={PricingSignalsExecutedTab}
        options={{
          tabBarLabel: `${executedBadge()} ${t('PricingSignals.tab.executed')}`,
          tabBarTestID: 'ps-executed-tab'
        }}
      />
      <Tab.Screen
        name="PricingSignalsPendingTab"
        component={PricingSignalsPendingTab}
        options={{
          tabBarLabel: `${pendingBadge()} ${t('PricingSignals.tab.pending')}`,
          tabBarTestID: 'ps-pending-tab'
        }}
      />
    </Tab.Navigator>
  )
}

export default PricingSignalsTabs
