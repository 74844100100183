import { ActionType } from 'typesafe-actions'

import * as actions from './actions'

export enum TranslationsActionConsts {
  SET_LANGUAGE='SET_LANGUAGE'
}

export type TranslationsActionTypes = ActionType<typeof actions>

export interface TranslationsState {
  readonly language: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly keys: Record<string, any>;
}
