import React from 'react'
import { useTranslation } from 'react-i18next'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { theme } from '@hedgit/lib/theme'

import useResetTab from '@hedgit/lib/hooks/use-reset-tab'

import AlgorithmsCornTab from './corn'
import AlgorithmsWheatTab from './wheat'
import AlgorithmsList from './list'

const Tab = createMaterialTopTabNavigator()

const { colors } = theme

const AlgorithmsTabs = () => {
  const { t } = useTranslation()

  useResetTab(t('AlgorithmDetails.tab.soy'))

  return (
    <Tab.Navigator screenOptions={{
      tabBarActiveTintColor: colors.primary,
      tabBarInactiveTintColor: colors.dark,
      tabBarIndicatorStyle: {
        backgroundColor: colors.primary
      }
    }}
    >
      <Tab.Screen
        name={t('AlgorithmDetails.tab.soy')}
        component={AlgorithmsList}
        options={{
          tabBarTestID: 'al-soy-tab',
          tabBarLabel: t('AlgorithmDetails.tab.soy')
        }}
      />
      <Tab.Screen
        name={t('AlgorithmDetails.tab.corn')}
        component={AlgorithmsCornTab}
        options={{
          tabBarTestID: 'al-corn-tab',
          tabBarLabel: t('AlgorithmDetails.tab.corn')
        }}
      />
      <Tab.Screen
        name={t('AlgorithmDetails.tab.wheat')}
        component={AlgorithmsWheatTab}
        options={{
          tabBarTestID: 'al-wheat-tab',
          tabBarLabel: t('AlgorithmDetails.tab.wheat')
        }}
      />
    </Tab.Navigator>
  )
}

export default AlgorithmsTabs
