import styled from 'styled-components/native'
import {
  Button as NBButton
} from 'native-base'

export const Content = styled.View`
  align-items: center;
  height: calc(100vh - 64px);
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

export const SubContent = styled.View`
  padding-top: 30px;
  height: 100%;
  max-height: fit-content;
  width: 100%;
  max-width: 600px;
  align-self: center;
`

export const Button = styled(NBButton)`
  align-items: center;
  justify-content: center;
  padding-vertical: 12px;
  padding-horizontal: 32px;
  border-radius: 4px;
  elevation: 3;
  margin: 3%;
  font-family: arial;
  margin-top: 60px;
`
