import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'
import React from 'react-native'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import H1 from '@hedgit/lib/components/typography/h1'
import Body from '@hedgit/lib/components/typography/body'

import StatusExecuted from '../../../icons/status-executed'
import StatusCancelled from '../../../icons/status-cancelled'
import StatusActive from '../../../icons/status-active'

const addOpacity = (color: string) => color + '33'

export const AlgorithmTypeContent = styled.View`
  font-family: arial;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

// PricingProgram Charts
export const ChartTitle = styled(H1)`
  align-items: flex-end;
  justify-content: center;
  padding: 20px 0px;
`

export const ChartGlobalContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom-color: ${props => props.theme.colors.lightGray};
  border-bottom-width: 1;
`

export const PerformanceChartContent = styled.View`
  align-items: center;
  height: 172px;
  width: 221px;
  padding-left: 10px;
  margin-top: -5px;
`

export const CoverageAndSignalContent = styled.View`
  flex-direction: column;
  justify-content: space-around;
  margin-top: 14px;
  margin-bottom: 18px;
  border-left-color: ${props => props.theme.colors.lightGray};
  border-left-width: 1;
  padding-left: 16px;
  height: 172px;
  padding-right: 2px;
`

export const Box = styled.View`
  align-items: center;
  padding-top: 8px;
`

export const CoverageBox = styled.View`
  align-items: center;
  border-bottom-color: ${props => props.theme.colors.lightGray};
  border-bottom-width: 1;
  padding-bottom: 15px;
`

export const ImageDetails = styled.Image`
  width: 18px;
  height: 18px;
`

export const Card = styled.View`
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  width: 100%;
  max-width: 600px;
`

export const CardContent = styled.View`
  margin-left: 16px;
  margin-right: 14px;
  margin-bottom: 10px;
  margin-top: 6px;
`

export const CardText = styled.Text`
  color: ${props => props.theme.colors.green};
  padding-top: 9px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const LeftTextContent = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

type TextProps = {
  textColor: string;
}

export const TitleTextDetails = styled(Body)<TextProps>`
  color: ${props => props.textColor} ;
  padding-left: 13px;
`

export const DataTextDetails = styled(Body)<TextProps>`
  color: ${props => props.textColor};
  font-weight: bold;
`

export const SmallIconBox = styled.View`
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
`

type IconBoxProps = {
  color: string;
}

export const IconBox = styled.View<IconBoxProps>`
  position: absolute;
  margin-top: 12px;
  margin-left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${props => addOpacity(props.color)};
`

export const getPPStatusColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.green

  if (status === PricingProgramStatus.cancelled) return theme.colors.red

  return theme.colors.dark
}

export const getStateIcon = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) {
    return (
      <StatusActive
        color={theme.colors.dark}
        width={22}
        height={22}
      />
    )
  }

  if (status === PricingProgramStatus.executed) {
    return (
      <StatusExecuted
        color={theme.colors.green}
        width={22}
        height={22}
      />
    )
  }

  if (status === PricingProgramStatus.cancelled) {
    return (
      <StatusCancelled
        color={theme.colors.red}
        width={22}
        height={22}
      />
    )
  }

  return (
    <StatusActive
      color={theme.colors.dark}
      width={22}
      height={22}
    />
  )
}

export const getPPTextColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.dark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getPPDataColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.dark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getPPEPMarkerColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.dark

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getPPBPMarkerColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.green

  if (status === PricingProgramStatus.executed) return theme.colors.green

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.green
}
