/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import {
  SignalText,
  TotalSignalText,
  SignalReceivedText,
  NumbersContainer,
  SignalsContainer,
  getPPTextColor,
  getPPDarkTextColor
} from './styled'

interface SignalsProps {
  received: number;
  total: number;
  state: PricingProgramStatus;
}

const SignalsReceived = ({
  received,
  total,
  state
} :SignalsProps) => {
  const { t } = useTranslation()
  const Text = t('Components.card.signalsReceived')
  const textColor = getPPTextColor(state as PricingProgramStatus)
  const darkColor = getPPDarkTextColor(state as PricingProgramStatus)
  return (
    <SignalsContainer>
      {/* Text */}
      <NumbersContainer>
        <SignalReceivedText
          textColor={darkColor}
        >
          {received}
        </SignalReceivedText>
        <TotalSignalText
          textColor={textColor}
        >
          /
          {total}
        </TotalSignalText>
      </NumbersContainer>
      <SignalText>
        {Text}
      </SignalText>
    </SignalsContainer>
  )
}

export default SignalsReceived
