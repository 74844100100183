import { useEffect, useState } from 'react'

const useSafeArea = () => {
  const [top, setTop] = useState(0)
  const [bottom, setBottom] = useState(0)

  useEffect(() => {
    const sab = getComputedStyle(document.documentElement).getPropertyValue('--sab')
    const sat = getComputedStyle(document.documentElement).getPropertyValue('--sat')

    setBottom(parseInt(sab))
    setTop(parseInt(sat))
  }, [])

  return {
    top,
    bottom
  }
}

export default useSafeArea
