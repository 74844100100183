import React from 'react'
import { differenceInCalendarDays, format, isBefore } from 'date-fns'

import { GetMonthName } from '@hedgit/lib/utils/format'

import { PricingCardFooter } from './styled'
import { CancelledProgressBar } from './components/progress-bar/cancelled'
import { ExecutedProgressBar } from './components/progress-bar/executed'
import { ProgressBar } from './components/progress-bar'

interface Props {
  status: string;
  color: string;
  startDate: Date;
  endDate: Date;
  entityType: 'pricing-program' | 'pricing-signal';
}

const CardFooter = (props: Props) => {
  const { startDate, endDate, color, status, entityType } = props

  const xMax = differenceInCalendarDays(new Date(endDate), new Date(startDate))

  const today = differenceInCalendarDays(new Date(), new Date(startDate))

  const todayPosition = (today / xMax) * 100

  const startMonth = format(new Date(startDate), 'M')
  const endMonth = format(new Date(endDate), 'M')

  const startMonthLabel = GetMonthName(startMonth).toString()
  const endMonthLabel = GetMonthName(endMonth).toString()
  const currentDate = new Date()

  const showEmptyProgressBar = () => {
    if (isBefore(currentDate, new Date(startDate))) return true
    return false
  }

  return (
    <PricingCardFooter>
      {
        status !== 'pricingprogramscancelledtab' && status !== 'pricingprogramsexecutedtab' && status !== 'executed'
          ? (showEmptyProgressBar()
              ? (
                <ProgressBar
                  color={color}
                  xMax={100}
                  currentDay={0}
                  initLabel={startMonthLabel.toUpperCase()}
                  endLabel={endMonthLabel.toUpperCase()}
                />
                )
              : (
                <ProgressBar
                  color={color}
                  xMax={100}
                  currentDay={todayPosition}
                  initLabel={startMonthLabel.toUpperCase()}
                  endLabel={endMonthLabel.toUpperCase()}
                />
                )
            )
          : status === 'pricingprogramscancelledtab'
            ? (
              <CancelledProgressBar
                entityType={entityType}
                status={status}
                color={color}
                xMax={100}
                currentDay={todayPosition}
                initLabel={startMonthLabel.toUpperCase()}
                endLabel={endMonthLabel.toUpperCase()}
              />
              )
            : (
              <ExecutedProgressBar
                entityType={entityType}
                status={status}
                color={color}
                xMax={100}
                currentDay={todayPosition}
                initLabel={startMonthLabel.toUpperCase()}
                endLabel={endMonthLabel.toUpperCase()}
              />
              )
      }
    </PricingCardFooter>
  )
}

export default CardFooter
