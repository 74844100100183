import React from 'react'
import { useTranslation } from 'react-i18next'

import { UnavailableProductCard } from '@hedgit/lib/components/cards/unavailable-product-card/unavailable-product-card'

import { Content, SubContent } from './styled'

const AlgorithmsCornTab = () => {
  const { t } = useTranslation()

  return (
    <Content>
      <SubContent>
        <UnavailableProductCard
          text={t('Algorithm.tab.corn.text')}
          generalText={t('Algorithm.tab.corn.general')}
        />
      </SubContent>
    </Content>
  )
}

export default AlgorithmsCornTab
