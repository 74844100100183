/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Circle, Svg, Text, Line } from 'react-native-svg'
import { theme } from '@hedgit/lib/theme'
import type { CircleProps } from 'react-native-svg'

interface DecoratorProps {
    x: (arg: number) => number;
    y: (arg: number) => number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: number[] | Record<string, any>[];
}

interface DotProps {
  x: (arg: number) => number;
  y: (arg: number) => number;
  index: number;
  value: number;
}

interface CircleSvgProps extends CircleProps {
  style: Record<string, string>;
}

const CircleSvg = (props: CircleSvgProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Circle {...props} />
}

const Dot = ({ x, y, index, value }: DotProps) => {
  const shadow = { filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .16))' }
  return (
    <Svg>
      <Text
        x={x(index)}
        y={y(value) - 12}
        fontWeight="600"
        fill="#000"
        fontSize="10"
        fontFamily='Lato'
        textAnchor='middle'
      >
        {value}
      </Text>
      <Line
        x1={x(index)}
        y1={y(value)}
        x2={x(index)}
        y2={240}
        stroke={theme.colors.primary}
        strokeOpacity={0.3}
        strokeDasharray="1.5"
        strokeWidth="1"
        opacity={0.5}
      />
      <CircleSvg
      // eslint-disable-next-line react/no-array-index-key
        key={index}
        cx={x(index)}
        cy={y(value)}
        r={4.5}
        fill={theme.colors.primary}
        style={shadow}
      />
    </Svg>
  )
}

export const Dots = (props: Partial<DecoratorProps>) => {
  const { x, y, data } = props as DecoratorProps
  return (
    <Svg key={`dot-${data}`}>
      {
        typeof data[0] === 'number'
          ? data.map((value, index) => {
            return <Dot key={`dot-${index}`} x={x} y={y} index={index} value={value} />
          })
          : data[0].data.map((value, index) => {
            return <Dot key={`dot-${index}`} x={x} y={y} index={index} value={value} />
          })
      }
    </Svg>
  )
}
