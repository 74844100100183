import React from 'react'

import { ButtonContainer, ButtonText } from './styled'

export interface FilterButtonProps {
  label: string;
  onPress: () => void;
  isSelected: boolean;
  disabled?: boolean;
}

export const FilterButton = ({
  label,
  onPress,
  isSelected,
  disabled = false
}:FilterButtonProps) => {
  return (
    <ButtonContainer disabled={disabled} onPress={onPress} key={label} isSelected={isSelected}>
      <ButtonText isSelected={isSelected}>
        {label}
      </ButtonText>
    </ButtonContainer>
  )
}

export default FilterButton
