import React, { useState } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { APIResponse } from '@hedgit/lib/types/api'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useDispatch } from '@hedgit/web/src/store'

import { patch } from '@hedgit/lib/utils/axios'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { Subscriber, Subscription } from '@hedgit/lib/interfaces/subscription'

import { UserModal } from '@hedgit/lib/components/modals/user-modal'

import {
  Card,
  CardContent,
  DetailsContent,
  SeeBox,
  SeeText,
  DetailsText,
  SubscriberText,
  SubscriberName,
  UserIcon,
  TonsText,
  NewText,
  CancelText,
  UserIconContainer
} from './styled'

import { SubscriptionStatus } from '../../../enums/subscription-status'
import { getSubscriptions } from '../../../store/modules/subscriptions/thunks'
import { UserRole } from '../../../enums/user-role'
import { getDate } from '../../../translations/getTranslation'

interface SubscriberProps {
 subscriber: Subscriber;
}

interface cancelResponse {
  subscription: Subscription;
}

type NavigationProps = NativeStackNavigationProp<RootStackParamList, 'PricingProgramSubscribers'>

export const SubscriberCard = ({ subscriber }: SubscriberProps) => {
  const navigation = useNavigation<NavigationProps>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [userModalVisible, setUserModalVisible] = useState(false)

  const subscriptionTime = getDate(subscriber.subscriptionTime)

  const nameInitial = subscriber.firstName.charAt(0)
  const lastNameInitial = subscriber.lastName.charAt(0)

  const subscriptionId = subscriber.subscriptionId

  const buttonConfirmActions = async () => {
    if (subscriber.status === SubscriptionStatus.pendingCancellation) {
      if (subscriptionId) {
        try {
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/cancel`
          )
          setUserModalVisible(false)
          dispatch(getSubscriptions(UserRole.broker))
          navigation.navigate('NavigationTabs')
        } catch (error) {
          console.log(error)
        }
      }
    } else if (subscriber.status === SubscriptionStatus.pending) {
      if (subscriptionId) {
        try {
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/accepted`
          )
          dispatch(getSubscriptions(UserRole.broker))
          setUserModalVisible(false)
          navigation.navigate('NavigationTabs')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  const buttonRejectActions = async () => {
    if (subscriber.status === SubscriptionStatus.pendingCancellation) {
      if (subscriptionId) {
        try {
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/reject-cancellation`
          )
          dispatch(getSubscriptions(UserRole.broker))
          setUserModalVisible(false)
          navigation.navigate('NavigationTabs')
        } catch (error) {
          console.log(error)
        }
      }
    } else if (subscriber.status === SubscriptionStatus.pending) {
      if (subscriptionId) {
        try {
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/reject-subscription`
          )
          dispatch(getSubscriptions(UserRole.broker))
          setUserModalVisible(false)
          navigation.navigate('NavigationTabs')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  return (
    <TouchableOpacity
      onPress={() => setUserModalVisible(true)}
    >
      <Card testID={`pps-subscribers-${subscriber.id}-card`}>
        <CardContent testID={`pps-subscribers-${subscriber.id}-cardContent`}>
          <DetailsContent>
            <UserIconContainer>
              <UserIcon>
                {nameInitial}
                {lastNameInitial}
              </UserIcon>
            </UserIconContainer>
            <DetailsText>
              <SubscriberName>
                <Text>{subscriber.firstName}</Text>
                {' '}
                <Text>{subscriber.lastName}</Text>
                {
                  subscriber.status === SubscriptionStatus.pending
                    ? (
                      <NewText>
                        {t('Components.card.new')}
                      </NewText>
                      )
                    : null
                }
                {
                  subscriber.status === SubscriptionStatus.pendingCancellation
                    ? (
                      <CancelText>
                        {t('Components.card.cancel')}
                      </CancelText>
                      )
                    : null
                }
              </SubscriberName>
              <SubscriberText>
                <TonsText>
                  {subscriber.tons}
                  {' '}
                  {t('Components.card.tons')}
                </TonsText>
                <Text>
                  {subscriptionTime}
                </Text>
              </SubscriberText>
            </DetailsText>
          </DetailsContent>
          <SeeBox>
            <SeeText>
              {t('Subscribers.see')}
            </SeeText>
          </SeeBox>
        </CardContent>
        <UserModal
          userName={subscriber.firstName + ' ' + subscriber.lastName}
          body={t('Subscribers.date') + subscriptionTime}
          phone={subscriber.phone}
          email={subscriber.email}
          visible={userModalVisible}
          onPress={() => setUserModalVisible(false)}
          firstLetter={nameInitial}
          secondLetter={lastNameInitial}
          status={subscriber.status}
          buttons={[
            {
              label: t('Components.button.reject'),
              onPress: () => buttonRejectActions(),
              testID: 'reject-modal-button',
              variant: 'secondary'
            },
            {
              label: t('Components.button.confirm'),
              onPress: () => buttonConfirmActions(),
              testID: 'confirm-modal-button',
              variant: 'primary'
            }
          ]}
        />
      </Card>
    </TouchableOpacity>
  )
}
