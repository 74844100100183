import styled from 'styled-components/native'

import { ModalProps } from './modal'

import { SharedButton } from '../../shared/button'

export const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000052;
`

interface ModalViewProps {
  variant: ModalProps['variant'];
}

export const ModalView = styled.View<ModalViewProps>`
  background-color: white;
  border-radius: 10px;
  align-items: center;
  width: 85%;
  padding: 16px;
  padding-top: ${props => props.variant === 'confirm' ? '24px' : '34px'};
  max-width: 500px;
`

export const ButtonsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const ButtonContainer = styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: 24px;
`

export const Button = styled(SharedButton)`
  flex: 1;
`

export const IconContainer = styled.View`
  position: absolute;
  top: -24px;
`
