import { createSelector } from 'reselect'
import { isBefore } from 'date-fns'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { BrokerSubscription } from '@hedgit/lib/interfaces/subscription'

import { RootState } from 'store'

const selectBrokerList = createSelector((state: RootState) => state.subscriptions.brokerList, a => a)

const selectCancelledPricingProgramsBroker = () => createSelector(
  selectBrokerList,
  (brokerList) => {
    return brokerList.reduce<BrokerSubscription[]>((accum, pp) => {
      const cancelledSubscriptions = pp.subscribers?.filter(subscriber =>
        [
          SubscriptionStatus.cancelled,
          SubscriptionStatus.rejected
        ].includes(subscriber.status)
      )
      const isNotFinished = isBefore(new Date(), new Date(pp.pricingProgram.endDate))
      if (cancelledSubscriptions?.length && isNotFinished) {
        accum.push({
          ...pp,
          subscribers: cancelledSubscriptions
        })
      }

      return accum
    }, [])
  }
)

export default selectCancelledPricingProgramsBroker
