import React from 'react'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormControl } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import {
  Container,
  Error,
  ButtonsContainer,
  SignInButtonContainer,
  InputContainer,
  Requirements,
  ErrorContainer
} from './styled'

import { phoneNumberRegex } from '../../../utils/regex/index'
import ErrorIcon from '../../icons/error'
import { theme } from '../../../theme'
import { SharedButton } from '../../shared/button'
import { SharedInput } from '../../shared/input'
import H2 from '../../typography/h2'
import Body from '../../typography/body'

export const formatPhoneNumber = (number: string) => {
  const formattedNumber = number.replace(/[^\d.]/g, '')
  return formattedNumber
}

export interface ISignInPhoneProps {
  isFetching: boolean;
  isAdmin: boolean;
  error?: string;
  onSubmit: (phoneNumber: string) => void;
  clearError?: () => void;
}

const { colors } = theme

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'SignIn'>

const SignInPhone = ({
  isAdmin,
  isFetching,
  error,
  onSubmit,
  clearError
}: ISignInPhoneProps) => {
  const navigation = useNavigation<NavigationType>()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      phoneNumber: ''
    }
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const handleSubmit = data => {
    const phone = `+549${data.phoneNumber}`
    onSubmit(phone)
  }

  const handleInputFocus = () => {
    clearError()
    clearErrors()
  }

  return (
    <Container>
      <InputContainer>
        <H2 style={{ textAlign: 'center', marginVertical: 4 }} testID="si-title-text">
          {!isAdmin ? t('SignIn.title') : t('Admin.adminTitle')}
        </H2>
        <FormControl
          isInvalid={'phoneNumber' in errors}
          style={{ marginTop: 32 }}
        >
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t('ErrorMessages.signIn.required')
              },
              pattern: {
                value: phoneNumberRegex,
                message: t('ErrorMessages.signIn.invalid')
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <SharedInput
                testID="si-phone-input"
                value={formatPhoneNumber(value)}
                withLabel
                label={t('SignIn.form.phoneNumber.placeholder')}
                placeholder="341-244563"
                keyboardType="numeric"
                maxLength={10}
                onChange={onChange}
                onFocus={handleInputFocus}
                onBlur={onBlur}
              />
            )}
          />
          <ErrorContainer>
            <FormControl.ErrorMessage
              testID="si-phone-error-message"
              leftIcon={<ErrorIcon color={colors.red} width={16} height={16} />}
              _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
            >
              {errors.phoneNumber?.message}
            </FormControl.ErrorMessage>
          </ErrorContainer>
        </FormControl>
        <Error testID="si-errors-messages">{error}</Error>
      </InputContainer>
      <Requirements style={{ width: '100%' }} testID="si-phone-requirements-text">
        {t('SignIn.requirements')}
      </Requirements>
      <ButtonsContainer>
        <SignInButtonContainer>
          <SharedButton
            testID="si-signIn-button"
            variant="primary"
            onPress={handleSubmitForm(handleSubmit)}
            disabled={isDisabled}
            loading={isFetching}
          >
            {t('SignIn.signInButton')}
          </SharedButton>
        </SignInButtonContainer>
        {!isAdmin
          ? (
            <>

              <Body style={{ textAlign: 'center' }}>{t('SignIn.account')}</Body>
              <SharedButton
                testID="si-signUp-broker-link"
                variant="secondary"
                onPress={() => navigation.navigate('SignUpBroker')}
              >
                {t('SignIn.signUpBrokerLink')}
              </SharedButton>
              <SharedButton
                testID="si-signUp-farmer-link"
                variant="secondary-dark"
                onPress={() => navigation.navigate('SignUpFarmer')}
              >
                {t('SignIn.signUpFarmerLink')}
              </SharedButton>
            </>
            )
          : null}
      </ButtonsContainer>
    </Container>
  )
}

export default SignInPhone
