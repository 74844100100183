import React, { useState } from 'react'
import { UserCredential } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { setAuthToken } from '@hedgit/lib/store/modules/auth'

import useSignInWithPhoneNumber from '@hedgit/lib/hooks/firebase/useSignInWithPhoneNumber'

import { AccessVerificationCode } from '@hedgit/lib/components/auth/access-verification-code'

import { RootState } from 'store'

import auth from 'utils/firebase'

import { Container } from './styled'

const SignInVerification = () => {
  const dispatch = useDispatch()

  const [errorCodeInvalid, setErrorCodeInvalid] = useState<string>('')
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const phone = useSelector((store: RootState) => store.auth.phone)

  const onCodeVerified = async (data: UserCredential) => {
    const token = await data.user.getIdToken(true)
    try {
      await AsyncStorage.setItem('auth_token', token)
      dispatch(setAuthToken(token))
    } catch (e) {
      console.error(e)
    }
  }

  const onCodeInvalid = (error: string) => {
    setIsFetching(false)
    setErrorCodeInvalid(error)
  }

  const { signInPhoneNumber, signInVerification } =
    useSignInWithPhoneNumber({
      auth,
      onCodeVerified,
      onCodeInvalid
    })

  return (
    <Container>
      <AccessVerificationCode
        onSubmit={code => {
          setIsFetching(true)
          signInVerification(code)
        }}
        onResendCode={() => {
          if (phone) signInPhoneNumber(phone)
        }}
        phone={`+549${phone}`}
        errorCodeInvalid={errorCodeInvalid}
        isFetching={isFetching}
      />
    </Container>
  )
}

export default SignInVerification
