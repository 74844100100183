import { ThunkAction } from '@hedgit/lib/types/redux-thunk'
import { APIResponse } from '@hedgit/lib/types/api'

import { get, patch } from '@hedgit/lib/utils/axios'

import { Notification } from '@hedgit/lib/interfaces/notifications'

import {
  getNotificationsPending,
  getNotificationsFulfilled,
  getNotificationsFailed,
  markNotificationAsSeenPending,
  markNotificationAsSeenFulfilled,
  markNotificationAsSeenFailed
} from './actions'
import { NotificationsActionTypes } from './types'

interface Response {
  notifications: Notification[];
}

export const getNotifications = (): ThunkAction<void, unknown, unknown, NotificationsActionTypes> => {
  return async (dispatch) => {
    dispatch(getNotificationsPending())
    try {
      const response = await get<APIResponse<Response>>('/notifications')
      dispatch(getNotificationsFulfilled(response.data.notifications))
    } catch (error) {
      dispatch(getNotificationsFailed(error))
    }
  }
}

interface ResponseNotification {
  notification: Notification;
}

export const markNotificationAsSeen = (
  id: string
): ThunkAction<void, unknown, unknown, NotificationsActionTypes> => {
  return async dispatch => {
    dispatch(markNotificationAsSeenPending())
    try {
      const response = await patch<APIResponse<ResponseNotification>>(
        `/notifications/${id}/actions/seen`
      )
      dispatch(markNotificationAsSeenFulfilled(response.data.notification))
    } catch (error) {
      dispatch(markNotificationAsSeenFailed(error))
    }
  }
}
