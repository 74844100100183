
/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const OkIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg width="73.876" height="80.037" viewBox="0 0 73.876 80.037">
      <Path id={'Path_34775' + id} data-name={'Path 34775' + id} d="M67.52,36.232V30.6a30.6,30.6,0,0,0-61.207,0v5.541C2.635,37.874,0,42.387,0,47.684c0,6.334,3.762,11.562,8.577,12.228A6.9,6.9,0,0,0,22.2,58.319V37.757a6.895,6.895,0,0,0-12-4.647V30.6a26.72,26.72,0,0,1,53.439,0V33.11a6.895,6.895,0,0,0-12,4.647V58.319a6.916,6.916,0,0,0,4.769,6.563v1.411a6.761,6.761,0,0,1-6.754,6.754H47.611a5.366,5.366,0,0,0-5.1-3.727H37.54a5.358,5.358,0,1,0,0,10.717h4.969a5.361,5.361,0,0,0,4.855-3.106h2.288A10.65,10.65,0,0,0,60.29,66.293v-1.3a6.922,6.922,0,0,0,5-5.218c4.811-.592,8.586-5.787,8.586-12.091,0-5.288-2.661-9.785-6.356-11.451M8.385,55.892c-2.538-.855-4.5-4.265-4.5-8.209s1.963-7.353,4.5-8.208Zm3.884-18.135a3.021,3.021,0,1,1,6.042,0V58.319a3.021,3.021,0,0,1-6.042,0Zm30.24,38.4H37.54a1.474,1.474,0,1,1,0-2.949h4.969a1.474,1.474,0,1,1,0,2.949M61.564,58.319a3.021,3.021,0,0,1-6.043,0V37.757a3.021,3.021,0,1,1,6.043,0Zm3.884-2.532V39.579c2.552.762,4.544,4.163,4.544,8.1s-1.992,7.341-4.544,8.1" fill="#313c4d" />
    </Svg>

  )
}

export default OkIcon
