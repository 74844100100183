/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const Location = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (

    <Svg id={'location' + id} width={width} height={height} viewBox="0 0 64 64" fill="none">
      <Path id={'Path_location' + id} data-name={'Path_location' + id} d="M32 2.5C21.11 2.5 12.25 11.85 12.25 23.35C12.25 34.35 30.02 59.79 30.77 60.86C30.9096 61.0561 31.0941 61.2159 31.3081 61.3262C31.522 61.4365 31.7593 61.4941 32 61.4941C32.2407 61.4941 32.478 61.4365 32.6919 61.3262C32.9059 61.2159 33.0904 61.0561 33.23 60.86C34 59.79 51.75 34.39 51.75 23.35C51.75 11.85 42.89 2.5 32 2.5ZM22.77 23.35C23.27 11.12 40.77 11.12 41.23 23.35C40.73 35.59 23.27 35.58 22.77 23.35Z" fill={color} />
    </Svg>
  )
}

export default Location
