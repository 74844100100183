/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const StatusCancelled = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (

    <Svg id={'cancel_black_24dp' + id} width={width} height={height} viewBox="0 0 22 22">
      <Path id={'Path_34751' + id} data-name={'Path 34751' + id} d="M0,0H22V22H0Z" fill="none" opacity="0.87" />
      <Path id={'Path_34752' + id} data-name={'Path 34752' + id} d="M11,2a9,9,0,1,0,9,9A8.992,8.992,0,0,0,11,2Zm0,16.2A7.2,7.2,0,1,1,18.2,11,7.21,7.21,0,0,1,11,18.2ZM14.231,6.5,11,9.731,7.769,6.5,6.5,7.769,9.731,11,6.5,14.231,7.769,15.5,11,12.269,14.231,15.5,15.5,14.231,12.269,11,15.5,7.769Z" fill={color} />
    </Svg>

  )
}

export default StatusCancelled
