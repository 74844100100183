import styled from 'styled-components/native'
import { Box } from 'native-base'

import Caption from '../../../typography/caption'
import Title from '../../../typography/title'

const addOpacity = (color: string) => color + '33'

export const PricingCardHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  height: 64px;
`

export const CardTitle = styled(Title)`
  position: relative;
`

export const CardCaption = styled(Caption)`
  position: relative;
`

export const WrapContainer = styled.View`
  margin-top: 3px;
  margin-right: 2px;
  display: flex;
  align-items: flex-start;
`

export const HeaderWrapBox = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const WrapBox = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 6px;
`

export const TonsContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
`

type IconBoxProps = {
  color: string;
}

export const IconBox = styled.View<IconBoxProps>`
  width: 32px;
  height: 32px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: ${props => addOpacity(props.color)};
`

type IconProps = {
  color: string;
}

export const Icon = styled.View<IconProps>`
  fill: ${props => props.color};
`

export const SmallIconBox = styled.View`
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
`

type BoxProp = {
  isBroker?: boolean;
}
export const BoxRN = styled(Box)<BoxProp>`
  padding-top: ${props => props.isBroker ? 5 : 0}
`
