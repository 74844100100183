// import { View } from 'native-base'
import React from 'react'
import { Path, Svg } from 'react-native-svg'

interface LineProps {
  line: string;
  color?: string;
}

export const Line = (props: Partial<LineProps>) => {
  const { line, color } = props as LineProps
  return (
    <Svg style={{ marginHorizontal: 20, marginTop: 10 }}>
      <Path key="line" d={line} stroke={color} fill="none" />
    </Svg>

  )
}
