import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import {
  Container,
  TermsAndConditionsLink,
  TermsAndConditionsText,
  ButtonContainer,
  TermsAndConditionsContainer,
  Title
} from './styled'

import { SharedButton } from '../../../shared/button'
import { ContactInformation } from '../../../shared/contact-information'
import { ContactInformationType } from '../../../shared/contact-information/contact-information'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'SignUp'>

const BrokerSignUp = () => {
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()
  return (
    <Container>
      <Title>
        {t('SignUpBroker.title')}
      </Title>
      <ContactInformation
        variant={ContactInformationType.email}
        value='soporte@hedgit.ai'
      />
      <TermsAndConditionsContainer>
        <TermsAndConditionsText>
          {t('SignUpFarmer.termsAndConditionsText')}
        </TermsAndConditionsText>
        <Button
          testID="su-broker-terms-link"
          variant="link"
          onPress={() => navigation.navigate('TermsAndConditions')}
        >
          <TermsAndConditionsLink>
            {t('SignUpFarmer.termsAndConditionsLink')}
          </TermsAndConditionsLink>
        </Button>
      </TermsAndConditionsContainer>
      <ButtonContainer>
        <SharedButton
          testID='su-broker-go-back'
          variant='primary'
          onPress={() => navigation.goBack()}
          style={{ width: '100%' }}
        >
          {t('Components.button.goBack')}
        </SharedButton>
      </ButtonContainer>
    </Container>
  )
}

export default BrokerSignUp
