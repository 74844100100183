import { action } from 'typesafe-actions'

import { PricingSignal, BrokerPricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import { PricingSignalsActionConsts, SelectedPricingProgram } from './types'
import { ExecutePricingSignalsResponse } from './thunks'

interface BrokerFarmerPricingSignals {
  signalsAsFarmer?: PricingSignal[];
  signalsAsBroker?: BrokerPricingSignal[];
}

export const getPricingSignalPending = () =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_PENDING)

export const getPricingSignalFulfilled = (pricingSignals: PricingSignal[]) =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_FULFILLED, pricingSignals)

export const getBrokerPricingSignalFulfilled = (pricingSignals: BrokerPricingSignal[]) =>
  action(PricingSignalsActionConsts.GET_BROKER_PRICING_SIGNALS_FULFILLED, pricingSignals)

export const getBrokerFarmerPricingSignalFulfilled = (data: BrokerFarmerPricingSignals) =>
  action(PricingSignalsActionConsts.GET_BROKER_FARMER_PRICING_SIGNALS_FULFILLED, data)

export const getPricingSignalFailed = (error: string) =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_FAILED, error)

export const executePricingSignalsPending = () =>
  action(PricingSignalsActionConsts.EXECUTE_PRICING_SIGNALS_PENDING)

export const executePricingSignalsFulfilled = (pricingProgram: ExecutePricingSignalsResponse) =>
  action(PricingSignalsActionConsts.EXECUTE_PRICING_SIGNALS_FULFILLED, pricingProgram)

export const executePricingSignalsFailed = (error: string) =>
  action(PricingSignalsActionConsts.EXECUTE_PRICING_SIGNALS_FAILED, error)

export const setSelectedPricingProgram = (selectedPricingProgram: SelectedPricingProgram) =>
  action(PricingSignalsActionConsts.SET_SELECTED_PRICING_PROGRAM, selectedPricingProgram)

export const clearSelectedPricingProgram = () =>
  action(PricingSignalsActionConsts.CLEAR_SELECTED_PRICING_PROGRAM)

export const getPricingSignalsByPricingProgramPending = () =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_PENDING)

interface PricingSignalsParams {
  pricingProgramId: string;
  signals: PricingSignal[];
}

export const getPricingSignalsByPricingProgramFulfilled = (pricingSignals: PricingSignalsParams) =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FULFILLED, pricingSignals)

export const getPricingSignalsByPricingProgramFailed = (error: string) =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_PRICING_PROGRAM_FAILED, error)

export const getPricingSignalsBySubscriptionPending = () =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_SUBSCRIPTION_PENDING)

interface PricingSignalsBySubscriptionParams {
  subscriptionId: string;
  signals: PricingSignal[];
}

export const getPricingSignalsBySubscriptionFulfilled = (pricingSignals: PricingSignalsBySubscriptionParams) =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FULFILLED, pricingSignals)

export const getPricingSignalsBySubscriptionFailed = (error: string) =>
  action(PricingSignalsActionConsts.GET_PRICING_SIGNALS_BY_SUBSCRIPTION_FAILED, error)
