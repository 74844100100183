import React from 'react'
import { Modal as RNModal, TouchableOpacity, GestureResponderEvent } from 'react-native'
import { useTranslation } from 'react-i18next'

import {
  CenteredView,
  ModalView,
  UserIcon,
  UserName,
  SubscriptionDate,
  XiconContainer,
  ButtonContainer,
  ButtonsContainer,
  Button,
  ButtonText,
  ConfirmationContent
} from './styled'

import { ContactInformation } from '../../shared/contact-information'
import { ContactInformationType } from '../../shared/contact-information/contact-information'
import XCloseIcon from '../../icons/x-close-icon'
import { SubscriptionStatus } from '../../../enums/subscription-status'

interface IButton {
  label: string;
  onPress: (event: GestureResponderEvent) => void;
  testID: string;
  variant: 'primary' | 'secondary';
}
export interface ModalProps {
  userName?: string;
  visible?: boolean;
  onCloseModal?: () => void;
  body?: string;
  email?: string;
  phone?: string;
  onPress: (event: GestureResponderEvent) => void;
  firstLetter: string;
  secondLetter: string;
  buttons?: IButton[];
  status: SubscriptionStatus;
}

const UserModal = ({
  userName,
  visible,
  body,
  phone,
  email,
  firstLetter,
  secondLetter,
  onPress,
  onCloseModal,
  buttons,
  status
}: ModalProps) => {
  const { t } = useTranslation()
  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView>
        <ModalView>
          <UserIcon>
            {firstLetter}
            {secondLetter}
          </UserIcon>
          <UserName>{userName}</UserName>
          <SubscriptionDate>{body}</SubscriptionDate>
          <ContactInformation
            variant={ContactInformationType.phone}
            value={phone}
          />
          <ContactInformation
            variant={ContactInformationType.email}
            value={email}
          />
          <XiconContainer>
            <TouchableOpacity
              testID='x-close-userModal-icon'
              onPress={onPress}
            >
              <XCloseIcon />
            </TouchableOpacity>
          </XiconContainer>
          {
            status === SubscriptionStatus.pending
              ? (
                <ConfirmationContent>
                  <ButtonText>
                    {t('Components.card.newPPText')}
                  </ButtonText>
                  <ButtonsContainer>
                    {
                buttons.map((button) => (
                  <ButtonContainer>
                    <Button
                      testID={button.testID}
                      variant={button.variant}
                      onPress={button.onPress}
                    >
                      {button.label}
                    </Button>
                  </ButtonContainer>
                )
                )
              }
                  </ButtonsContainer>
                </ConfirmationContent>
                )
              : null
          }
          {
            status === SubscriptionStatus.pendingCancellation
              ? (
                <ConfirmationContent>
                  <ButtonText>
                    {t('Components.card.cancelPPText')}
                  </ButtonText>
                  <ButtonsContainer>
                    {
                buttons.map((button) => (
                  <ButtonContainer>
                    <Button
                      testID={button.testID}
                      variant={button.variant}
                      onPress={button.onPress}
                    >
                      {button.label}
                    </Button>
                  </ButtonContainer>
                )
                )
              }
                  </ButtonsContainer>
                </ConfirmationContent>
                )
              : null
          }
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default UserModal
