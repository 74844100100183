import React, { useCallback, useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'
import { useToast } from 'react-native-toast-notifications'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer, getFocusedRouteNameFromRoute } from '@react-navigation/native'
import type { HeaderTitleProps } from '@react-navigation/elements'
import { useTranslation } from 'react-i18next'
import type { RouteProp } from '@react-navigation/native'
import { theme } from '@hedgit/lib/theme'
import { APIResponse } from '@hedgit/lib/types/api'

import { toggleSidebar } from '@hedgit/lib/store/modules/ui'

import { GetMonthName } from '@hedgit/lib/utils/format'
import { get } from '@hedgit/lib/utils/axios'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { SideBar } from '@hedgit/lib/components/sidebar'
import H2 from '@hedgit/lib/components/typography/h2'
import QuickShare from '@hedgit/lib/components/icons/quick-share'
import { HelpModal } from '@hedgit/lib/components/modals/help-modal'

import { useDispatch, useSelector } from 'store'

import { Notifications } from 'routes/notifications'
import { AlgorithmDetails } from 'routes/algorithms/details'
import { AlgorithmSubscription } from 'routes/algorithms/subscription'
import { PricingProgramDetails } from 'routes/pricing-programs/details'
import { Subscribers } from 'routes/pricing-programs/subscribers'
import Support from 'routes/support'
import { TutorialsList } from 'routes/tutorials/list'
import { TutorialsDetails } from 'routes/tutorials/details'
import { NavigationTabs } from 'routes/navigation-tabs'

import { HeaderLeftButton } from '../../components/header-left-button'
import { HeaderRightButton } from '../../components/header-right-button'
import { Settings } from '../settings'

type NavigationRouteProp = RouteProp<RootStackParamList, 'NavigationTabs'>;
type NavigationPricingProgramDetailsRouteProp = RouteProp<RootStackParamList, 'PricingProgramDetails'>;
type NavigationAlgorithmDetailsRouteProp = RouteProp<RootStackParamList, 'AlgorithmDetails'>;
type NavigationAlgorithmSubscriptionRouteProp = RouteProp<RootStackParamList, 'AlgorithmSubscription'>;

interface Response {
  imgUrl: string;
}

const Stack = createStackNavigator()

const HeaderTitleStackScreen = (props: HeaderTitleProps) => (
  <H2>{props.children}</H2>
)

const HeaderTitleTabScreen = (route: string) => (
  <H2>{route}</H2>
)

const HeaderTitlePricingProgramDetailsScreen = (route: NavigationPricingProgramDetailsRouteProp) => {
  const crop = route.params.crop
  const month = GetMonthName(route.params.month)
  const year = route.params.year
  return (
    <H2>{`${crop} ${month} ${year}`}</H2>
  )
}

const HeaderTitleAlgorithmDetailsScreen = (route: NavigationAlgorithmDetailsRouteProp) => {
  const algorithm = route.params.name
  return (
    <H2>{`${algorithm}`}</H2>
  )
}

const HeaderTitleAlgorithmSubscriptionScreen = (route: NavigationAlgorithmSubscriptionRouteProp) => {
  const algorithm = route.params.algorithmName
  return (
    <H2>{`${algorithm}`}</H2>
  )
}

const GetHeaderTitle = (route: NavigationRouteProp) => {
  const { t } = useTranslation()

  const routeName = getFocusedRouteNameFromRoute(route) ?? 'Pricing Program'

  switch (routeName) {
    case 'PricingPrograms':
      return t('NavigationTabs.pricingPrograms')
    case 'Algorithms':
      return t('NavigationTabs.algorithms')
    case 'PricingSignals':
      return t('NavigationTabs.signals')
    default:
      return t('NavigationTabs.pricingPrograms')
  }
}

const AppNavigator = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const sidebarOpen = useSelector(state => state.ui.sidebarOpen)
  const [modalProgramDetailVisible, setModalProgramDetailVisible] = useState(false)

  const dispatch = useDispatch()

  const closeSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [dispatch])

  const copyToClipboard = async (algorithmId: string) => {
    try {
      const url = `/files/algorithm-screenshot/${algorithmId}`

      const res = await get<APIResponse<Response>>(`${url}`)
      const data = res.data.imgUrl
      toast.show('Copiada al portapapeles', {
        type: 'success',
        successColor: 'gray',
        placement: 'bottom',
        duration: 4000,
        animationType: 'slide-in'
      })
      return Clipboard.setString(data)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <View style={{ height: '100%' }}>
      <NavigationContainer>
        <SideBar open={sidebarOpen} onLinkPressed={closeSidebar} />
        <Stack.Navigator screenOptions={{
          headerTitleAlign: 'center',
          headerTitle: HeaderTitleStackScreen,
          headerShadowVisible: false,
          headerLeft: HeaderLeftButton,
          headerRight: HeaderRightButton
        }}
        >
          <Stack.Screen
            name="NavigationTabs"
            component={NavigationTabs}
            options={({ route }) => ({
              headerTitle: () => HeaderTitleTabScreen(GetHeaderTitle(route as NavigationRouteProp))
            })}
          />
          <Stack.Screen
            name="Notifications"
            component={Notifications}
            options={() => ({
              // eslint-disable-next-line max-len
              headerTitle: () => HeaderTitleTabScreen(t('NavigationStack.notifications'))
            })}
          />
          <Stack.Screen
            name="AlgorithmDetails"
            component={AlgorithmDetails}
            options={({ route }) => ({
              // eslint-disable-next-line max-len
              headerTitle: () => HeaderTitleAlgorithmDetailsScreen(route as NavigationAlgorithmDetailsRouteProp),
              // eslint-disable-next-line react/no-unstable-nested-components
              headerRight: () => (
                <TouchableOpacity
                  onPress={() => copyToClipboard((route.params as RootStackParamList['AlgorithmDetails'])?.id)}
                >
                  <QuickShare
                    testID='alg-farmer-quickshare-icon'
                    color={theme.colors.dark}
                  />

                </TouchableOpacity>
              ),
              headerRightContainerStyle: {
                paddingRight: 16
              }
            })}
          />
          <Stack.Screen
            name="AlgorithmSubscription"
            component={AlgorithmSubscription}
            options={({ route }) => ({
              // eslint-disable-next-line max-len
              headerTitle: () => HeaderTitleAlgorithmSubscriptionScreen(route as NavigationAlgorithmSubscriptionRouteProp)
            })}
          />
          <Stack.Screen
            name="PricingProgramDetails"
            component={PricingProgramDetails}
            options={({ route }) => ({
              // eslint-disable-next-line max-len
              headerTitle: () => HeaderTitlePricingProgramDetailsScreen(route as NavigationPricingProgramDetailsRouteProp)
            })}
          />
          <Stack.Screen
            name="PricingProgramSubscribers"
            component={Subscribers}
            options={() => ({
              // eslint-disable-next-line max-len
              headerTitle: () => HeaderTitleTabScreen(t('Subscribers.title'))
            })}
          />
          <Stack.Screen
            name="Settings"
            component={Settings}
            options={{
              headerShown: false
            }}
          />
          <Stack.Screen
            name="Support"
            component={Support}
            options={{
              headerShown: false
            }}
          />
          <Stack.Screen
            name="TutorialsList"
            component={TutorialsList}
            options={{
              headerTitle: () => HeaderTitleTabScreen(t('Tutorials.headerTitle'))
            }}
          />
          <Stack.Screen
            name="TutorialsDetails"
            component={TutorialsDetails}
            options={{ headerShown: false }}
          />
        </Stack.Navigator>
        <HelpModal
          title={t('Components.modal.help.subscribe.title')}
          body={t('Components.modal.help.subscribe.body')}
          visible={modalProgramDetailVisible}
          buttons={[
            {
              label: t('Components.modal.button.ok'),
              onPress: () => setModalProgramDetailVisible(false),
              testID: 'help-modal-button',
              variant: 'primary'
            }
          ]}
        />
      </NavigationContainer>
    </View>
  )
}

export default AppNavigator
