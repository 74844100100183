import React from 'react'
import { Circle, Svg, Text } from 'react-native-svg'
import type { CircleProps } from 'react-native-svg'

interface DecoratorProps {
  x: (arg: number) => number;
  y: (arg: number) => number;
  signals: (number | undefined)[];
  colorDots: string;
  colorLabel: string;
}

interface CircleSvgProps extends CircleProps {
  style: Record<string, string>;
}

const CircleSvg = (props: CircleSvgProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Circle {...props} />
}

export const Dots = (props: Partial<DecoratorProps>) => {
  const { x, y, colorDots, colorLabel, signals } = props as DecoratorProps

  const shadow = { filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .16))' }
  return (
    <Svg>
      {signals?.map((value, index) => value && (
        // eslint-disable-next-line react/no-array-index-key
        <Svg key={index}>
          <Text
            x={x(index)}
            y={y(value) - 12}
            fontWeight="600"
            fill={colorLabel}
            fontSize="10"
            fontFamily='lato'
            textAnchor='middle'
          >
            {value}
          </Text>
          <CircleSvg
            key={`${x(index) - y(index)}`}
            cx={x(index)}
            cy={y(value)}
            r={4.5}
            fill={colorDots}
            style={shadow}
          />
        </Svg>
      ))}
    </Svg>
  )
}
