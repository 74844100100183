import React, { useCallback } from 'react'
import { TouchableOpacity, Image } from 'react-native'
import { slide as Menu } from 'react-burger-menu'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { View } from 'native-base'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDispatch, useSelector, RootState } from '@hedgit/web/src/store'
import { useTranslation } from 'react-i18next'

import { signOutThunk } from '@hedgit/lib/store/modules/auth'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import './styled.css'

import NotificationsBellIcon from '@hedgit/lib/components/icons/notifications-bell'
import Camera from '@hedgit/lib/components/icons/camera'
import Settings from '@hedgit/lib/components/icons/settings'
import Question from '@hedgit/lib/components/icons/question'
import Logout from '@hedgit/lib/components/icons/logout'
import BadgeIcon from '@hedgit/lib/components/icons/badge'

import {
  Title,
  LinksContainer,
  LineContent,
  IconContainer,
  LogOutBox,
  Header,
  SubTitle,
  GradientContainer,
  HeaderBack,
  LogoutButton,
  BadgeContainer,
  HedgitLogoBox
} from './styled'
import { BgGradient } from './components/bg-gradient'

import ButtonText from '../typography/button-text'
import { getRoleTranslation } from '../../translations/getTranslation'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'NavigationTabs'>

interface Props {
  open: boolean;
  onLinkPressed: () => void;
}

interface NavLink {
  testID: string;
  label: string;
  icon: React.ReactNode;
  page: keyof RootStackParamList;
}

const ResponsiveSideBar = ({ open, onLinkPressed }: Props) => {
  const navigation = useNavigation<NavigationType>()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userFirstName = useSelector(state => state.auth.currentUser?.firstName)
  const userLastName = useSelector(state => state.auth.currentUser?.lastName)
  const role = useSelector((store: RootState) => store.auth.currentUser?.role)
  const list = useSelector((state) => state.notifications.list)
  const notReadNotifications = list.filter(item => item.isRead === false)
  const numberOfNonReadNotifications = notReadNotifications.length

  const signOutUser = useCallback(async () => {
    AsyncStorage.removeItem('auth_token')
    dispatch(signOutThunk())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateTo = (page: keyof RootStackParamList) => {
    navigation.navigate(page)
    onLinkPressed()
  }

  const handleClose = () => {
    onLinkPressed()
  }

  const userFullName = userFirstName + ' ' + userLastName

  const links: NavLink[] = [
    {
      testID: 'sidebar-notification-button',
      label: t('SideBar.notifications'),
      icon: <NotificationsBellIcon color="#AEB1B7" />,
      page: 'Notifications'
    },
    {
      testID: 'sidebar-tutorials-button',
      label: t('SideBar.tutorials'),
      icon: <Camera color="#AEB1B7" width={22} />,
      page: 'TutorialsList'
    },
    {
      testID: 'sidebar-settings-button',
      label: t('SideBar.settings'),
      icon: <Settings color="#AEB1B7" />,
      page: 'Settings'
    },
    {
      testID: 'sidebar-support-button',
      label: t('SideBar.support'),
      icon: <Question color="#AEB1B7" />,
      page: 'Support'
    }
  ]

  return (
    <Menu
      pageWrapId="page-wrap"
      width='281px'
      isOpen={open}
      noOverlay={!open}
      customBurgerIcon={false}
      customCrossIcon={false}
      onClose={handleClose}
    >
      <View style={{ height: '100%' }}>
        <Header>
          <GradientContainer>
            <BgGradient />
          </GradientContainer>
          <HeaderBack style={{ transform: 'translate(-50%, -51%)' } as unknown} />
          <Title testID='sb-user-name-title'>
            {userFullName}
          </Title>
          <SubTitle>
            {getRoleTranslation(role)}
          </SubTitle>
        </Header>
        <LinksContainer>
          {
              links.map((link) => (
                <TouchableOpacity
                  key={link.label}
                  testID={link.testID}
                  onPress={() => {
                    navigateTo(link.page)
                  }}
                >
                  <LineContent>
                    <BadgeContainer>
                      {numberOfNonReadNotifications !== 0 && link.label === 'Notifications'
                        ? <BadgeIcon number={numberOfNonReadNotifications} />
                        : null}
                    </BadgeContainer>
                    <IconContainer>
                      {link.icon}
                    </IconContainer>
                    <ButtonText>
                      {link.label}
                    </ButtonText>
                  </LineContent>
                </TouchableOpacity>
              )
              )
            }
        </LinksContainer>

        <LogOutBox>
          <LogoutButton
            testID='sidebar-logout-button'
            onPress={signOutUser}
          >
            <IconContainer>
              <Logout color="#AEB1B7" width={24} height={24} />
            </IconContainer>
            <ButtonText>{t('SideBar.logOut')}</ButtonText>
          </LogoutButton>
          <HedgitLogoBox>
            <Image
              style={{ width: '50px', height: '50px' }}
              source={require('@hedgit/lib/components/sidebar/assets/icons/hedgit-icon-sm.png')}
            />
          </HedgitLogoBox>
        </LogOutBox>

      </View>
    </Menu>
  )
}

export default ResponsiveSideBar
