/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Defs, Rect, ClipPath, G } from 'react-native-svg'
import type { StyleProp, ViewStyle } from 'react-native'

import useId from '../../../../hooks/useId'

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const SidebarBack = ({ style }: Props) => {
  const id = useId('sidebar-back')

  return (
    <Svg viewBox="0 0 223.898 305.275" style={style}>
      <Defs>
        <ClipPath id={'clip-path' + id}>
          <Path id="Path_34743" data-name="Path 34743" d="M0-889.251H223.9v-305.275H0Z" transform="translate(0 1194.526)" fill="none" />
        </ClipPath>
        <ClipPath id={'clip-path-2' + id}>
          <Rect id="Rectangle_3629" data-name="Rectangle 3629" width="187.723" height="307.32" fill="none" />
        </ClipPath>
      </Defs>
      <G id={'Group_8351' + id} data-name={'Group 8351' + id} clipPath={`url(#clip-path${id})`}>
        <G id={'Group_8346' + id} data-name={'Group 8346' + id} transform="translate(-2.3 -0.257)" opacity="0.2">
          <G id={'Group_8345' + id} data-name={'Group 8345' + id}>
            <G id={'Group_8344' + id} data-name={'Group 8344' + id} clipPath={`url(#clip-path-2${id})`}>
              <G id={'Group_8343' + id} data-name={'Group 8343' + id} transform="translate(2.3 0.256)">
                <Path id={'Path_34741' + id} data-name={'Path 34741' + id} d="M-520.005-400.053l-90.966-137.336H-698.52l90.966,137.336a27.71,27.71,0,0,1,0,30.6L-698.52-232.114h87.549l90.966-137.336a27.71,27.71,0,0,0,0-30.6" transform="translate(698.52 537.389)" fill="#33a3d8" />
              </G>
            </G>
          </G>
        </G>
        <G id={'Group_8350' + id} data-name={'Group 8350' + id} transform="translate(38.475 -0.257)" opacity="0.1">
          <G id={'Group_8349' + id} data-name={'Group 8349' + id}>
            <G id={'Group_8348' + id} data-name={'Group 8348' + id} clipPath="url(#clip-path-2)">
              <G id={'Group_8347' + id} data-name={'Group 8347' + id} transform="translate(2.3 0.256)">
                <Path id={'Path_34742' + id} data-name={'Path 34742' + id} d="M-520.005-400.053l-90.966-137.336H-698.52l90.966,137.336a27.71,27.71,0,0,1,0,30.6L-698.52-232.114h87.549l90.966-137.336a27.71,27.71,0,0,0,0-30.6" transform="translate(698.52 537.389)" fill="#33a3d8" />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}
