import styled from 'styled-components/native'

import Body from '../../typography/body'

export const Container = styled.View`
  align-items: center;
  width: 100%;
  margin-top: 10px;
`

export const IconContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #D6EDF7;
  z-index: 2;
`

export const IconLineContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  position: relative;
`

export const IconLine = styled.View`
  background-color: #DAECF6;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const Label = styled(Body)`
  color: ${props => props.theme.colors.gray};
  padding: 6px 0;
  text-align: center;
`
