/* eslint-disable max-len */
import React from 'react'
import { Svg, G, Rect, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const OkIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg width="48" height="48" viewBox="0 0 48 48">
      <G id={'Group_8447' + id} data-name={'Group 8447' + id} transform="translate(-16 -208)">
        <Rect id={'Rectangle_3572' + id} data-name={'Rectangle 3572' + id} width="48" height="48" rx="24" transform="translate(16 208)" fill="#d6edf7" />
      </G>
      <G>
        <Path id={'done_FILL0_wght400_GRAD0_opsz48' + id} d="M18.9,35.7,7.7,24.5l2.15-2.15L18.9,31.4,38.1,12.2l2.15,2.15Z" transform="translate(0 2)" fill="#33a3d9" />
      </G>
    </Svg>

  )
}

export default OkIcon
