import { useState, useMemo } from 'react'
import { isBefore, isAfter, isEqual, subYears, isSameDay } from 'date-fns'
import { subMonths } from 'date-fns/esm'

import { HistoricalDataFilter } from '@hedgit/lib/enums/filters'

import { useSelector } from 'store'

export const useFilteredMarketData = (id: string) => {
  const farmerList = useSelector((state) => state.subscriptions.farmerList)
  const brokerList = useSelector((state) => state.subscriptions.brokerList)
  const [filter, setFilter] = useState<HistoricalDataFilter>(HistoricalDataFilter['1M'])

  const selectedSubscription = farmerList.find(
    subscription => subscription.id === id
  ) || brokerList.find(
    subscription => subscription.pricingProgramId === id
  )

  const marketData = useSelector(
    state =>
      state.marketData.listByPricingProgram[
        selectedSubscription?.pricingProgram.id || ''
      ] || []
  )

  const today = new Date()
  const ppEndDate = new Date(selectedSubscription?.pricingProgram.endDate || '')
  const endDate = isBefore(today, ppEndDate) ? today : ppEndDate

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = () => {
    if (!selectedSubscription) return new Date()

    let date: Date

    if (filter === HistoricalDataFilter['1M']) {
      date = subMonths(endDate, 1)
    } else if (filter === HistoricalDataFilter['3M']) {
      date = subMonths(endDate, 3)
    } else if (filter === HistoricalDataFilter['6M']) {
      date = subMonths(endDate, 6)
    } else if (filter === HistoricalDataFilter['12M']) {
      date = subYears(endDate, 1)
    } else {
      date = new Date(selectedSubscription?.pricingProgram.startDate)
    }
    return date
  }

  const filteredMarketData = useMemo(() => {
    if (!selectedSubscription) return []

    return marketData.filter(mD => {
      const valueDate = new Date(mD.date)
      const start = startDate()

      const isEqualOrAfter = isEqual(valueDate, start) || isAfter(valueDate, start)
      const isEqualOrBefore = isEqual(valueDate, endDate) || isBefore(valueDate, endDate)

      return isEqualOrAfter && isEqualOrBefore
    })
  }, [marketData, selectedSubscription, startDate, endDate])

  const ppStartMarketData = useMemo(() => {
    if (selectedSubscription?.pricingProgram.startDate) {
      const index = filteredMarketData.findIndex(
        (mD) => {
          return isSameDay(new Date(mD.date), new Date(selectedSubscription?.pricingProgram.startDate))
        }
      )
      if (index !== -1) {
        return {
          ...filteredMarketData[index],
          index
        }
      }
    }
  }
  , [filteredMarketData, selectedSubscription])

  return {
    setFilter,
    filter,
    filteredMarketData,
    startDate,
    endDate,
    ppStartMarketData
  }
}
