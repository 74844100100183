import React from 'react'
import { Text as TextRN, View } from 'react-native'
import { Svg } from 'react-native-svg'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'

import Line from './components/line'
import Circle from './components/circle'

interface ProgressBarProps {
  status: string;
  color: string;
  xMax: number;
  currentDay: number;
  initLabel: string;
  endLabel: string;
  entityType: 'pricing-program' | 'pricing-signal';
}

export const CancelledProgressBar = ({
  status,
  color,
  xMax,
  currentDay,
  initLabel,
  endLabel,
  entityType
}: ProgressBarProps) => {
  const { t } = useTranslation()

  const xCurrentDatePct = Math.min((currentDay / xMax) * 100, 100)

  return (
    <>
      <Svg style={{ height: 10, overflow: 'visible', zIndex: 2 }}>
        <Line
          pct={100}
          color={color}
        />
        {status !== 'pricingprogramscancelledtab' && (
          <Circle pct={xCurrentDatePct} color={color} />
        )}
      </Svg>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 26,
          justifyContent: 'space-between',
          borderColor: theme.colors.lightGray,
          borderStyle: 'dotted',
          borderTopWidth: 1.5,
          paddingTop: 13
        }}
      >
        <TextRN style={{ color: theme.colors.dark, fontSize: 10, fontFamily: 'lato' }}>{initLabel}</TextRN>
        {entityType === 'pricing-program' && (
          <TextRN style={{ color: `${color}`, fontSize: 10 }}>
            {t('Components.progressBar.cancelled').toUpperCase()}
          </TextRN>
        )}
        <TextRN style={{ color: theme.colors.dark, fontSize: 10, fontFamily: 'lato' }}>{endLabel}</TextRN>
      </View>
    </>
  )
}
