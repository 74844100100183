/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import useId from '../../hooks/useId'

const FaqIcon = () => {
  const id = useId('ps-icon')

  return (
    <Svg
      x="0px"
      y="0px"
      width="27.774px"
      height="27.774px"
      viewBox="0 0 27.774 27.774"
      // xmlSpace="preserve"
      // enableBackground="new 0 0 27.774 27.774"
    >
      <Path
        id={'Path_3445' + id}
        data-name={'Path_3445' + id}
        d="M10.398 22.811h4.618v4.964h-4.618v-4.964zm10.66-21.217C19.854.532 17.612 0 14.33 0c-3.711 0-6.205.514-7.482 1.543-1.277 1.027-1.916 3.027-1.916 6l-.021 1.008h4.577l-.02-1.049c0-1.424.303-2.377.907-2.854.604-.477 1.814-.717 3.632-.717 1.936 0 3.184.228 3.74.676.559.451.837 1.457.837 3.017 0 1.883-.745 3.133-2.237 3.752l-1.797.766c-1.882.781-3.044 1.538-3.489 2.27-.442.732-.665 2.242-.665 4.529h4.68v-.646c0-1.41.987-2.533 2.965-3.365 2.03-.861 3.343-1.746 3.935-2.651.592-.908.888-2.498.888-4.771-.001-2.883-.603-4.853-1.806-5.914z"
        fill="#33a3d9"
        stroke="#33a3d9"
        strokeWidth="0.7"
      />
    </Svg>
  )
}
export default FaqIcon
