import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components/native'
import { ToastProvider } from 'react-native-toast-notifications'
import { Provider } from 'react-redux'
import { NativeBaseProvider } from 'native-base'
import { theme } from '@hedgit/lib/theme'

import { store } from './store'
import Navigator from './routes'

const App = () => {
  useEffect(() => {
    console.log('ENV', process.env.ENV_NAME)
  }, [])
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <NativeBaseProvider theme={theme}>
          <ToastProvider>
            <Navigator />
          </ToastProvider>
        </NativeBaseProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
