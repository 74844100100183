import React from 'react'
import { Svg, Text, Line } from 'react-native-svg'
import { format, subBusinessDays } from 'date-fns'
import { setLanguageLocale } from '@hedgit/lib/translations/getTranslation'
import { theme } from '@hedgit/lib/theme'

interface DecoratorProps {
  x: (arg: number) => number;
  y: (arg: number) => number;
  data: number[];
  xMax: number;
  startDate: Date;
}

const getXAxisData = (data: number[], xMax: number) => {
  const diff = Math.max(data.length, xMax || 0)

  const distance = diff / 4

  const indexes = [0, 1, 2, 3, 4].map((v) => Math.round(v * distance))
  return indexes
}

export const XAxis = (props: Partial<DecoratorProps>) => {
  const { x, data, xMax } = props as DecoratorProps

  const { colors } = theme

  const distances = getXAxisData(data, xMax)

  const absoluteY = 9

  const opacity = 0.7

  const today = new Date()

  return (
    <Svg>
      <Line
        x1={x(-100)}
        y1={absoluteY - 8}
        x2={x(Math.max(data.length, xMax || 0) + 100)}
        y2={absoluteY - 8}
        stroke={colors.gray}
        strokeWidth="1"
        opacity={opacity}
      />
      {
        distances.map((val, index) => {
          const contrary = distances[distances.length - index - 1]

          const formattedMonth = format(
            subBusinessDays(today, contrary), 'MMM', { locale: setLanguageLocale() }
          )
          const displayMonth = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)

          const day = format(subBusinessDays(today, contrary), 'd')

          const value = index === distances.length - 1 ? val - 1 : val

          return (
            <>
              <Text
                x={x(value)}
                y={absoluteY}
                fontWeight="300"
                fill={colors.gray}
                fontSize="10"
                fontFamily='lato'
                textAnchor='middle'
                opacity={opacity}
              >
                |
              </Text>
              <Text
                x={x(value)}
                y={absoluteY + 14}
                fontWeight="300"
                fill={colors.gray}
                fontSize="10"
                fontFamily='lato'
                textAnchor='middle'
                opacity={opacity}
              >
                {displayMonth}
              </Text>
              <Text
                x={x(value)}
                y={absoluteY + 26}
                fontWeight="300"
                fill={colors.gray}
                fontSize="10"
                fontFamily='lato'
                textAnchor='middle'
                opacity={opacity}
              >
                {day}
              </Text>
            </>
          )
        })
      }

    </Svg>
  )
}
