
import styled from 'styled-components/native'

import Caption from '@hedgit/lib/components/typography/caption'
import H2 from '@hedgit/lib/components/typography/h2'

export const ChartContent = styled.View`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 220;
  height: 172;
`

export const CircleContent = styled.View`
  margin-top: 14px;
  align-items: center;
  width: 206;
  height: 113;
`

export const ChartTextContent = styled.View`
  text-align: center;
  color: #313C4D;
  width: 244px;
  padding-left: 4px;
  padding-top: 12px;
`

export const LegendText = styled(Caption)`
  color: #838A94;
  line-height: 19.5px;
`

export const LegendHistoricalText = styled(H2)`
  font-size: 8;
  color: ${props => props.theme.colors.blue};
  padding-left: 3px;
`

type LegendProps = {
  iconColor: string;
}

export const LegendHistoricalDot = styled.View<LegendProps>`
  height: 8px;
  background-color: ${props => props.iconColor};
  width: 8px;
  border-radius: 100;
`

export const LegendHistoricalContainer = styled.View`
  position: absolute;
  margin-bottom: 8px;
  margin-left: 78%;
  height: 26px;
  justify-content: space-between;
`
