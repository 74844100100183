/* eslint-disable max-len */
import React from 'react'
import { Svg, Line } from 'react-native-svg'

const XCloseIcon = () => {
  return (
    <Svg fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24">
      <Line x1="18" x2="6" y1="6" y2="18" />
      <Line x1="6" x2="18" y1="6" y2="18" />
    </Svg>
  )
}

export default XCloseIcon
