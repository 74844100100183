import React from 'react'
import { View } from 'native-base'
import { ImageBackground } from 'react-native'
import { useTranslation } from 'react-i18next'

import {
  Container,
  SubContainer,
  Gradient,
  DescriptionContainer,
  IconBackground,
  IconContainer,
  SubIconContainer,
  DurationContainer
} from './styled'

import AlgorithmIcon from '../../icons/algorithm'
import Title from '../../typography/title'
import Body from '../../typography/body'
import { Tutorial, TutorialReference } from '../../../interfaces/tutorial'
import PricingProgramIcon from '../../icons/pricing-program'
import PricingSignalIcon from '../../icons/pricing-signal'
import InterfaceIcon from '../../icons/interface'
import { theme } from '../../../theme'
import { getTranslation } from '../../../translations/getTranslation'

interface TutorialCardProps {
  tutorial: Tutorial;
}

export type TutorialReferenceProps = {
  reference: TutorialReference;
}

interface IconProps {
  reference: TutorialReferenceProps['reference'];
}

const Icon = ({ reference }: IconProps) => {
  if (reference === TutorialReference.algorithm) return <AlgorithmIcon color={colors.white} />
  if (reference === TutorialReference.pricingProgram) return <PricingProgramIcon color={colors.white} />
  if (reference === TutorialReference.pricingSignal) return <PricingSignalIcon color={colors.white} />
  if (reference === TutorialReference.interface) return <InterfaceIcon color={colors.white} />
  return null
}

const { colors } = theme

const TutorialCard = ({ tutorial }: TutorialCardProps) => {
  const { t } = useTranslation()
  return (
    <ImageBackground
      source={{
        uri: tutorial.posterUrl
      }}
      resizeMode='cover'
      style={{ borderRadius: 10, overflow: 'hidden' }}
    >
      <Gradient />
      <Container>
        <SubContainer>
          <DescriptionContainer>
            <IconContainer>
              <SubIconContainer>
                <Icon reference={tutorial.reference} />
              </SubIconContainer>
              <IconBackground />
            </IconContainer>
            <View>
              <Title style={{ color: colors.white }}>{getTranslation(tutorial.title)}</Title>
              <Body style={{ color: colors.white }}>{getTranslation(tutorial.shortDescription)}</Body>
            </View>
          </DescriptionContainer>
          <DurationContainer>
            <Body style={{ color: colors.skyBlue }}>{`${tutorial.duration} ${t('Components.card.minutes')}`}</Body>
          </DurationContainer>
        </SubContainer>
      </Container>
    </ImageBackground>
  )
}

export default TutorialCard
