import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import { PricingProgramStatus } from '../../../../enums/pricing-program-status'

const addOpacity = (color: string) => color + '80'

export const LabelContainer = styled.View`
  display: flex;
  flex-direction: row;
  height: 12;
  justify-content: space-between;
  padding-top: 190;
  position: absolute;
  width: 100%;
  z-index: 10;
`

export const getGradientColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return addOpacity(theme.colors.blueGray)

  if (status === PricingProgramStatus.executed) return addOpacity(theme.colors.greenLight)

  if (status === PricingProgramStatus.cancelled) return addOpacity(theme.colors.blueGrayLight)

  return theme.colors.blueGray
}

export const getDotsAndLineColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.active) return theme.colors.dark

  if (status === PricingProgramStatus.executed) return theme.colors.green

  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getSignalsPriceColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}

export const getMonthLabelColor = (status: PricingProgramStatus) => {
  if (status === PricingProgramStatus.cancelled) return theme.colors.gray

  return theme.colors.dark
}
