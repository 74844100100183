import React, { useState } from 'react'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { FormControl, View } from 'native-base'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Container,
  SubContainer,
  ButtonContainer,
  RequirementsContainer,
  TextErrorCodeInvalid,
  Requirements,
  RequirementsBold,
  InputContainer,
  StyledInput,
  InputWrapper
} from './styled'

import { numberRegex } from '../../../utils/regex/index'
import { theme } from '../../../theme'
import { SharedButton } from '../../shared/button'
import ErrorIcon from '../../icons/error'
import useSafeArea from '../../../hooks/use-safe-area'
import yup from '../../../utils/yup'

interface IAccessVerificationCode {
  onSubmit: (code: string) => void;
  onResendCode?: () => void;
  phone?: string;
  errorCodeInvalid?: string;
  isFetching?: boolean;
}

export const formatPhoneNumber = (number: string) => {
  const formattedNumber = number.replace(/[^\d.]/g, '')
  return formattedNumber
}

const { colors } = theme

const AccessVerificationCode = (props: IAccessVerificationCode) => {
  const { onSubmit, onResendCode, phone, errorCodeInvalid, isFetching } = props
  const { t } = useTranslation()
  const { bottom } = useSafeArea()

  const validationVerificationCodeSchema = yup.object({
    verificationCode: yup.string()
      .required(t('ErrorMessages.verificationCode.required'))
      .min(6, t('ErrorMessages.verificationCode.min'))
      .matches(numberRegex, t('ErrorMessages.verificationCode.matches'))
  })

  const [showMessage, setShowMessage] = useState<boolean>(false)
  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors,
    watch
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      verificationCode: ''
    },
    resolver: yupResolver(validationVerificationCodeSchema)
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const handleSubmit = (data) => {
    onSubmit(data.verificationCode)
  }

  const verificationCode = watch('verificationCode')

  const handleKeyPress = (keyValue, onChange) => {
    const allowed = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    if (keyValue === 'Backspace') {
      if (verificationCode.length === 0) {
        onChange(verificationCode)
      } else {
        onChange(verificationCode.slice(0, verificationCode.length - 1))
      }
    } else if (allowed.includes(parseInt(keyValue))) {
      if (verificationCode.length < 6) {
        onChange(`${verificationCode}${keyValue}`)
      }
    }
  }

  return (
    <Container>
      <SubContainer>
        <InputWrapper>
          <InputContainer>
            <FormControl isInvalid={'verificationCode' in errors}>
              <Controller
                name="verificationCode"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <StyledInput
                    testID="av-code-input"
                    value={formatPhoneNumber(value)}
                    onKeyPress={(e) => handleKeyPress(e.nativeEvent.key, onChange)}
                    onFocus={() => clearErrors('verificationCode')}
                    onBlur={onBlur}
                    keyboardType="numeric"
                    maxLength={6}
                  />
                )}
              />
              <View
                style={{ flexDirection: 'row', marginLeft: 44, marginTop: 0 }}
              >
                {[0, 1, 2, 3, 4, 5].map((v, i) => (
                  <View
                    key={v}
                    style={{
                      width: 32,
                      height: 2,
                      marginLeft: i !== 0 ? 8 : 0,
                      backgroundColor: verificationCode.length === i ? colors.primary : colors.lightGray
                    }}
                  />
                ))}
              </View>
              <FormControl.ErrorMessage
                testID="av-error-message"
                leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
                style={{ marginTop: 15, marginLeft: 20 }}
              >
                {errors.verificationCode?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </InputContainer>
          <RequirementsContainer>
            <Requirements testID="av-requirements-text">
              {t('SignInVerification.requirements.firstPart')}
              <RequirementsBold>
                {t('SignInVerification.requirements.code')}
              </RequirementsBold>
              {t('SignInVerification.requirements.secondPart')}
              <RequirementsBold>{phone}</RequirementsBold>
              {t('SignInVerification.requirements.thirdPart')}
            </Requirements>
            {showMessage && (
            <Requirements testID="av-resend-text">
              {t('SignInVerification.resend')}
              <RequirementsBold>{phone}</RequirementsBold>
              {' '}
            </Requirements>
            )}
            <TextErrorCodeInvalid testID="av-invalidCode-error-message">
              {errorCodeInvalid}
            </TextErrorCodeInvalid>
          </RequirementsContainer>
        </InputWrapper>
        <ButtonContainer style={{ marginBottom: bottom }}>
          <SharedButton
            testID="av-submit-button"
            variant="primary"
            onPress={handleSubmitForm(handleSubmit)}
            disabled={isDisabled}
            loading={isFetching}
          >
            {t('SignInVerification.submitButton')}
          </SharedButton>
          <SharedButton
            testID="av-resendCode-link"
            variant="secondary"
            onPress={() => {
              setShowMessage(true)
              onResendCode()
            }}
          >
            {t('SignInVerification.resendCodeButton')}
          </SharedButton>
        </ButtonContainer>
      </SubContainer>
    </Container>
  )
}

export default AccessVerificationCode
