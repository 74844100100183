import React from 'react'
import { Checkbox } from 'native-base'

import { theme } from '../../../theme'

export type CheckboxProps = {
  testID?: string;
  value?: string;
  accessibilityLabel?: string;
  onChange?: (value: boolean) => void;
}

const { colors } = theme

const SharedCheckbox: React.FC<CheckboxProps> = ({
  testID,
  value,
  accessibilityLabel,
  onChange
}: CheckboxProps) => {
  return (
    <Checkbox
      testID={testID}
      value={value}
      accessibilityLabel={accessibilityLabel}
      onChange={onChange}
      size='md'
      _icon={{
        color: colors.primary
      }}
      _checked={{
        value: 'true',
        borderColor: colors.primary
      }}
      _invalid={{
        value: 'false',
        borderColor: colors.red,
        backgroundColor: colors.redFaded
      }}
    />
  )
}

export default SharedCheckbox
