import React from 'react'
import { useTranslation } from 'react-i18next'

import { UnavailableProductCard } from '@hedgit/lib/components/cards/unavailable-product-card/unavailable-product-card'

import { Content, SubContent } from './styled'

const AlgorithmsWheatTab = () => {
  const { t } = useTranslation()

  return (
    <Content>
      <SubContent>
        <UnavailableProductCard
          text={t('Algorithm.tab.wheat.text')}
          generalText={(t('Algorithm.tab.wheat.general'))}
        />
      </SubContent>
    </Content>
  )
}

export default AlgorithmsWheatTab
