import { useMemo } from 'react'
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult
} from 'firebase/auth'

import { SignInWithPhoneNumberOptions } from './types'

import i18n from '../../translations/i18n'

let confirmationResult: ConfirmationResult
let recaptchaVerifier: RecaptchaVerifier

const useSignInWithPhoneNumber = (options: SignInWithPhoneNumberOptions) => {
  const { auth, onCodeVerified, onVerificationCodeSent, onCodeInvalid } = options

  const signInPhoneNumber = async (phoneNumber: string) => {
    if (recaptchaVerifier) {
      recaptchaVerifier.render()
    } else {
      recaptchaVerifier = new RecaptchaVerifier('recaptcha-verifier', {
        size: 'invisible'
      }, auth)
    }

    const phone = phoneNumber

    try {
      const result = await signInWithPhoneNumber(auth, phone, recaptchaVerifier)
      if (!result) throw new Error()
      confirmationResult = result
      onVerificationCodeSent(phone)
    } catch (error) {
      return error
    }
  }

  const clearRecaptcha = () => {
    if (recaptchaVerifier) {
      recaptchaVerifier.clear()
      recaptchaVerifier = null
    }
  }

  const signInVerification = async (
    code: string
  ) => {
    try {
      if (confirmationResult) {
        const result = await confirmationResult.confirm(code)
        if (!result) throw new Error()
        onCodeVerified(result)
      }
    } catch (error) {
      return onCodeInvalid(i18n.t('ErrorMessages.verificationCode.invalid'))
    }
  }

  const res = {
    signInPhoneNumber,
    signInVerification,
    clearRecaptcha
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => res, [])
}

export default useSignInWithPhoneNumber
