import { createSelector } from 'reselect'

import { RootState } from 'store'

const selectBrokerList = createSelector((state: RootState) => state.pricingSignals.brokerList, a => a)

const selectExecutedPricingSignalsBroker = () => createSelector(
  selectBrokerList,
  (brokerList) => brokerList.filter(pp => pp.executionPrice)
)

export default selectExecutedPricingSignalsBroker
