export enum TutorialReference {
  algorithm = 'algorithm',
  pricingProgram = 'pricingProgram',
  pricingSignal = 'pricingSignal',
  interface = 'interface'
}

export interface Tutorial {
  id: string;
  title: {
    en: string;
    es: string;
  };
  description: {
    en: string;
    es: string;
  };
  shortDescription: {
    en: string;
    es: string;
  };
  duration: number;
  reference: TutorialReference;
  src: string;
  posterUrl: string;
}
