import { action } from 'typesafe-actions'

import { Algorithm } from '@hedgit/lib/interfaces/algorithm'

import { AlgorithmsActionConsts } from './types'

export const getAlgorithmsPending = () =>
  action(AlgorithmsActionConsts.GET_ALGORITHMS_PENDING)

export const getAlgorithmsFulfilled = (algorithms: Algorithm[]) =>
  action(AlgorithmsActionConsts.GET_ALGORITHMS_FULFILLED, algorithms)

export const getAlgorithmsFailed = (error: string) =>
  action(AlgorithmsActionConsts.GET_ALGORITHMS_FAILED, error)
