import React, { useCallback, useEffect } from 'react'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { getTranslation } from '@hedgit/lib/translations/getTranslation'
import { useTranslation } from 'react-i18next'

import { getAlgorithms } from '@hedgit/lib/store/modules/algorithms/thunks'

import { Entity } from '@hedgit/lib/enums/entity'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { PricingProgram } from '@hedgit/lib/interfaces/pricing-program'

import { AlgorithmCard } from '@hedgit/lib/components/cards/algorithm-card'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'

import useMediaQueries from 'hooks/use-media-queries'

import { Content, SubContent, CardClickArea } from './styled'

import { useDispatch, useSelector } from '../../../store'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'AlgorithmDetails'>

const AlgorithmsList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationType>()
  const { columns } = useMediaQueries()

  const list = useSelector((state) => state.pricingPrograms.list)

  useEffect(() => {
    dispatch(getAlgorithms())
  }, [dispatch])

  const renderCard: ListRenderItem<PricingProgram> = useCallback(
    (pricingProgram: ListRenderItemInfo<PricingProgram>) => {
      return (
        <CardClickArea
          onPress={() => {
            navigation.navigate('AlgorithmDetails', {
              id: pricingProgram.item.id,
              name: getTranslation(pricingProgram.item.algorithm.name) || ''
            })
          }}
        >
          <AlgorithmCard pricingProgram={pricingProgram.item} />
        </CardClickArea>
      )
    },
    [navigation]
  )

  return (
    <Content>
      <SubContent>
        <FlatList
          key={`flatListWith-${columns}-Columns`}
          numColumns={columns}
          testID='al-algorithms-flatList'
          data={list}
          renderItem={renderCard}
          keyExtractor={data => data.id}
          style={{ width: '100%' }}
          contentContainerStyle={{ alignItems: 'center' }}
          ListEmptyComponent={<EmptyCard text={t('Empty.algorithms')} entity={Entity.pricingSignal} />}
        />
      </SubContent>
    </Content>
  )
}

export default AlgorithmsList
