import React, { useState, useMemo } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { AreaChart } from 'react-native-svg-charts'
import { Defs, LinearGradient, Stop } from 'react-native-svg'
import * as shape from 'd3-shape'
import { theme } from '@hedgit/lib/theme'
import { differenceInBusinessDays, subYears, subMonths } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { HelpModal } from '@hedgit/lib/components/modals/help-modal'

import {
  getLineColor,
  getDotsColor,
  getGradientColor,
  getSignalsPriceColor,
  DataContainer,
  H2Title,
  IconWrapper,
  CurrentDayBox,
  ButtonWrapper,
  NoDataText
} from './styled'
import { Dots } from './dots'
import { StartLine } from './start-line'
import { XAxis } from './x-axis'

import { Line } from '../../components/line'
import { FilterButtons } from '../../components/filter-button'
import { PricingProgramStatus } from '../../../../enums/pricing-program-status'
import { ChartTitle, ChartContainer, ChartHeader } from '../styled'
import Question from '../../../icons/question'
import ArrowIcon from '../../../icons/arrow'
import { HistoricalDataFilter } from '../../../../enums/filters'
import { MarketDataSummary } from '../../../../store/modules/market-data'

interface AreaChartExampleProps {
  data: number[];
  signals?: (number | null)[];
  state: PricingProgramStatus;
  mDLastValue: number;
  mDPreviousValue: number;
  onFilter: (filter: HistoricalDataFilter) => void;
  filter: HistoricalDataFilter;
  startDate: Date;
  endDate: Date;
  totalDataLength: number;
  ppStartMarketData: MarketDataSummary & { index: number } | undefined;
}

interface GradientProps {
  state: PricingProgramStatus;
  min: number;
  max: number;
}

const Gradients = ({
  state,
  min,
  max
}: GradientProps) => {
  const gradientColor = getGradientColor(state as PricingProgramStatus)

  const modifier = 0.28
  const gradientPct = (max - min) * modifier

  return (
    <Defs key="marketDataChart">
      <LinearGradient id="marketDataChart" x1="0%" y1="0%" x2="0%" y2="100%">
        <Stop offset="0%" stopColor={gradientColor} stopOpacity={0.8} />
        <Stop offset={`${gradientPct}%`} stopColor={gradientColor} stopOpacity={0} />
      </LinearGradient>
    </Defs>
  )
}

function getStandardDeviation (array) {
  const n = array.length
  const mean = array.reduce((a, b) => a + b) / n
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

const getMinMax = (data, ppLength: number) => {
  if (!data.length) return { min: 0, max: 0 }

  const standardDeviation = getStandardDeviation(data)

  const extrapolatedDeviation = Math.sqrt(Math.pow(standardDeviation, 2) * Math.abs(ppLength))

  const lastPrice = data[data.length - 1]

  const min = lastPrice - extrapolatedDeviation
  const max = lastPrice + extrapolatedDeviation

  return { min, max }
}

const getXmax = (filter: HistoricalDataFilter) => {
  const today = new Date()
  if (filter === HistoricalDataFilter['12M']) return differenceInBusinessDays(today, subYears(today, 1))
  if (filter === HistoricalDataFilter['6M']) return differenceInBusinessDays(today, subMonths(today, 6))
  if (filter === HistoricalDataFilter['3M']) return differenceInBusinessDays(today, subMonths(today, 3))
  if (filter === HistoricalDataFilter['1M']) return differenceInBusinessDays(today, subMonths(today, 1))
  return undefined
}

const HistoricalPricesPricingProgramChart = ({
  data,
  signals,
  state,
  mDLastValue,
  mDPreviousValue,
  onFilter,
  filter,
  startDate,
  endDate,
  totalDataLength,
  ppStartMarketData
}: AreaChartExampleProps) => {
  const { t } = useTranslation()
  const [modalHistoricalPricesVisible, setModalHistoricalPricesVisible] = useState(false)

  const lineColor = getLineColor(state as PricingProgramStatus)
  const dotsColor = getDotsColor(state as PricingProgramStatus)
  const signalsPriceColor = getSignalsPriceColor(state as PricingProgramStatus)

  const currentValue = mDLastValue
  const previousValue = mDPreviousValue

  const difference = currentValue - previousValue

  const differenceColor = difference < 0 ? theme.colors.red : theme.colors.green

  const changePct = (100 - ((previousValue / currentValue) * 100))

  const xMax = useMemo(() => getXmax(filter), [filter])

  const { min: marketDataMin, max: marketDataMax } = getMinMax(data, differenceInBusinessDays(startDate, endDate))

  const min = Math.min(marketDataMin, ...signals.filter(s => s))
  const max = Math.max(marketDataMax, ...signals.filter(s => s))

  return (
    <ChartContainer>
      <ChartHeader>
        <ChartTitle>
          {t('Components.charts.titles.chartView')}
        </ChartTitle>
        <TouchableOpacity
          onPress={() => setModalHistoricalPricesVisible(true)}
        >
          <Question color={theme.colors.lightGray} width={24} height={24} />
        </TouchableOpacity>
      </ChartHeader>
      <DataContainer>
        <H2Title>
          {t('Components.charts.usd').toUpperCase()}
          {' '}
          {mDLastValue || '-'}
        </H2Title>
        { state === PricingProgramStatus.active && !isNaN(changePct)
          ? (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              paddingLeft: 3,
              marginLeft: 4
            }}
            >
              <Text style={{ color: differenceColor }}>
                {difference > 0 && '+'}
                {`${difference.toFixed(2)} (${!isNaN(changePct) ? changePct.toFixed(2) : 0}%)`}
              </Text>
              <IconWrapper>
                {
                difference !== 0 && (
                <ArrowIcon
                  color={differenceColor}
                  style={{
                    alignItems: 'baseline',
                    transform: `rotate(${(mDLastValue - mDPreviousValue) > 0 ? '180deg' : '0'}) translateY(2px)`
                  }}
                />
                )
                }
                <CurrentDayBox color={differenceColor}>{t('Components.charts.today')}</CurrentDayBox>
              </IconWrapper>
            </View>
            )
          : null}

      </DataContainer>
      {
        data.length === 0
          ? (
            <NoDataText>
              <Text>{t('Components.charts.noData')}</Text>
            </NoDataText>
            )
          : (
            <View
              style={{
                width: '100%',
                paddingBottom: 5
              }}
            >
              <AreaChart
                style={{
                  height: 260,
                  overflow: 'visible',
                  backgroundColor: theme.colors.white,
                  zIndex: 2
                }}
                yMin={min}
                yMax={max}
                xMax={xMax}
                data={data}
                contentInset={{ top: 20, bottom: 70, left: 10, right: 10 }}
                curve={shape.curveLinear}
                svg={{ fill: 'url(#marketDataChart)' }}
              >
                <Line color={lineColor} />
                <StartLine ppStartMarketData={ppStartMarketData} />
                <Dots colorDots={dotsColor} colorLabel={signalsPriceColor} signals={signals} />
                <Gradients state={state} min={min} max={max} />
              </AreaChart>
              <AreaChart
                style={{
                  height: 50,
                  overflow: 'visible',
                  backgroundColor: theme.colors.white,
                  zIndex: 2
                }}
                yMin={min}
                yMax={max}
                xMax={xMax}
                data={data}
                contentInset={{ left: 10, right: 10 }}
              >
                <XAxis data={data} xMax={xMax} startDate={startDate} />
              </AreaChart>
            </View>
            )
      }
      <ButtonWrapper>
        <FilterButtons onChange={onFilter} selected={filter} dataLength={totalDataLength} />
      </ButtonWrapper>
      <HelpModal
        title={t('Components.modal.pricingProgramChart.historical.title')}
        body={t('Components.modal.pricingProgramChart.historical.body')}
        visible={modalHistoricalPricesVisible}
        buttons={[
          {
            label: t('Components.modal.button.ok'),
            onPress: () => setModalHistoricalPricesVisible(false),
            testID: 'help-modal-button',
            variant: 'primary'
          }
        ]}
      />
    </ChartContainer>
  )
}

export default HistoricalPricesPricingProgramChart
