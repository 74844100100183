/* eslint-disable max-len */
import React from 'react'
import { Svg, Text, G, TSpan, Circle } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const ChartCircleIcon = ({
  text,
  color,
  shadowed,
  width,
  height,
  cx,
  cy,
  r,
  r2,
  fontS,
  tx,
  ty,
  angle,
  dx,
  dy,
  data
}: IconProps) => {
  const id = useId('ps-icon')

  const shadow = { filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .16))' }

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      style={{ overflow: 'visible' }}
    >
      <G
        id={'Group_8336' + id}
        data-name={'Group 8336' + id}
        transform={`rotate(${angle || 0}, 0, 0)`}
        transform-origin="50% 50%"
      >
        <Circle
          id={'Ellipse_386' + id}
          data-name={'Ellipse 386' + id}
          cx={cx}
          cy={cy}
          r={r2}
          fill="#FFF"
          style={shadowed ? shadow : {}}
        />
        <Circle
          id={'Ellipse_385' + id}
          data-name={'Ellipse 385' + id}
          cx={cx}
          cy={cy}
          r={r}
          fill={color}
        />
        <G id={'Component_32_15' + id} data-name={'Component_32_15' + id}>
          <Text
            id={'_3' + id}
            data-name="3"
            transform={`translate(${tx || 0} ${ty || 0})`}
            fill="#fff"
            fontSize={fontS}
            fontFamily="Lato"
            fontWeight="700"
          >
            <TSpan x="0" y="0">
              {text}
            </TSpan>
          </Text>

          <Text
            id={'_4' + id}
            data-name="4"
            transform={`translate(${dx || 0} ${dy || 0})`}
            fontSize={fontS}
            fontFamily="Lato"
            fontWeight="700"
          >
            <TSpan x="0" y="0" stroke="#FFF" strokeWidth={6}>
              {data}
            </TSpan>
            <TSpan x="0" y="0" fill={color} strokeWidth={1}>
              {data}
            </TSpan>
          </Text>
        </G>
      </G>
    </Svg>
  )
}

export default ChartCircleIcon
