import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const Interface = ({ color }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20">
      <Path
        id={'Path_34770' + id}
        data-name={'Path 34770' + id}
        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15H11V11h2Zm0-8H11V7h2Z"
        transform="translate(-2 -2)"
        fill={color}
      />
    </Svg>
  )
}

export default Interface
