import { createSelector } from 'reselect'
import { isAfter } from 'date-fns'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { RootState } from 'store'

const selectFarmerList = (state: RootState) => state.subscriptions.farmerList

const selectExecutedPricingProgramsFarmer = () => createSelector(
  selectFarmerList,
  (farmerList) => {
    return farmerList.filter(pp => {
      const activeSubscriptions =
        pp.status === SubscriptionStatus.active
      const isNotFinished =
        isAfter(new Date(), new Date(pp.pricingProgram.endDate))
      return activeSubscriptions && isNotFinished
    }
    )
  })

export default selectExecutedPricingProgramsFarmer
