
/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

const PencilIcon = ({ color, width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Path d="M0,0H24V24H0Z" fill="none" />
      <Path d="M14.06,9.02l.92.92L5.92,19H5v-.92l9.06-9.06M17.66,3a1,1,0,0,0-.7.29L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41L18.37,3.29A.982.982,0,0,0,17.66,3Zm-3.6,3.19L3,17.25V21H6.75L17.81,9.94,14.06,6.19Z" fill={color} />
    </Svg>
  )
}

export default PencilIcon
