import { ActionType } from 'typesafe-actions'

import { Algorithm } from '@hedgit/lib/interfaces/algorithm'

import * as actions from './actions'
import { getAlgorithms } from './thunks'

export enum AlgorithmsActionConsts {
  GET_ALGORITHMS_PENDING='GET_ALGORITHMS_PENDING',
  GET_ALGORITHMS_FULFILLED='GET_ALGORITHMS_FULFILLED',
  GET_ALGORITHMS_FAILED='GET_ALGORITHMS_FAILED',
}

export type AlgorithmsActionTypes = ActionType<typeof actions>
export type AlgorithmsThunksTypes = typeof getAlgorithms

export interface AlgorithmsState {
  readonly list: Algorithm[];
  readonly isFetching: boolean;
  readonly error: string;
}
