import React from 'react'
import { TouchableOpacity, GestureResponderEvent, ActivityIndicator } from 'react-native'
import type { StyleProp, ViewStyle } from 'react-native'

import { Container, StyledButtonText } from './styled'

import { theme } from '../../../theme'

const { colors } = theme

export type ButtonProps = {
  testID?: string;
  variant?: 'primary' | 'secondary' | 'secondary-dark';
  children?: React.ReactNode | string;
  disabled?: boolean;
  loading?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
}

export const SharedButton: React.FC<ButtonProps> = ({
  testID,
  variant = 'primary',
  children,
  disabled = false,
  loading = false,
  onPress,
  style = {}
}: ButtonProps) => {
  const isChildrenString = typeof children === 'string'

  return (
    <TouchableOpacity
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      style={[style]}
    >
      <Container
        variant={variant}
        disabled={disabled}
      >
        {
          loading
            ? (
              <ActivityIndicator
                color={
                  variant === 'primary' ? colors.white : variant === 'secondary' ? colors.primary : colors.dark
                }
              />
              )
            : isChildrenString
              ? (
                <StyledButtonText variant={variant}>
                  {children}
                </StyledButtonText>
                )
              : (
                  children
                )
        }
      </Container>
    </TouchableOpacity>
  )
}
