import React from 'react'
import { ScrollView } from 'react-native'
import omit from 'lodash.omit'

import { updateFarmer, updateBroker } from '@hedgit/lib/store/modules/auth/thunks'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { User } from '@hedgit/lib/interfaces/user'
import { Farmer } from '@hedgit/lib/interfaces/farmer'
import { Broker } from '@hedgit/lib/interfaces/broker'

import { Settings } from '@hedgit/lib/components/settings'

import { useDispatch, useSelector } from 'store'

import { Content } from './styled'

const SettingsScreen = () => {
  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.auth.currentUser)
  const role = useSelector(state => state.auth.currentUser?.role)
  const isUpdatingUser = useSelector(state => state.auth.isUpdatingUser)
  const error = useSelector(state => state.auth.error)

  const handleSubmit = (data: User) => {
    if (!currentUser) return
    if (role === UserRole.broker) {
      dispatch(updateBroker({ ...data, role, id: currentUser.id } as Broker))
    } else {
      const user = omit(data, 'billingInformation')
      dispatch(updateFarmer({ ...user, role, id: currentUser.id } as Farmer))
    }
  }

  return (
    <Content>
      <ScrollView style={{ padding: 16 }}>
        {
          (currentUser && role)
            ? (
              <Settings
                role={role}
                currentUser={currentUser}
                onSubmit={handleSubmit}
                isFetching={isUpdatingUser}
                error={error}
              />
              )
            : null
}
      </ScrollView>
    </Content>
  )
}

export default SettingsScreen
