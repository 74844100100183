/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Defs, Rect, ClipPath, G } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
}

const PricingSignalIcon = ({ color }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg width="19.697" height="19.684" viewBox="0 0 19.697 19.684">
      <Defs>
        <ClipPath id={'clip-path' + id}>
          <Rect id={'Rectangle_3574' + id} data-name={'Rectangle 3574' + id} width="19.697" height="19.684" fill={color} />
        </ClipPath>
      </Defs>
      <G id={'Group_8107' + id} data-name={'Group 8107' + id} clipPath="url(#clip-path)">
        <Path id={'Path_34647' + id} data-name={'Path 34647' + id} d="M0,8.068c.05-.347.085-.7.151-1.04A8.473,8.473,0,0,1,3.54,1.65,8.134,8.134,0,0,1,7.7.044a8.441,8.441,0,0,1,7.374,2.867A8.318,8.318,0,0,1,17.14,7.338a8.585,8.585,0,0,1-.031,2.737.74.74,0,0,1-.034.1,5.693,5.693,0,0,0-2.214-.24,6.315,6.315,0,0,0-1.793-5.874,6.1,6.1,0,0,0-4.51-1.822A6.376,6.376,0,1,0,9.945,14.851a5.713,5.713,0,0,0,.248,2.227c-.23.034-.449.077-.669.1a8.5,8.5,0,0,1-7.358-2.866A8.334,8.334,0,0,1,.091,9.864C.055,9.625.03,9.385,0,9.145V8.068" transform="translate(0 0)" fill={color} />
        <Path id={'Path_34648' + id} data-name={'Path 34648' + id} d="M219.524,224.086c-.281-.061-.566-.1-.841-.184a4.3,4.3,0,1,1,1.758.149,1.257,1.257,0,0,0-.148.035Zm.95-6.952h-1.13v3.408h1.13Zm0,4.16h-1.125v1.135h1.125Z" transform="translate(-204.521 -204.403)" fill={color} />
        <Path id={'Path_34649' + id} data-name={'Path 34649' + id} d="M66.129,71.257a5.217,5.217,0,0,1,10.383-.838,5.206,5.206,0,0,1-.151,2.168.3.3,0,0,1-.2.226,5.447,5.447,0,0,0-3.141,3.138.289.289,0,0,1-.206.2,5.225,5.225,0,0,1-6.626-4.265c-.033-.247-.048-.5-.06-.625m7.25-1.284a1.626,1.626,0,0,0,0-.179,1.727,1.727,0,0,0-1.291-1.487c-.14-.035-.171-.09-.165-.219.01-.2,0-.392,0-.589H70.773v.757l-.161.041A1.732,1.732,0,0,0,69.3,70.043a1.7,1.7,0,0,0,1.434,1.638,8.412,8.412,0,0,0,.939.037.579.579,0,0,1,0,1.157c-.167.006-.333,0-.5,0-.457,0-.605-.109-.732-.541H69.3a1.768,1.768,0,0,0,1.488,1.7v.745h1.142c0-.2.008-.4,0-.586-.007-.131.029-.182.167-.216A1.716,1.716,0,0,0,73.4,72.231a1.7,1.7,0,0,0-1.44-1.632,8.563,8.563,0,0,0-.939-.036.576.576,0,0,1-.563-.587.568.568,0,0,1,.559-.569c.224-.008.449-.008.673,0a.553.553,0,0,1,.515.394c.02.058.035.118.052.172Z" transform="translate(-62.731 -62.534)" fill={color} />
      </G>
    </Svg>
  )
}

export default PricingSignalIcon
