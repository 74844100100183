import React from 'react'
import { Circle as CircleSvg } from 'react-native-svg'
import { theme } from '@hedgit/lib/theme'
const OFFSET_Y = 10

interface Props {
  pct: number;
  color: string;
}

const Line = ({ pct, color }: Props) => {
  return (
    <CircleSvg
      cx={`${pct}%`}
      cy={OFFSET_Y}
      r={3}
      stroke={color}
      strokeWidth="3"
      fill={theme.colors.white}
    />
  )
}

export default Line
