import React from 'react'
import { useRoute, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { theme } from '@hedgit/lib/theme'
import { Pressable } from 'native-base'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import NotificationsBellIcon from '@hedgit/lib/components/icons/notifications-bell'
import BadgeIcon from '@hedgit/lib/components/icons/badge'

import { useSelector } from 'store'

import { BadgeContainer } from './styled'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'Brokers'>

const HeaderRightButton = () => {
  const navigation = useNavigation<NavigationType>()

  const route = useRoute()

  const list = useSelector((state) => state.notifications.list)
  const notReadNotifications = list.filter(item => !item.isRead)
  const numberOfNonReadNotifications = notReadNotifications.length

  if (route.name === 'NavigationTabs') {
    return (
      <Pressable
        testID='nav-bar-notification-bell'
        onPress={() => navigation.navigate('Notifications')}
        style={{ padding: 16 }}
      >
        <BadgeContainer>
          {numberOfNonReadNotifications !== 0
            ? (
              <BadgeIcon number={numberOfNonReadNotifications} />
              )
            : null}
        </BadgeContainer>
        <NotificationsBellIcon width={24} height={24} color={theme.colors.dark} />
      </Pressable>
    )
  }

  return null
}

export default HeaderRightButton
