/* eslint-disable max-len */
import React from 'react'
import { Svg, Circle, G, Text, TSpan } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const BadgeIcon = ({ number }: IconProps) => {
  const id = useId('ps-icon')
  let size = 12
  let tx = '0'
  let ty = '12.3'

  if (number >= 10) {
    size = 9
    tx = '-2'
    ty = '11'
  }

  return (
    <Svg width="16" height="16" viewBox="0 0 16 16">
      <G id={'Group_8336' + id} data-name={'Group 8336' + id} transform="translate(-1185.506 -104.506)">
        <Circle id={'Ellipse_385' + id} data-name={'Ellipse 385' + id} cx="8" cy="8" r="8" transform="translate(1185.506 104.506)" fill="#33a3d9" />
        <G id={'Component_32_15' + id} data-name={'Component_32_15' + id} transform="translate(1190.006 104.506)">
          <Text id={'_3' + id} data-name={'3' + id} transform={`translate(${tx || 0} ${ty || 0})`} fill="#fff" fontSize={size} fontFamily="Raleway-Bold, Raleway" fontWeight="700"><TSpan x="0" y="0">{number}</TSpan></Text>
        </G>
      </G>
    </Svg>
  )
}

export default BadgeIcon
