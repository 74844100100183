import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import { PricingProgramStatus } from '../../enums/pricing-program-status'
import { PricingSignalStatus } from '../../enums/pricing-signal-status'
import { SubscriptionStatus } from '../../enums/subscription-status'
import Body from '../typography/body'
import DateText from '../typography/date-text'
import Caption from '../typography/caption'

export const getPSStatusColor = (signalStatus: PricingSignalStatus, programStatus?: PricingProgramStatus) => {
  if (
    programStatus === PricingProgramStatus.executed &&
    signalStatus === PricingSignalStatus.executed
  ) return theme.colors.green

  if (
    programStatus === PricingProgramStatus.active &&
    signalStatus === PricingSignalStatus.executed
  ) return theme.colors.lightBlue

  if (signalStatus === PricingSignalStatus.pending) return theme.colors.yellowDark

  return theme.colors.lightBlue
}

export const getPPStatusBorder = (state: SubscriptionStatus) => {
  if (state === SubscriptionStatus.pendingCancellation) return theme.colors.red

  if (state === SubscriptionStatus.pending) return theme.colors.yellowDark

  if (state === SubscriptionStatus.active || SubscriptionStatus.cancelled) return theme.colors.gray
}

export const getPPStatusBorderWidth = (state: SubscriptionStatus) => {
  if (state === SubscriptionStatus.pendingCancellation) return '2px'

  if (state === SubscriptionStatus.pending) return '2px'

  if (state === SubscriptionStatus.active || SubscriptionStatus.cancelled) return '0px'
}

export const getPPStatusColor = (state: PricingProgramStatus) => {
  if (state === PricingProgramStatus.active) return theme.colors.dark

  if (state === PricingProgramStatus.executed) return theme.colors.green

  if (state === PricingProgramStatus.cancelled) return theme.colors.red

  return theme.colors.dark
}

export const getPSDarkerColor = (signalStatus: PricingSignalStatus, programStatus?: PricingProgramStatus) => {
  if (
    programStatus === PricingProgramStatus.executed &&
    signalStatus === PricingSignalStatus.executed
  ) return theme.colors.greenDarker

  if (
    signalStatus === PricingSignalStatus.executed
  ) return theme.colors.lightBlueDarker

  if (signalStatus === PricingSignalStatus.pending) return theme.colors.yellowDarker

  return theme.colors.lightBlue
}

type PricingCardProps = {
  state: SubscriptionStatus;
}

export const PricingCard = styled.View<PricingCardProps>`
  filter: drop-shadow(0 3px 6px #0000001A);
  border-color: ${props => getPPStatusBorder(props.state)};
  border-width: ${props => getPPStatusBorderWidth(props.state)};
  border-radius: 10px;
  background-color: ${theme.colors.white};
  margin-bottom: 6px;
  margin-top: 6px;
`

export const CardBodyTitle = styled(Body)`
  font-weight: 700;
  color: ${props => props.theme.colors.whiteDark};
`

export const CardBodyTitleSmall = styled(Caption)`
  font-weight: 700;
  color: ${props => props.theme.colors.whiteDark};
`

export const CardBodyText = styled(DateText)`
  color: ${props => props.theme.colors.whiteDark};
`

export const PricingCardContent = styled.View`
`

type PPCardProps = {
  state: PricingProgramStatus;
}

export const PPContentContainer = styled.View<PPCardProps>`
  background-color: ${props => getPPStatusColor(props.state)};
  display: flex;
  flex-direction: row;
`

type PSCardProps = {
  signalStatus: PricingSignalStatus;
  programStatus?: PricingProgramStatus;
}

export const PSContentContainer = styled.View<PSCardProps>`
  background-color: ${props => getPSStatusColor(props.signalStatus, props.programStatus)};
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const PPContentDoubles = styled.View`
  display: flex;
  width: 49%;
  align-items: center;
  justify-content: center;
  height: 56px;
`

export const PSContentDoubles = styled(PPContentDoubles)`
  width: 33.3%;
`

export const PricingCardContentBox = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

type PricingCardContentSimpleProps = {
  signalStatus: PricingSignalStatus;
  programStatus?: PricingProgramStatus;
}

export const PricingCardContentSimple = styled.View<PricingCardContentSimpleProps>`
  background-color: ${props => getPSDarkerColor(props.signalStatus, props.programStatus)};
  width: 33.3%;
  justify-content: center;
  align-items: center;
`
