import { Reducer } from 'redux'

import { CropsActionTypes, CropsState, CropsActionConsts } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: CropsState = {
  list: [],
  isFetching: false,
  error: ''
}

export const cropsReducer: Reducer<CropsState, CropsActionTypes | SignOutActionType> = (
  state = initialState,
  action
): CropsState => {
  switch (action.type) {
    case CropsActionConsts.GET_CROPS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case CropsActionConsts.GET_CROPS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case CropsActionConsts.GET_CROPS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
