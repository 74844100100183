import styled from 'styled-components/native'

import H2 from '@hedgit/lib/components/typography/h2'
import Body from '@hedgit/lib/components/typography/body'

import { SharedButton } from '../../shared/button'

export const CenteredView = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #00000052;
  padding-top: 74px;
`

export const ModalView = styled.View`
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  width: 92%;
  padding-top: 36px;
  padding-bottom: 20px;
  max-width: 500px;

`

export const UserIcon = styled.View`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-size: 43px;
  color: ${props => props.theme.colors.white};
  align-self: center;
  padding-bottom: 8px;
  margin-left: -3px;
`

export const UserName = styled(H2)`
  font-size: 24;
  align-self: center;
  padding-bottom: 9px;
  padding-top: 5px;
`

export const SubscriptionDate = styled(Body)`
  align-self: center;
  padding-bottom: 18px;
`

export const XiconContainer = styled.View`
  position: absolute;
  right: 12px;
  top: 12px;
`

export const ButtonsContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
  gap: 16px;
`

export const ButtonContainer = styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: 24px;
`

export const Button = styled(SharedButton)`
  flex: 1;
`

export const ButtonText = styled.Text`
  text-align: center;
  width: 100%;
  padding-top: 10px;
`

export const ConfirmationContent = styled.View`
  justify-content: center;
  align-self: center;
  width: 90%;
`
