import { useMemo, useState } from 'react'
import { useMedia } from 'react-use'

const useMediaQueries = () => {
  const [columns, setColumns] = useState(1)
  const isMobile = useMedia('(max-width: 600px)')
  const isTablet = useMedia('(min-width: 601px) and (max-width: 1280px)')
  const isDesktop = useMedia('(min-width: 1281px)')

  useMemo(() => {
    if (isMobile) return setColumns(1)
    if (isTablet) return setColumns(2)
    if (isDesktop) return setColumns(3)

    return setColumns(1)
  }, [isMobile, isTablet, isDesktop])

  return { columns }
}

export default useMediaQueries
