import React, { useCallback } from 'react'
import { useRoute, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { theme } from '@hedgit/lib/theme'
import { Pressable } from 'native-base'
import { useDispatch } from '@hedgit/web/src/store'

import { toggleSidebar } from '@hedgit/lib/store/modules/ui'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import GoBackIcon from '@hedgit/lib/components/icons/go-back'
import MenuIcon from '@hedgit/lib/components/icons/menu'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'Brokers'>

const HeaderLeftButton = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationType>()

  const route = useRoute()

  const handleClick = useCallback(() => {
    dispatch(toggleSidebar())
  }, [dispatch])

  if (route.name === 'NavigationTabs') {
    return (
      <Pressable testID='nav-bar-menu' onPress={handleClick} style={{ padding: 16 }}>
        <MenuIcon color={theme.colors.dark} />
      </Pressable>
    )
  }
  if (route.name === 'SignIn') {
    return null
  }
  return (
    <Pressable testID='nav-bar-go-back' onPress={() => navigation.goBack()} style={{ padding: 16 }}>
      <GoBackIcon color={theme.colors.dark} />
    </Pressable>
  )
}

export default HeaderLeftButton
