import { action } from 'typesafe-actions'

import { Destination } from '@hedgit/lib/interfaces/destination'

import { DestinationsActionConsts } from './types'

export const getDestinationsPending = () =>
  action(DestinationsActionConsts.GET_DESTINATIONS_PENDING)

export const getDestinationsFulfilled = (Destinations: Destination[]) =>
  action(DestinationsActionConsts.GET_DESTINATIONS_FULFILLED, Destinations)

export const getDestinationsFailed = (error: string) =>
  action(DestinationsActionConsts.GET_DESTINATIONS_FAILED, error)
