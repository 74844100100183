import React, { useCallback, useEffect } from 'react'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import selectCancelledPricingProgramsBroker from '@hedgit/web/src/store/selectors/selectCancelledPricingProgramsBroker'
import selectActivePricingProgramsBroker from '@hedgit/web/src/store/selectors/selectActivePricingProgramsBroker'
import selectExecutedPricingProgramsBroker from '@hedgit/web/src/store/selectors/selectExecutedPricingProgramsBroker'

import { getSubscriptions } from '@hedgit/lib/store/modules/subscriptions/thunks'

import { UserRole } from '@hedgit/lib/enums/user-role'
import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import { BrokerSubscription, Subscriber } from '@hedgit/lib/interfaces/subscription'
import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { SubscriberCard } from '@hedgit/lib/components/cards/subscriber-card'

import { Content, SubContent } from './styled'

import { useDispatch, useSelector } from '../../../store'

type Props = NativeStackScreenProps<RootStackParamList, 'PricingProgramSubscribers'>

const Subscribers = ({ route }: Props) => {
  const { pricingProgramId, pricingProgramStatus } = route.params

  const dispatch = useDispatch()

  const activeList = useSelector(selectActivePricingProgramsBroker())
  const executedList = useSelector(selectExecutedPricingProgramsBroker())
  const cancelledList = useSelector(selectCancelledPricingProgramsBroker())

  let selectedSubscription: BrokerSubscription | undefined
  if (pricingProgramStatus === PricingProgramStatus.cancelled) {
    selectedSubscription = cancelledList.find(pp => pp.pricingProgram.id === pricingProgramId)
  } else if (pricingProgramStatus === PricingProgramStatus.executed) {
    selectedSubscription = executedList.find(pp => pp.pricingProgram.id === pricingProgramId)
  } else if (pricingProgramStatus === PricingProgramStatus.active) {
    selectedSubscription = activeList.find(pp => pp.pricingProgram.id === pricingProgramId)
  }

  useEffect(() => {
    dispatch(getSubscriptions(UserRole.broker))
  }, [dispatch])

  const renderCard: ListRenderItem<Subscriber> = useCallback(
    (subscriber: ListRenderItemInfo<Subscriber>) => {
      return (
        <SubscriberCard subscriber={subscriber.item} />
      )
    },
    []
  )

  return (
    <Content>
      <SubContent>
        {
            selectedSubscription
              ? (
                <FlatList
                  showsHorizontalScrollIndicator
                  testID="pps-subscribers-flatList"
                  data={selectedSubscription.subscribers}
                  renderItem={renderCard}
                  keyExtractor={data => data.id}
                  contentContainerStyle={{ paddingBottom: '5vh' }}
                />
                )
              : null
          }
      </SubContent>
    </Content>
  )
}

export default Subscribers
