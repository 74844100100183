import styled from 'styled-components/native'

import Caption from '../../../typography/caption'

export const Container = styled.View`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  width: 100%;
  max-width: 600px;
  align-self: center;
  flex: 1;
  height: 100%;
  overflow: scroll;
`

export const InputsContainer = styled.View`
  width: 100%;
  gap: 10px;
`

export const TermsAndConditionsContainer = styled.View`
`

export const TermsAndConditionsRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TermsAndConditionsText = styled(Caption)`
  color: ${props => props.theme.colors.gray};
`

export const TermsAndConditionsLink = styled(Caption)`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

export const Requirements = styled(Caption)`
  color: ${props => props.theme.colors.gray};
`

export const ButtonContainer = styled.View`
  width: 100%;
  gap: 20px;
`

export const Error = styled.Text`
  color: ${props => props.theme.colors.red};
`
