import React from 'react'
import { Text } from 'native-base'
import type { StyleProp, TextStyle } from 'react-native'

const Title: React.FC<{
  style?: StyleProp<TextStyle>;
  testID?: string;
}> = ({ children, style, testID }) => {
  return (
    <Text
      testID={testID}
      fontSize="md"
      fontWeight={800}
      fontFamily="Lato"
      color="dark"
      style={style}
    >
      {children}
    </Text>
  )
}

export default Title
