import React, { useEffect, useState } from 'react'
import { View, Image } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { savePhone } from '@hedgit/lib/store/modules/auth/actions'

import useSignInWithPhoneNumber from '@hedgit/lib/hooks/firebase/useSignInWithPhoneNumber'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { SignInPhone } from '@hedgit/lib/components/auth/sign-in-phone'
import { Background as BackgroundSvg } from '@hedgit/lib/components/background'

import auth from 'utils/firebase'
import { verifyPhoneNumber } from 'utils/api'

import { Container, BackgroundContainer, ImageContainer } from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'SignIn'>;

const SignIn = ({ navigation }:Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const onVerificationCodeSent = (phone: string) => {
    setIsFetching(false)
    const phoneNumber = phone.substring(4)
    dispatch(savePhone(phoneNumber))
    navigation.navigate('SignInVerification')
  }

  const {
    signInPhoneNumber,
    clearRecaptcha
  } = useSignInWithPhoneNumber({ auth, onVerificationCodeSent })

  useEffect(() => {
    return () => {
      clearRecaptcha()
    }
  }, [clearRecaptcha])

  const handleSubmit = async (phoneNumber: string) => {
    try {
      setIsFetching(true)
      const userExists = await verifyPhoneNumber(phoneNumber)

      if (userExists) {
        signInPhoneNumber(phoneNumber)
      } else {
        setIsFetching(false)
        setError(t('ErrorMessages.signIn.invalidUser'))
      }
    } catch (e) {
      setIsFetching(false)
      setError(t('ErrorMessages.signIn.invalidUser'))
    }
  }

  return (
    <Container>
      <View nativeID="recaptcha-verifier" />
      <BackgroundContainer>
        <BackgroundSvg />
      </BackgroundContainer>
      <ImageContainer>
        <Image
          style={{ width: 230.2, height: 56.81 }}
          source={require('@hedgit/lib/assets/images/hedgit-icon.png')}
        />
      </ImageContainer>
      <SignInPhone
        isFetching={isFetching}
        isAdmin={false}
        error={error}
        onSubmit={handleSubmit}
        clearError={() => setError('')}
      />
    </Container>
  )
}

export default SignIn
