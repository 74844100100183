import styled from 'styled-components/native'

import Title from '@hedgit/lib/components/typography/title'

export const Content = styled.View`
  background-color: #FFF;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
`

export const SubContent = styled.View`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;
  max-width: 600px;
  align-self: center;
`

export const CardText = styled.Text`
  color: #5A6371;
  padding: 18px 16px 10px 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ChartTitle = styled(Title)`
  font-weight: bold;
`

export const ImageDetails = styled.Image`
  width: 18;
  height: 18;
`

export const ButtonContainer = styled.View`
  padding-bottom: 16px;
  margin: 15px;
`
