import * as yup from 'yup'
import mongoose, { isValidObjectId } from 'mongoose'
import { Maybe, AnyObject } from 'yup/lib/types'

import { UserRole } from '../../enums/user-role'

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    objectId(message?: string, mongooseSchema?: mongoose.Model<unknown>): this;
    objectIdUser(message?: string, mongooseSchema?: mongoose.Model<unknown>, userRole?: UserRole | UserRole[]): this;
  }
}

function MongooseObjectIdValidationTransformFunction (
  message = 'Should be an Mongoose objectId',
  mongooseSchema?: mongoose.Model<unknown>
) {
  return this.test({
    message,
    name: 'objectId',
    test: async function (value) {
      if (!value) return true
      const { path, createError } = this
      if (!isValidObjectId(value)) return createError({ path, message })
      try {
        const valueExists = await mongooseSchema.exists({ _id: value })
        return !!valueExists
      } catch (error) { console.log('Yup Extended objectId Error:', error) }
    }
  })
}

function MongooseObjectIdUserValidationTransformFunction (
  message = 'Should be an Mongoose objectId',
  mongooseSchema?: mongoose.Model<unknown>,
  userRole = UserRole.farmer
) {
  return this.test({
    message,
    name: 'objectIdUser',
    test: async function (value) {
      if (!value) return true
      const { path, createError } = this
      if (!isValidObjectId(value)) return createError({ path, message })
      try {
        const roles = [].concat(userRole)
        const valueExists = await mongooseSchema.find({ _id: value, role: { $in: roles } })
        return !!valueExists
      } catch (error) { console.log('Yup Extended objectId Error:', error) }
    }
  })
}

yup.addMethod(
  yup.string,
  'objectId',
  MongooseObjectIdValidationTransformFunction
)

yup.addMethod(
  yup.string,
  'objectIdUser',
  MongooseObjectIdUserValidationTransformFunction
)

export default yup
