import styled from 'styled-components/native'

import Support from '@hedgit/lib/components/icons/support'
import H2 from '@hedgit/lib/components/typography/h2'
import { Background as BackgroundSvg } from '@hedgit/lib/components/background'

export const Content = styled.View`
  background-color: ${props => props.theme.colors.whiteDark};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const SubContent = styled.View`
  justify-content: center;
  background-color: ${props => props.theme.colors.whiteDark};

`

export const SupportIcon = styled(Support)`
  fill: ${props => props.theme.colors.dark};
`

export const SupportContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 40px 0;
  top: 0;
  background-color: ${props => props.theme.colors.whiteDark};
`

export const Subtitle = styled(H2)`
  display: flex;
  padding: 12px 50px;
  text-align: center;
`

export const Background = styled(BackgroundSvg)`
  position: absolute;
  z-index: -1;
  bottom: 0;
  margin: auto;
  right: 40%;
  left: unset;
  top: 0%;
  left: -100%;
`

export const Pressable = styled.Pressable`
  position: absolute;
  left: 16px;
`

export const Title = styled(H2)`
  top: 15px;
`

export const HeaderWrapper = styled.View`
  width: 100%;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
`

export const ContactBox = styled.View`
  padding-right: 16px;
  padding-left: 16px;
`
