
/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, Rect } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
  testID?: string;
}

const QuickShare = ({ color, testID }: Props) => {
  const id = useId('ps-icon')

  return (

    <Svg id={'Trailing_icon_2' + id} data-testid={testID} data-name={'Trailing icon 2' + id} width="24" height="24" viewBox="0 0 24 24">
      <Rect id={'Boundary' + id} width="24" height="24" fill="none" />
      <Path id={'_Color' + id} data-name={'_Color' + id} d="M15,14.137a2.906,2.906,0,0,0-1.96.773L5.91,10.743a3.3,3.3,0,0,0,.09-.7,3.3,3.3,0,0,0-.09-.7l7.05-4.127A2.99,2.99,0,0,0,18,3.012a3,3,0,1,0-6,0,3.3,3.3,0,0,0,.09.7L5.04,7.841a3.012,3.012,0,1,0,0,4.4l7.12,4.177a2.843,2.843,0,0,0-.08.653A2.92,2.92,0,1,0,15,14.137Z" transform="translate(3 2)" fill={color} />
    </Svg>

  )
}

export default QuickShare
