import { action } from 'typesafe-actions'

import { Tutorial } from '@hedgit/lib/interfaces/tutorial'

import { TutorialsActionConsts } from './types'

export const getTutorialsPending = () =>
  action(TutorialsActionConsts.GET_TUTORIALS_PENDING)

export const getTutorialsFulfilled = (tutorials: Tutorial[]) =>
  action(TutorialsActionConsts.GET_TUTORIALS_FULFILLED, tutorials)

export const getTutorialsFailed = (error: string) =>
  action(TutorialsActionConsts.GET_TUTORIALS_FAILED, error)
