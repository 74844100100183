import React from 'react'
import { useTranslation } from 'react-i18next'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { theme } from '@hedgit/lib/theme'

import useSafeArea from '@hedgit/lib/hooks/use-safe-area'

import PricingProgramIcon from '@hedgit/lib/components/icons/pricing-program'
import PricingSignalIcon from '@hedgit/lib/components/icons/pricing-signal'
import AlgorithmIcon from '@hedgit/lib/components/icons/algorithm'

import { PricingSignalsTabs } from 'routes/pricing-signals/tabs'
import { AlgorithmsTabs } from 'routes/algorithms/tabs'
import { PricingProgramsTabs } from 'routes/pricing-programs/tabs'

const Tab = createBottomTabNavigator()

const { colors } = theme

const NavigationTabs = () => {
  const { t } = useTranslation()
  const { bottom } = useSafeArea()

  return (
    <Tab.Navigator
      screenOptions={{
        headerTitleAlign: 'center',
        headerShown: false,
        tabBarActiveTintColor: colors.white,
        tabBarInactiveTintColor: colors.inactiveGray,
        tabBarStyle: {
          height: 67 + bottom,
          paddingBottom: bottom,
          backgroundColor: colors.primary
        },
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: {
          fontFamily: 'Lato',
          fontSize: 10,
          fontWeight: '500'
        },
        tabBarItemStyle: {
          paddingVertical: 10
        }
      }}
    >
      <Tab.Screen
        name='PricingPrograms'
        component={PricingProgramsTabs}
        options={{
          tabBarTestID: 'nv-pricingProgram-tab',
          tabBarIcon: (PricingProgramIcon),
          title: t('NavigationTabs.pricingProgramsTab').toUpperCase()
        }}
      />
      <Tab.Screen
        name='Algorithms'
        component={AlgorithmsTabs}
        options={{
          tabBarTestID: 'nv-algorithm-tab',
          tabBarIcon: (AlgorithmIcon),
          title: t('NavigationTabs.algorithms').toUpperCase()
        }}
      />
      <Tab.Screen
        name='PricingSignals'
        component={PricingSignalsTabs}
        options={{
          tabBarTestID: 'nv-signals-tab',
          tabBarIcon: (PricingSignalIcon),
          title: t('NavigationTabs.signals').toUpperCase()
        }}
      />
    </Tab.Navigator>
  )
}

export default NavigationTabs
