import { action } from 'typesafe-actions'

import { AuthUser } from '@hedgit/lib/interfaces/auth-user'
import { Farmer } from '@hedgit/lib/interfaces/farmer'
import { Broker } from '@hedgit/lib/interfaces/broker'
import { User } from '@hedgit/lib/interfaces/user'

import { AuthActionConsts } from './types'

export const setAuthToken = (token: string) =>
  action(AuthActionConsts.SET_TOKEN, token)

export const signOut = () =>
  action(AuthActionConsts.SIGN_OUT)

export const saveUserInformation = (data: AuthUser) =>
  action(AuthActionConsts.SAVE_USER_INFORMATION, data)

export const savePhone = (phone: string) =>
  action(AuthActionConsts.SAVE_PHONE, phone)

export const saveFirebaseUID = (data: string) =>
  action(AuthActionConsts.SAVE_FIREBASE_UID, data)

export const verifyUserPending = () =>
  action(AuthActionConsts.VERIFY_USER_PENDING)

export const verifyUserFulfilled = (isVerified: boolean) =>
  action(AuthActionConsts.VERIFY_USER_FULFILLED, isVerified)

export const verifyUserRejected = (error: string) =>
  action(AuthActionConsts.VERIFY_USER_REJECTED, error)

export const createFarmerPending = () =>
  action(AuthActionConsts.CREATE_FARMER_PENDING)

export const createFarmerFulfilled = (data: Farmer) =>
  action(AuthActionConsts.CREATE_FARMER_FULFILLED, data)

export const createFarmerRejected = (error: string) =>
  action(AuthActionConsts.CREATE_FARMER_REJECTED, error)

export const updateFarmerPending = () =>
  action(AuthActionConsts.UPDATE_FARMER_PENDING)

export const updateFarmerFulfilled = (data: Farmer) =>
  action(AuthActionConsts.UPDATE_FARMER_FULFILLED, data)

export const updateFarmerRejected = (error: string) =>
  action(AuthActionConsts.UPDATE_FARMER_REJECTED, error)

export const createBrokerPending = () =>
  action(AuthActionConsts.CREATE_BROKER_PENDING)

export const createBrokerFulfilled = (data: Farmer) =>
  action(AuthActionConsts.CREATE_BROKER_FULFILLED, data)

export const createBrokerRejected = (error: string) =>
  action(AuthActionConsts.CREATE_BROKER_REJECTED, error)

export const updateBrokerPending = () =>
  action(AuthActionConsts.UPDATE_BROKER_PENDING)

export const updateBrokerFulfilled = (data: Broker) =>
  action(AuthActionConsts.UPDATE_BROKER_FULFILLED, data)

export const updateBrokerRejected = (error: string) =>
  action(AuthActionConsts.UPDATE_BROKER_REJECTED, error)

export const deleteBrokerPending = () =>
  action(AuthActionConsts.DELETE_BROKER_PENDING)

export const deleteBrokerFulfilled = () =>
  action(AuthActionConsts.DELETE_BROKER_FULFILLED)

export const deleteBrokerRejected = (error: string) =>
  action(AuthActionConsts.DELETE_BROKER_REJECTED, error)

export const getUserDataPending = () =>
  action(AuthActionConsts.GET_USER_DATA_PENDING)

export const getUserDataFulfilled = (data: User) =>
  action(AuthActionConsts.GET_USER_DATA_FULFILLED, data)

export const getUserDataRejected = (error: string) =>
  action(AuthActionConsts.GET_USER_DATA_REJECTED, error)
