/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Svg, Circle, Text as SVGText } from 'react-native-svg'
import { useTranslation } from 'react-i18next'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import {
  CoverageContent,
  CoverageText,
  getPPTextColor,
  getPPCircleColor
} from './styled'

interface CircleProps {
  coveragePercent: number;
  state: PricingProgramStatus;
}

const CoverageRatioChart = ({
  coveragePercent,
  state
} :CircleProps) => {
  const { t } = useTranslation()
  const size = 56
  const Text = t('Components.card.coverageRatio')
  const strokeWidth = 7
  const radius = (size - strokeWidth) / 2
  const circum = radius * 2 * Math.PI
  const coveragePct = parseFloat((Math.min(Math.max(coveragePercent, 0), 100)).toFixed(1))
  const svgProgress = 100 - coveragePct

  const textColor = getPPTextColor(state as PricingProgramStatus)

  const circleColor = getPPCircleColor(state as PricingProgramStatus)

  return (
    <CoverageContent>
      <Svg
        width={size}
        height={size}
      >
        {/* Background Circle */}
        <Circle
          stroke='#ededed'
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />
        {/* Progress Circle */}
        <Circle
          stroke={circleColor}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap="round"
          {...{ strokeWidth }}
        />

        {/* Text */}
        <SVGText
          fontFamily='Lato'
          fontSize='14'
          fontWeight={900}
          x={size / 2}
          y={size / 1.7}
          textAnchor="middle"
          fill={textColor}
        >
          {coveragePct}
          %
        </SVGText>
      </Svg>
      <CoverageText>
        {Text}
      </CoverageText>
    </CoverageContent>
  )
}

export default CoverageRatioChart
