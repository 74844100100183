import { createSelector } from 'reselect'

import { RootState } from 'store'

const selectFarmerList = (state: RootState) => state.pricingSignals.list

const selectPendingPricingSignalsFarmer = () => createSelector(
  selectFarmerList,
  (farmerList) => farmerList.filter(pp => !pp.executionPrice))

export default selectPendingPricingSignalsFarmer
