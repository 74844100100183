import { ActionType } from 'typesafe-actions'

import { Product } from '@hedgit/lib/interfaces/product'

import * as actions from './actions'

export enum ProductsActionConsts {
  GET_PRODUCTS_PENDING='GET_PRODUCTS_PENDING',
  GET_PRODUCTS_FULFILLED='GET_PRODUCTS_FULFILLED',
  GET_PRODUCTS_FAILED='GET_PRODUCTS_FAILED',
}

export type ProductsActionTypes = ActionType<typeof actions>

export interface ProductsState {
  readonly list: Product[];
  readonly isFetching: boolean;
  readonly error: string;
}
