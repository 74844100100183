import { ThunkAction } from '@hedgit/lib/types/redux-thunk'
import { APIResponse } from '@hedgit/lib/types/api'

import { get } from '@hedgit/lib/utils/axios'

import { Tutorial } from '@hedgit/lib/interfaces/tutorial'

import { getTutorialsPending, getTutorialsFulfilled, getTutorialsFailed } from './actions'
import { TutorialsActionTypes } from './types'

interface Response {
  tutorials: Tutorial[];
}

export const getTutorials = (): ThunkAction<void, unknown, unknown, TutorialsActionTypes> => {
  return async (dispatch) => {
    dispatch(getTutorialsPending())
    try {
      const response = await get<APIResponse<Response>>('/tutorials')
      dispatch(getTutorialsFulfilled(response.data.tutorials))
    } catch (error) {
      dispatch(getTutorialsFailed(error))
    }
  }
}
