/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { IconProps } from './types'

import useId from '../../hooks/useId'

const ErrorIcon = ({ color, width, height }: IconProps) => {
  const id = useId('ps-icon')

  return (
    <Svg width={width} height={height} viewBox="0 0 14 14">
      <Path id={'Path_34806' + id} data-name={'Path_34806' + id} d="M9,2a7,7,0,1,0,7,7A6.994,6.994,0,0,0,9,2ZM9,14.6A5.6,5.6,0,1,1,14.6,9,5.607,5.607,0,0,1,9,14.6Zm2.513-9.1L9,8.013,6.487,5.5,5.5,6.487,8.013,9,5.5,11.513l.987.987L9,9.987,11.513,12.5l.987-.987L9.987,9,12.5,6.487Z" transform="translate(-2 -2)" fill={color} />
    </Svg>
  )
}

export default ErrorIcon
