import { action } from 'typesafe-actions'

import { Notification } from '@hedgit/lib/interfaces/notifications'

import { NotificationsActionConsts } from './types'

export const getNotificationsPending = () =>
  action(NotificationsActionConsts.GET_NOTIFICATIONS_PENDING)

export const getNotificationsFulfilled = (notifications: Notification[]) =>
  action(NotificationsActionConsts.GET_NOTIFICATIONS_FULFILLED, notifications)

export const getNotificationsFailed = (error: string) =>
  action(NotificationsActionConsts.GET_NOTIFICATIONS_FAILED, error)

export const markNotificationAsSeenPending = () =>
  action(NotificationsActionConsts.MARK_NOTIFICATION_AS_SEEN_PENDING)

export const markNotificationAsSeenFulfilled = (notification: Notification) =>
  action(NotificationsActionConsts.MARK_NOTIFICATION_AS_SEEN_FULFILLED, notification)

export const markNotificationAsSeenFailed = (error: string) =>
  action(NotificationsActionConsts.MARK_NOTIFICATION_AS_SEEN_FAILED, error)
