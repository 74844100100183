import { action } from 'typesafe-actions'

import { Crop } from '@hedgit/lib/interfaces/crop'

import { CropsActionConsts } from './types'

export const getCropsPending = () =>
  action(CropsActionConsts.GET_CROPS_PENDING)

export const getCropsFulfilled = (crops: Crop[]) =>
  action(CropsActionConsts.GET_CROPS_FULFILLED, crops)

export const getCropsFailed = (error: string) =>
  action(CropsActionConsts.GET_CROPS_FAILED, error)
